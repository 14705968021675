import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from "react";
import styled from "styled-components";
import {Button, CheckBox, Icon, Spacer, Text, Theme, Title} from "@dspworkplace/ui";
import {Badge, Card, Link, Tag} from "../../../components/UI";
import {useAuth} from "../../../Auth";
import {confirm} from "../../../components/Confirm";
import {dialogPromise} from "../../../components/Dialog";
import Empty from "../../../components/Empty";
import {useParams} from "react-router-dom";
import {
    deleteVehicleImage,
    fetchAiImageCategory,
    fetchVehicleDetail,
    fetchVehicleImage,
    fetchVehicleImageCategory,
    fetchVehiclesAndDrivers,
    handlePrintImageVehicle
} from "./api";
import Store, {VehicleImageProvider2} from "./Context";
import Filter from "./Filter";
import Modal from "../../../components/Modal";
import moment from "moment";
import Skeleton from "../../../components/Skeleton";
import {showVehicleImageEditForm} from "./editForm";
import ReactImageMagnify from "react-image-magnify";
import Loading, {LoadingWrapper} from "../../../components/Loading";

import {useIntersection} from "../../../hooks/useIntersect";

import {useSelectedStation} from "../../../components/StationSelector";
import {Btn} from "../../../components/Grid/Tools/Filter/Filter";
import {showVehicleImageForm} from "./form";
import Tooltip from "../../../components/Tooltip/tooltip";

const Wrapper = styled.div`
    min-height: 100%;
    //max-width: 850px;
    width: 100%;
`;

const TagArea = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`;

const InfosArea = styled.div`
    margin-top: auto;
`;

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding-bottom: 2rem;
`;

const SpacerInline = styled(Spacer)`
    display: inline-flex;
    align-items: center;
`;

const InfoRow = styled(SpacerInline)`
    gap: 8px;
    padding: 4px 0;
    width: 100%;
    
    > svg {
        flex-shrink: 0;
    }
`;

const Image = ({src}) => {
    const [error, setError] = useState(null);

    const style = {
        width: '100%',
        height: 'auto'
    };

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Theme.colors.extra.gray93,
            height: 160,
        }}>
            {error ? (
                <Empty/>
            ) : (
                <img
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }}
                    src={src}
                    onError={() => setError(true)}
                />
            )}
        </div>
    )
}

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const LWrap = styled(LoadingWrapper)`
    height: 100%;
`;


const App = () => {
    const vehicleImageListRef = React.useRef();
    return (
        <VehicleImageProvider2>
            <Wrapper>
                <Header fetchList={(isClear) => { vehicleImageListRef.current.fetchList(isClear) }} />
                <Spacer top={5} />

                <VehicleImageList ref={vehicleImageListRef} />
            </Wrapper>
        </VehicleImageProvider2>
    );
};

const Header = (props) => {
    const { api } = useAuth();
    const [selectedStation] = useSelectedStation();
    const [state, dispatch] = useContext(Store);
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState({});

    // const VehicleId = state.selectedVehicle.name;
    const [badge, setBadge] = useState(0);
    const vehicleId = state?.vehicleId;

    const handleAdd = () => {
        showVehicleImageForm({ api: api, vehicle: vehicleId, categories: state.vehicleImageCategory }).then(
            async (vehicleImage) => {
                const limit = window.screen.width < 1201 ? 6 : 16;
                const result = await fetchVehicleImage(api, vehicleId, 1, limit, state, '', '', selectedStation, '', '');
                if (result) {
                    dispatch({ type: "ADD", payload: { data: result.data.vehicleImages.list, page: result.data.vehicleImages.page, totalPages: result.data.vehicleImages.totalPages } });
                }
            },
            (cancel) => () => { }
        );
    }

    const handlePrint = async () => {
        dispatch({ type: "LOADING_WRAPPER", payload: { status: true } });
        dispatch({ type: "RESET_SELECTED_IMAGE", payload: {} });
        const result = await handlePrintImageVehicle(api, state.selectedImage, state.selectedVehicle.id);
        dispatch({ type: "LOADING_WRAPPER", payload: { status: false } });
        let element = document.createElement("a");
        element.setAttribute("href", result.data.handlePrintImageVehicle);
        element.setAttribute(
            "download",
            result.data.handlePrintImageVehicle
        );
        element.setAttribute("target", "_blank");
        // target='_blank'
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const TooltipContent = ({ content }) => {
        return <div>{content}</div>
    }

    const ButtonRender = ({ children, content }) => {
        if (!content || content.length < 1) return children
        return <Tooltip direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
    }

    const TooltipRender = ({ label, value }) => {
        return <p>{label} = {value}</p>
    }


    const appliedFilters = useMemo(() => {
        const selectedAiCategory = state.vehicleImageAiCategory
            ?.filter(item => state.selectedAiCategory.includes(item.value))
            .map(item => item.name)
            .join(", ");
        const selectedCategory = val?.imageCategories?.map(id => {
            const category = state.vehicleImageCategory.find(item => item.id.toString() === id);
            return category ? category.name : null;
          }).filter(name => name).join(", "); 
        const filters = []

        Object.keys(val).forEach(filterKey => {
            const value = filterKey == "vehicleId" ? state?.vehiclesOpts?.filter((items) => items.value == val[filterKey])?.[0]?.name
                : filterKey == "imageCategories" ? selectedCategory :
                    filterKey == "driversId" ? state?.driversOpts?.filter((items) => items.value == val[filterKey])?.[0]?.name :
                        filterKey === "aiInspectionNotes" ? selectedAiCategory : val[filterKey].hasOwnProperty('value') ? val[filterKey].value : val[filterKey];

            // @ts-ignore            
            if (value !== null&&value && value != '' && value != "all" && value != "All") filters.push(<TooltipRender label={filterKey} value={value} key={filterKey} />);
        })
        return filters
    }, [val])




    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                <Title>
                    Vehicles Images Viewer {' '}
                    {state.vehicleId
                        && state.vehicleId!="all"
                        && "(" + state?.vehiclesOpts?.filter((item) => item?.value == (state.vehicleId?.id ||state.vehicleId) )[0]?.name + ")"
                    }
                </Title>

                <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    <Filter setVal={setVal} setBadge={setBadge} open={open} setOpen={setOpen} onSubmitCallback={props.fetchList} />
                    <Spacer inline right={3} />

                    <Spacer inline right={3} />
                    <ButtonRender content={appliedFilters}>
                        <Btn onClick={() => setOpen(true)}>
                            <span>Filter</span>
                            <Icon.Filter />
                            {badge > 0 && <Badge>{badge}</Badge>}
                        </Btn>
                    </ButtonRender>

                    <Spacer inline left={3}>
                        <Button type="primary" onClick={handlePrint}
                            disabled={!state.selectedImageArr?.length}>
                            Print to PDF
                        </Button>
                    </Spacer>

                    {vehicleId && (vehicleId !== 'all' && vehicleId?.id !== 'all') &&
                        <Spacer inline left={2}>
                            <Button type="primary" onClick={handleAdd}>
                                Add Image
                            </Button>
                        </Spacer>
                    }
                </div>
            </div>
        </>
    );
};

const VehicleImageList = forwardRef((props, ref) => {
    const [state, dispatch] = useContext(Store);
    const [load, setLoad] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);

    const { api } = useAuth();

    let { id } = useParams();

    if (id === ":id" || id === "id") id = false;

    if (state.selectedVehicle.value) id = state.selectedVehicle.value;

    const driverId = state.selectedDriver;
    const vehicleId = state.vehicleId || "";
    const imageCategories = state.selectedCategory;
    const timeframe = state.selectedTimeframe;
    const label = state.label;
    const [station] = useSelectedStation();
    const aiInspectionNotes = state.selectedAiCategory;

    const DeleteRole = [
        "ROLE_OWNER",
        "ROLE_STATION_MANAGER",
        "ROLE_OPERATIONS_MANAGER",
        "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        "ROLE_FLEET_MANAGER",
    ];

    const loadList = async (page = 1, vehicle) => {
        const limit = window.screen.width < 1201 ? 6 : 24;

        const result = await fetchVehicleImage(api, vehicle || vehicleId, page, limit, state, driverId, timeframe, station, imageCategories, label, aiInspectionNotes);
        if (result) {
            dispatch({
                type: "ADD",
                payload: { data: result.data.vehicleImages.list, page, totalPages: result.data.vehicleImages.totalPages },
                // payload: { data: result.data.vehicleImages, page, totalPages: result.pagination.totalPages },
            });
            setLoad(true);
        } else {
            dispatch({
                type: "ADD",
                payload: { data: [], page, totalPages: 0 },
            });
        }
    };

    const fetchOpts = async () => {
        const result = await fetchVehiclesAndDrivers(api, station);
        dispatch({ type: "VEHICLES_OPTS", payload: { data: result } });
        let newValue = null;
        if (typeof id === 'string') {
            newValue = (result.Vehicle || []).find(item => item.vehicleId == id);
            dispatch({
                type: "SELECTED_VEHICLE",
                payload: {
                    vehicleId: newValue?.name || id,
                    id: newValue?.value || id
                },
            });
        }
        if (initialLoad) {
            state.page = 1;
            setLoad(false);
            if (!state.isLoading) dispatch({ type: "LOADING", payload: { status: true } });
            loadList(1, newValue)
        }
    }

    useEffect(() => {
        fetchOpts();
    }, [station, initialLoad]);

    useImperativeHandle(ref, () => ({
        fetchList(isClear) {
            let newValue = null
            if (isClear) {
                newValue = (state.vehiclesOpts || []).find(item => item.value == id);
                dispatch({
                    type: "SELECTED_VEHICLE",
                    payload: {
                        vehicleId: newValue?.name || id,
                        id: newValue?.value || id
                    },
                });
            }
            if (!state.isLoading) dispatch({ type: "LOADING", payload: { status: true } });
            loadList(1, newValue)
        }
    }));
    

    const LoaderRef = React.useRef(null);
    const entry = useIntersection(LoaderRef, {
        threshold: 0.05,
    });
    const isLoadingVisible = !!entry?.isIntersecting;

    useEffect(() => {
        if (isLoadingVisible && state.page < state.totalPages && load) {
            loadList(state.page + 1);
        }
    }, [isLoadingVisible]);

    const fetchAll = async () => {
        if (!state.isLoading) dispatch({ type: "LOADING", payload: { status: true } });
        const vehicleId = await fetchVehicleDetail(api, id);
        dispatch({ type: "ADD_VEHICLEID", payload: vehicleId });

        // const result = await fetchVehicleImage(api, id, { page: 1 });
        // dispatch({ type: "ADD", payload: { data: result } });
    };

    const handleDeletion = async (id) => {
        let confirmation = await confirm(`Confirm you want to delete Vehicle Image ?`);
        if (confirmation) {
            await deleteVehicleImage(api, id);
            dispatch({ type: "REMOVE", payload: { id: id } });
        }
    };

    const handleEdit = (data, key) => {
        showVehicleImageEditForm({ api: api, vehicleImage: data, categories: state.vehicleImageCategory }).then(
            async (vehicleImage) => {
                dispatch({ type: "EDIT", payload: { vehicleImage: vehicleImage, key: key } });
            },
            (cancel) => () => {}
        );
    };

    const fetchCategory = async () => {
        const result = await fetchVehicleImageCategory(api);
        const aiCategory = await fetchAiImageCategory(api);
        dispatch({ type: "ADD_CATEGORY", payload: result });
        dispatch({ type: "ADD_AI_CATEGORY", payload: aiCategory });
    };

    useEffect(() => {
        fetchCategory();
        Promise.all([fetchAll()]).then(() => {
            setTimeout(() => {
                setInitialLoad(true)
            }, 100)
        });
    }, []);

    const openImagePopup = (image) => {
        return dialogPromise((success, cancel) => <ImageModal success={success} cancel={cancel} image={image} />);
    };

    const handleCheckboxClick = (data) => {
        const formatName = (name) => name.replace(/^(\w+)\s(\w).*$/, '$1 $2.');
        const formattedDriverName = formatName(data.updatedBy.friendlyName);
        const driverInfo = {
            imageId: data.id,
            driverName: formattedDriverName
        };

        const { selectedImageArr, selectedImage } = state;
        const isSelected = selectedImageArr.includes(data.id);

        const driverImageSelections = isSelected
            ? selectedImage.filter(item => item.imageId !== driverInfo.imageId)
            : [...selectedImage, driverInfo];

        const newSelectedImageArr = isSelected
            ? selectedImageArr.filter(id => id !== data.id)
            : [...selectedImageArr, data.id];
        dispatch({ type: 'SET_SELECTED_IMAGE', payload: newSelectedImageArr });
        dispatch({ type: 'SELECTED_IMAGE', payload: driverImageSelections });
    };

    return (
        <>
            {state.isLoadingWrapper && (
                <LWrap id="week-table-loading" style={{ position: "fixed" }}>
                    <Loading style={{ width: 40, height: '100%', display: "block", position: "fixed" }} />
                </LWrap>
            )}

            <Wrap>
                {state.isLoading ? (
                    <>
                        {[...Array(5)].map((u, i) => (
                            <SkeletonCard key={i} opacity={1 - i / 10}/>
                        ))}
                    </>
                ) : state.list.length > 0 ? (
                    state.list.map((data, key) => {
                        return (
                            <Card key={JSON.stringify({ key, n: Math.random() })} style={{display: "flex", flexDirection: "column"}}>
                                <Actions>
                                    <SpacerInline style={{justifyContent: 'flex-end', width: '100%'}}>
                                        {state.selectedVehicle && state.selectedVehicle.vehicleId && state.selectedVehicle.vehicleId !== 'All' && (
                                            <div style={{marginRight: 'auto'}}>
                                                <CheckBox
                                                    name={data.id}
                                                    options={[{ value: data.id, label: "" }]}
                                                    onClick={() => {
                                                        handleCheckboxClick(data)
                                                    }}
                                                    defaultValues={state.selectedImageArr}
                                                />
                                            </div>
                                        )}
                                        <SpacerInline left={3}>
                                            <div
                                                onClick={async () => {
                                                    // href={data.path} target={'_blank'} download={''}
                                                    // const response = await fetch(data.path, { mode: 'cors' }); // Include mode: 'cors' for CORS handling
                                                    // if (!response.ok) {
                                                    // throw new Error(`Error downloading image: ${response.statusText}`);
                                                    // }
                                                    // const blob = await response.blob();
                                                    // const link = document.createElement('a');
                                                    // link.href = URL.createObjectURL(blob);
                                                    // link.download = 'downloaded_image.jpg'; // Set desired filename
                                                    // link.click();

                                                    // try {
                                                    //     const image = await fetch(data.path);
                                                    //     const imageBlog = await image.blob();
                                                    //     const imageURL = URL.createObjectURL(imageBlog);
                                                    // } catch (e) {}

                                                    // const link = document.createElement('a')
                                                    // link.href = imageURL;
                                                    // link.download = data.path.replace("https://s3.amazonaws.com/dsp.data.storage.general/", "");
                                                    // document.body.appendChild(link)
                                                    // link.click()
                                                    // document.body.removeChild(link)
                                                    // let element = document.createElement("a");
                                                    // element.setAttribute("href", data.path);
                                                    // element.setAttribute(
                                                    //     "download",
                                                    //     data.path.replace(
                                                    //         "https://s3.amazonaws.com/dsp.data.storage.general/",
                                                    //         ""
                                                    //     )
                                                    // );
                                                    // element.setAttribute("target", "_blank");
                                                    // // target='_blank'
                                                    // document.body.appendChild(element);
                                                    // element.click();
                                                    // document.body.removeChild(element);


                                                    try {
                                                        const response = await fetch(data.path + "?not-from-cache-please");
                                                        const blob = await response.blob();
                                                        const url = window.URL.createObjectURL(blob);

                                                        let element = document.createElement("a");
                                                        element.setAttribute("href", url);
                                                        element.setAttribute(
                                                            "download",
                                                            data.path.replace(
                                                                "https://s3.amazonaws.com/dsp.data.storage.general/",
                                                                ""
                                                            )
                                                        );
                                                        element.setAttribute("target", "_blank");
                                                        document.body.appendChild(element);
                                                        element.click();
                                                        document.body.removeChild(element);
                                                    } catch (error) {
                                                        console.error("There was a problem with the fetch operation:",error);
                                                    }
                                                }}
                                            >
                                                <Icon.Download
                                                    size="18px"
                                                    color={Theme.colors.info.border}
                                                    className="icon-action"
                                                />
                                            </div>
                                        </SpacerInline>
                                        <SpacerInline left={3}>
                                            <div onClick={() => handleEdit(data, key)}>
                                                <Icon.Edit
                                                    size="18px"
                                                    color={Theme.colors.info.border}
                                                    className="icon-action"
                                                />
                                            </div>
                                        </SpacerInline>
                                        {DeleteRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                                            <SpacerInline left={3}>
                                                <div onClick={() => handleDeletion(data.id)}>
                                                    <Icon.Times
                                                        size="18px"
                                                        color={Theme.colors.info.border}
                                                        className="icon-action"
                                                    />
                                                </div>
                                            </SpacerInline>
                                        )}
                                    </SpacerInline>
                                </Actions>

                                <Spacer top={3} />
                                {data.inspectionImagePath && data.inspectionImagePath != "" ? (
                                    <div
                                        onClick={() => {
                                            openImagePopup(data.inspectionImagePath).then();
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Image src={data.inspectionImagePath} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            openImagePopup(data.path).then();
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Image src={data.path} />
                                    </div>
                                )}

                                <Spacer top={3} />

                                <InfoRow>
                                    <Icon.Notes
                                        size="16px"
                                        color={Theme.colors.info.border}
                                    />
                                    <Text>
                                        {typeof data.label === "string" && data.label != ""
                                            ? data.label.trim()
                                            : "-"}
                                    </Text>
                                </InfoRow>

                                {data.inspectionImagePath && data.inspectionImagePath != "" && (
                                    <InfoRow>
                                        <Icon.Info
                                            size="16px"
                                            color={Theme.colors.info.border}
                                        />
                                        <div>
                                            {data.inspectionFailedNote != '' && data.inspectionFailedNote != null && <Text>{data.inspectionFailedNote.charAt(0).toUpperCase() + data.inspectionFailedNote.slice(1)}</Text>}
                                            <Link title={'Original Pic'} onClick={() => { openImagePopup(data.path).then(); }}>(Original Pic)</Link>
                                        </div>
                                    </InfoRow>
                                )}

                                <InfoRow>
                                    <Icon.Skill
                                        size="16px"
                                        color={Theme.colors.info.border}
                                    />
                                    <TagArea>
                                        {data.category && data.category.length > 0
                                            ? data.category.map((c, i) => <Tag inline small key={i}>{c.name}</Tag>)
                                            : "-"}
                                    </TagArea>
                                </InfoRow>

                                <Spacer top={3}/>
                                <InfosArea>
                                    <InfoRow>
                                        <Icon.Info
                                            size="16px"
                                            color={Theme.colors.info.border}
                                        />
                                        <Text>{data.vehicle && data.vehicle.vehicleId}</Text>
                                    </InfoRow>

                                    <InfoRow>
                                        <Icon.Clock
                                            size="16px"
                                            color={Theme.colors.info.border}
                                        />
                                        <Text>
                                            {data.createdAt
                                                ? moment
                                                      .utc(data.createdAt.timestamp * 1000)
                                                      .local()
                                                      .format("MMM D, Y")
                                                : "-"}
                                        </Text>
                                    </InfoRow>

                                    <InfoRow>
                                        <Icon.Info
                                            size="16px"
                                            color={Theme.colors.info.border}
                                        />
                                        <Text>{data.updatedBy && data.updatedBy.friendlyName}</Text>
                                    </InfoRow>
                                </InfosArea>
                            </Card>
                        );
                    })
                ) : (
                    state.isLoading === false && (
                        <SpacerInline>
                            <Empty />
                        </SpacerInline>
                    )
                )}
            </Wrap>

            <div
                style={{ marginBottom: 30, visibility: state.page != state.totalPages ? "visible" : "hidden" }}
                ref={LoaderRef}
            >
                {state.list.length > 0 && (
                    <Loading style={{ width: "40px", margin: "30px auto", display: "block", paddingBottom: 30 }} />
                )}
            </div>
        </>
    );
});

const ImageModal = ({ success, cancel, image }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Modal title={"Image"} visible={true} width="700px" setVisible={cancel} disabled={loading}>
            <ReactImageMagnify
                {...{
                    smallImage: { isFluidWidth: true, src: image },
                    largeImage: { src: image, width: 900, height: 700 },
                    enlargedImageContainerStyle: { background: "#fff", zIndex: 9, enlargedImagePosition: "over" },
                }}
            />
            {/*<img style={{maxWidth:'660px'}} src={image}/>*/}
        </Modal>
    );
};

const SkeletonCard = ({opacity}) => {
    return (
        <Card style={{ position: "relative", opacity: opacity }}>
            <div style={{ height: "20px" }}>
                <Skeleton style={{ width: "81%", float: "left" }} />
                <Skeleton style={{ width: "15%", float: "right" }} />
            </div>
            <Spacer top={3} />

            <div style={{ height: "140px" }}>
                <Skeleton style={{ width: "100%", float: "left" }} />
            </div>
            <Spacer top={3} />
            <div style={{ height: "20px" }}>
                <Skeleton style={{ width: "15%", float: "left" }} />
                <Skeleton style={{ width: "81%", float: "right" }} />
            </div>
            <Spacer top={3} />
            <div style={{ height: "20px" }}>
                <Skeleton style={{ width: "15%", float: "left" }} />
                <Skeleton style={{ width: "81%", float: "right" }} />
            </div>
        </Card>
    );
};

export default App;
