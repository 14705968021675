import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    CustomDropdown,
    Dropdown,
    Spacer,
    SubTitle,
    TagInput,
    // TimePicker,
    RadioBox,
    CheckBox,
    Input, Icon, Theme
} from "@dspworkplace/ui";
import { Label } from "../../components/UI";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import API from "./api";
import moment from "moment";
import { dialogPromise, dialog } from "../../components/Dialog";
import DayPicker from "../../components/Calendar/dayNew";
import { useSelectedStation } from "../../components/StationSelector";
import { confirm } from "../../components/Confirm";
import TimePicker from "../../components/TimePicker";
import ComboBox from "../../components/ComboBox";
import { getRole, getAllowsDispatcherPermission } from "../../Auth";
import { alert } from "../../components/Alert";

const DriverSelection = ({ success, cancel, children, api, config }) => {
    const [options, setOptions] = useState([]);
    const [invoiceOptions, setInvoiceOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [station, setStation] = useState(config.station);
    const [stationOptions, setStationOptions] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState("");
    const [selectedShiftType, setSelectedShiftType] = useState("");
    const { register, handleSubmit, errors, reset, getValues, setValue } = useForm();
    const [routeStatus, setRouteStatus] = useState(false);
    const [backupStatus, setBackupStatus] = useState(false);
    const [selectedStation] = useSelectedStation();
    let isVoluntary;
    
    const onSubmit = async (data) => {
        if (!data.driver) return;

        if (config.type === "REPLACE_DRIVER") {
            success({
                driver: options.find((opt) => opt.id == data.driver),
                routeCodes: data.routeCodes,
            });
        } else if (config.type === "SCHEDULER_ADD_DRIVER") {
            success({
                driver: data.driver,
                shift: data.shiftType,
                invoiceType: data.invoiceType,
                startTime: data.startTime,
                endTime: data.endTime,
                routeStatus: data.routeStatus ? data.routeStatus : 0,
                isVoluntary: data.isVoluntary,
                backupStatus: data.backupStatus ? data.backupStatus : 0,
                onCall: data.onCall
            });
        } else if (config.type === "SCHEDULER_ADD_ROUTE") {
            let configArr = { driver: data.driver, ...config };
            let res = await API.checkDriverRoute(api, configArr);
            let dialogMsg = "";
            if(res.availableDriverIds.length > 0 && res.isTodayPast === true) {
                dialogMsg ="Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can't create today or during the past shift!";
                await alert({
                    text: dialogMsg,
                    btnText: "Cancel",
                });
                return false;
            } else if (res.success == true && res.availableDriverIds.length > 0) {
                let tempDriverId = [];
                let publishDriverId = [];
                let availableDriverId = res.availableDriverIds.map((driver) => driver.driverId);
                let filterAvailableDriverIds = data.driver.filter((item) => !availableDriverId.includes(Number(item)));
                filterAvailableDriverIds = filterAvailableDriverIds.map(Number);
                let messageString = "";
                let messageStringTemp = "";
                let isPublish = false;
                let isTemp = false;
                let dialogBoxStatus = false;
                
                if (res.availableDriverIds.length == 1) {
                    if (res.availableDriverIds[0].type == "temp") {
                        dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in  " + res.availableDriverIds[0].code +". \n You can't create the shift, please publish or discard temp changes to other station shifts then after creating..!";
                        await alert({
                            text: dialogMsg,
                            btnText: "Cancel",
                        });
                        if (filterAvailableDriverIds.length === 0) {
                            return false;
                        }
                        dialogBoxStatus = true;
                        let foundDriver = options.filter((driver) => filterAvailableDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        dialogMsg = 'Are you sure want to create shift selected "' + foundDriver + "\" drivers'?";
                    } else {
                        publishDriverId.push(res.availableDriverIds[0].driverId);
                        dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code + ". Would you like to move this driver anyway?";
                    }    
                } else {
                    messageString += '<table style="width: 100%; border-collapse: collapse; margin-top: 13px;"><tbody><tr><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Driver\'s name</th><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Station</th></tr>';
                    messageStringTemp += '<table style="width: 100%; border-collapse: collapse; margin-top: 13px;  margin-bottom: 13px;"><tbody><tr><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Driver\'s name</th><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Station</th></tr>';                        
                    res.availableDriverIds.map((driver) => {
                        if(driver.type == "publish") {
                            publishDriverId.push(driver.driverId);
                            isPublish = true;
                            messageString += "<tr>";
                            messageString += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.name + "</td>";
                            messageString += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.code + "</td>";
                            messageString += "</tr>";
                        } else if(driver.type == "temp") {
                            tempDriverId.push(driver.driverId);
                            isTemp = true;
                            messageStringTemp += "<tr>";
                            messageStringTemp += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.name + "</td>";
                            messageStringTemp += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.code + "</td>";
                            messageStringTemp += "</tr>";
                        }
                    });
                    messageString += "</tbody></table>";
                    messageStringTemp += "</tbody></table>";
                    // prepare dialog box message
                    let publishMsg = (isPublish) ? "Some of the selected drivers already have scheduled shifts in other stations. Would you like to move this drivers anyway? " + messageString : '';
                    let tempMsg = (isTemp) ? "Some of the selected drivers already have scheduled shifts in other stations. You can't create the shift, please publish or discard temp changes to other station shifts then after creating..! " + messageStringTemp : '';
                    if (isTemp && !isPublish) {
                        await alert({
                            text: tempMsg,
                            btnText: "Cancel",
                        });
                        if (filterAvailableDriverIds.length === 0) {
                            return false;
                        } 
                        dialogBoxStatus = true;
                        let foundDriver = options.filter((driver) => filterAvailableDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        tempMsg = 'Are you sure want to create shift selected "' + foundDriver + '" drivers\'?';
                    }
                    dialogMsg = tempMsg + publishMsg;
                }
                await dialog({
                    text: dialogMsg,
                    buttons: [
                        {
                            text: "Cancel",
                            callback: async () => {
                                return false;
                            },
                        },
                        {
                            type: "primary",
                            text: "Confirm",
                        },
                    ],
                }).then(async (dialogResponse) => {
                    publishDriverId = publishDriverId.map(Number);
                    let driverIds = [];
                    if(tempDriverId.length == 0 && filterAvailableDriverIds.length != 0) {
                        tempDriverId = availableDriverId;
                        driverIds = data.driver.filter((item) => !tempDriverId.includes(Number(item)));
                    }
                    // ghost cancel action after ask to selected driver's 
                    let selectedDriverIds = filterAvailableDriverIds.filter((item) => !publishDriverId.includes(item));
                    if(!dialogResponse && selectedDriverIds.length > 0 && dialogBoxStatus === false) {
                        
                        let foundDriver = options.filter((driver) => selectedDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        await dialog({
                            text: 'Are you sure want to create shift selected "'+ foundDriver +'" drivers\'?',
                            buttons: [
                                {
                                    text: "Cancel",
                                    callback: async () => {
                                        return false;
                                    },
                                },
                                {
                                    type: "primary",
                                    text: "Yes",
                                },
                            ],
                        }).then(async (response) => {
                            if (!response) return;
                            data.driver = selectedDriverIds;
                            const driverSkillParam = {
                        actions: {
                            response: {
                                Driver: {
                                    custom: {
                                        functionName: "checkDriverAndShiftSkillMatch",
                                        get: "result",
                                        excludes: [],
                                        criteria: {
                                            driverId: data.driver,
                                            shiftId: data.shiftType,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                    if (!responseDriverSkill.data.data.result.result) {
                        if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                            alert({
                                text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                                btnText: "Cancel",
                            });
                            return false;
                        } else {
                            const confirmation = await confirm(
                                'WARNING: "' +
                                    responseDriverSkill.data.data.result.driverName +
                                    '" does not have the required "' +
                                    responseDriverSkill.data.data.result.shiftSkillName +
                                    '" skill to be assigned this shift type. Are you sure you want to continue?'
                            );
                            if (!confirmation) {
                                return true;
                            }
                        }
                    }
                    success({
                        driver: data.driver,
                        shift: data.shiftType,
                        invoiceType: data.invoiceType,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        station: data.station,
                        routeStatus: data.routeStatus ? data.routeStatus : 0,
                        inactiveCurrent: true,
                        isVoluntary: data.isVoluntary,
                        backupStatus: data.backupStatus ? data.backupStatus : 0,
                        onCall: data.onCall,
                    });
                        });
                    }
                    if (!dialogResponse) return;
                    // fix the driverId shift create                  
                    data.driver = driverIds.length > 0 && publishDriverId === 0
                            ? driverIds
                            : dialogBoxStatus === true
                            ? filterAvailableDriverIds
                            : dialogBoxStatus === false && publishDriverId.length > 0
                            ? [...publishDriverId, ...filterAvailableDriverIds]
                            : data.driver;
                            
                    const driverSkillParam = {
                        actions: {
                            response: {
                                Driver: {
                                    custom: {
                                        functionName: "checkDriverAndShiftSkillMatch",
                                        get: "result",
                                        excludes: [],
                                        criteria: {
                                            driverId: data.driver,
                                            shiftId: data.shiftType,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                    if (!responseDriverSkill.data.data.result.result) {
                        if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                            alert({
                                text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                                btnText: "Cancel",
                            });
                            return false;
                        } else {
                            const confirmation = await confirm(
                                'WARNING: "' +
                                    responseDriverSkill.data.data.result.driverName +
                                    '" does not have the required "' +
                                    responseDriverSkill.data.data.result.shiftSkillName +
                                    '" skill to be assigned this shift type. Are you sure you want to continue?'
                            );
                            if (!confirmation) {
                                return true;
                            }
                        }
                    }
                    success({
                        driver: data.driver,
                        shift: data.shiftType,
                        invoiceType: data.invoiceType,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        station: data.station,
                        routeStatus: data.routeStatus ? data.routeStatus : 0,
                        inactiveCurrent: true,
                        isVoluntary: data.isVoluntary,
                        backupStatus: data.backupStatus ? data.backupStatus : 0,
                        onCall: data.onCall,
                    });
                });        
            } else {
                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: data.driver,
                                        shiftId: data.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                                responseDriverSkill.data.data.result.driverName +
                                '" does not have the required "' +
                                responseDriverSkill.data.data.result.shiftSkillName +
                                '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
                success({
                    driver: data.driver,
                    shift: data.shiftType,
                    invoiceType: data.invoiceType,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    station: data.station,
                    routeStatus: data.routeStatus ? data.routeStatus : 0,
                    isVoluntary: data.isVoluntary,
                    backupStatus: data.backupStatus ? data.backupStatus : 0,
                    onCall: data.onCall
                });
            }
        } else if (config.type === "SCHEDULER_ADD_ROUTE_WITH_DATE") {
            let configArr = { driver: data.driver, ...config };
            let res = await API.checkDriverRoute(api, configArr);
            let dialogMsg = "";

            if(res.availableDriverIds.length > 0 && res.isTodayPast === true) {
                dialogMsg ="Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can't move a shift that already happened.";
                await alert({
                    text: dialogMsg,
                    btnText: "Cancel",
                });
                return false;
            } else if (res.success == true && res.availableDriverIds.length > 0) {
                let tempDriverId = [];
                let publishDriverId = [];
                let availableDriverId = res.availableDriverIds.map((driver) => driver.driverId);
                let filterAvailableDriverIds = data.driver.filter((item) => !availableDriverId.includes(Number(item)));
                filterAvailableDriverIds = filterAvailableDriverIds.map(Number);
                let messageString = "";
                let messageStringTemp = "";
                let isPublish = false;
                let isTemp = false;
                let dialogBoxStatus = false;
                
                if (res.availableDriverIds.length == 1) {
                    if (res.availableDriverIds[0].type == "temp") {
                        dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in  " + res.availableDriverIds[0].code +". \n You can't create the shift, please publish or discard temp changes to other station shifts then after creating..!";
                        await alert({
                            text: dialogMsg,
                            btnText: "Cancel",
                        });
                        if (filterAvailableDriverIds.length === 0) {
                            return false;
                        }
                        dialogBoxStatus = true;
                        let foundDriver = options.filter((driver) => filterAvailableDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        dialogMsg = 'Are you sure want to create shift selected "' + foundDriver + "\" drivers'?";
                    } else {
                        publishDriverId.push(res.availableDriverIds[0].driverId);
                        dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code + ". Would you like to move this driver anyway?";
                    }    
                } else {
                    messageString += '<table style="width: 100%; border-collapse: collapse; margin-top: 13px;"><tbody><tr><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Driver\'s name</th><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Station</th></tr>';
                    messageStringTemp += '<table style="width: 100%; border-collapse: collapse; margin-top: 13px;  margin-bottom: 13px;"><tbody><tr><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Driver\'s name</th><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Station</th></tr>';                        
                    res.availableDriverIds.map((driver) => {
                        if(driver.type == "publish") {
                            publishDriverId.push(driver.driverId);
                            isPublish = true;
                            messageString += "<tr>";
                            messageString += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.name + "</td>";
                            messageString += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.code + "</td>";
                            messageString += "</tr>";
                        } else if(driver.type == "temp") {
                            tempDriverId.push(driver.driverId);
                            isTemp = true;
                            messageStringTemp += "<tr>";
                            messageStringTemp += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.name + "</td>";
                            messageStringTemp += '<td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">' + driver.code + "</td>";
                            messageStringTemp += "</tr>";
                        }
                    });
                    messageString += "</tbody></table>";
                    messageStringTemp += "</tbody></table>";
                    // prepare dialog box message
                    let publishMsg = (isPublish) ? "Some of the selected drivers already have scheduled shifts in other stations. Would you like to move this drivers anyway? " + messageString : '';
                    let tempMsg = (isTemp) ? "Some of the selected drivers already have scheduled shifts in other stations. You can't create the shift, please publish or discard temp changes to other station shifts then after creating..! " + messageStringTemp : '';
                    if (isTemp && !isPublish) {
                        await alert({
                            text: tempMsg,
                            btnText: "Cancel",
                        });
                        if (filterAvailableDriverIds.length === 0) {
                            return false;
                        } 
                        dialogBoxStatus = true;
                        let foundDriver = options.filter((driver) => filterAvailableDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        tempMsg = 'Are you sure want to create shift selected "' + foundDriver + '" drivers\'?';
                    }
                    dialogMsg = tempMsg + publishMsg;
                }
                await dialog({
                    icon: <Icon.Warning color={Theme.colors.warnings.text}/>,
                    text: dialogMsg,
                    buttons: [
                        {
                            text: "Cancel",
                            callback: async () => {
                                return false;
                            },
                        },
                        {
                            type: "primary",
                            text: "Confirm",
                        },
                    ],
                }).then(async (dialogResponse) => {
                    publishDriverId = publishDriverId.map(Number);
                    let driverIds = [];
                    if(tempDriverId.length == 0 && filterAvailableDriverIds.length != 0) {
                        tempDriverId = availableDriverId;
                        driverIds = data.driver.filter((item) => !tempDriverId.includes(Number(item)));
                    }
                    
                    // ghost cancel action after ask to selected driver's
                    let selectedDriverIds = filterAvailableDriverIds.filter((item) => !publishDriverId.includes(item));
                    if(!dialogResponse && selectedDriverIds.length > 0 && dialogBoxStatus === false) {
                        
                        let foundDriver = options.filter((driver) => selectedDriverIds.includes(driver.id)).map(driver => driver.name).join(", ");
                        await dialog({
                            text: 'Are you sure want to create shift selected "'+ foundDriver +'" drivers\'?',
                            buttons: [
                                {
                                    text: "Cancel",
                                    callback: async () => {
                                        return false;
                                    },
                                },
                                {
                                    type: "primary",
                                    text: "Yes",
                                },
                            ],
                        }).then(async (response) => {
                            if (!response) return;
                            data.driver = selectedDriverIds;
                            const driverSkillParam = {
                                actions: {
                                    response: {
                                        Driver: {
                                            custom: {
                                                functionName: "checkDriverAndShiftSkillMatch",
                                                get: "result",
                                                excludes: [],
                                                criteria: {
                                                    driverId: data.driver,
                                                    shiftId: data.shiftType,
                                                },
                                            },
                                        },
                                    },
                                },
                            };
                            const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                            if (!responseDriverSkill.data.data.result.result) {
                                if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                                    alert({
                                        text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                                        btnText: "Cancel",
                                    });
                                    return false;
                                } else {
                                    const confirmation = await confirm(
                                        'WARNING: "' +
                                        responseDriverSkill.data.data.result.driverName +
                                        '" does not have the required "' +
                                        responseDriverSkill.data.data.result.shiftSkillName +
                                        '" skill to be assigned this shift type. Are you sure you want to continue?'
                                    );
                                    if (!confirmation) {
                                        return true;
                                    }
                                }
                            }
                            success({
                                driver: data.driver,
                                shift: data.shiftType,
                                invoiceType: data.invoiceType,
                                startTime: data.startTime,
                                endTime: data.endTime,
                                station: data.station,
                                date: data.date,
                                routeStatus: data.routeStatus ? data.routeStatus : 0,
                                inactiveCurrent: true,
                                isVoluntary: data.isVoluntary,
                                backupStatus: data.backupStatus ? data.backupStatus : 0,
                                onCall: data.onCall
                            });
                        });
                    }
                    if (!dialogResponse) return;
                    // fix the driverId shift create                  
                    data.driver =
                        driverIds.length > 0 && publishDriverId === 0
                            ? driverIds
                            : dialogBoxStatus === true
                            ? filterAvailableDriverIds
                            : dialogBoxStatus === false && publishDriverId.length > 0
                            ? [...publishDriverId, ...filterAvailableDriverIds]
                            : data.driver;
                    
                    const driverSkillParam = {
                        actions: {
                            response: {
                                Driver: {
                                    custom: {
                                        functionName: "checkDriverAndShiftSkillMatch",
                                        get: "result",
                                        excludes: [],
                                        criteria: {
                                            driverId: data.driver,
                                            shiftId: data.shiftType,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                    if (!responseDriverSkill.data.data.result.result) {
                        if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                            alert({
                                text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                                btnText: "Cancel",
                            });
                            return false;
                        } else {
                            const confirmation = await confirm(
                                'WARNING: "' +
                                responseDriverSkill.data.data.result.driverName +
                                '" does not have the required "' +
                                responseDriverSkill.data.data.result.shiftSkillName +
                                '" skill to be assigned this shift type. Are you sure you want to continue?'
                            );
                            if (!confirmation) {
                                return true;
                            }
                        }
                    }
                    success({
                        driver: data.driver,
                        shift: data.shiftType,
                        invoiceType: data.invoiceType,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        station: data.station,
                        date: data.date,
                        routeStatus: data.routeStatus ? data.routeStatus : 0,
                        inactiveCurrent: true,
                        isVoluntary: data.isVoluntary,
                        backupStatus: data.backupStatus ? data.backupStatus : 0,
                        onCall: data.onCall
                    });
                });
            } else {
                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: data.driver,
                                        shiftId: data.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
                success({
                    driver: data.driver,
                    shift: data.shiftType,
                    invoiceType: data.invoiceType,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    station: data.station,
                    date: data.date,
                    routeStatus: data.routeStatus ? data.routeStatus : 0,
                    isVoluntary: data.isVoluntary,
                    backupStatus: data.backupStatus ? data.backupStatus : 0,
                    onCall: data.onCall
                });
            }
        } else {
            success(options.find((opt) => opt.id == data.driver));
        }
    };

    const inputRef = useRef();
    const sorter = (array, field, direction = "DESC") => {
        //0 DESC
        //1 ASC
        const sorted = array.sort((a, b) => {
            if (direction === "DESC") return (a[field] > b[field]) - (a[field] < b[field]);
            return (b[field] > a[field]) - (b[field] < a[field]);
        });
        return sorted;
    };

    let currentShiftDate = moment().format("YYYY-MM-DD");
    if(config.date != "undefined") {
        currentShiftDate = moment(config.date).format("YYYY-MM-DD");
    }
    if(moment(currentShiftDate).day() === 0) {
        currentShiftDate = moment(currentShiftDate).add(1, 'days').format('YYYY-MM-DD');
    }

    const onDateChange = async (date, station) => {
        config.date = date;
        if(moment(date).day() === 0) {
            currentShiftDate = moment(date).add(1, 'days').format('YYYY-MM-DD');
        } else {
            currentShiftDate = moment(date).format("YYYY-MM-DD");
        }
        let drivers = await API.fetchAvailableForAddDrivers(api, { station: station, date: date });
        let driversOpts =
            drivers.length > 0 ? drivers : [{ id: 0, img: "", name: "No Driver Found!", routeId: 0, title: "" }];
        reset({ driver: [] });
        setOptions(driversOpts);
    };

    const onStationChange = async (selectedStationChange) => {
        let drivers = await API.fetchAvailableForAddDrivers(api, {
            station: selectedStationChange,
            date: config.date,
            selectedStation: config.station,
        });
        setStation(selectedStationChange);
        let driversOpts =
            drivers.length > 0 ? drivers : [{ id: 0, img: "", name: "No Driver Found!", routeId: 0, title: "" }];
        reset({ driver: [] });
        setOptions(driversOpts);
    };

    const loadOptions = async (api) => {
        let opts = [];
        const getStationLocalStorage = JSON.parse(localStorage.getItem("station"));
        if (config && config.type && config.type === "REASIGN_DRIVER") {
            opts = await API.fetchAvailableDriversForReplace(api, config);
        } /*else if (config && config.type && config.type === "OPEN_ROUTE_ASIGN") {
            //opts = await API.fetchAvailableForAddDrivers(api, config);
           // opts = await API.fetchReplaceDrivers(api, config);
        } */ else if (config && config.type && config.type === "SCHEDULER_ADD_DRIVER") {
            const [drivers, invoiceTypes, shiftOptions] = await Promise.all([
                API.fetchAvailableForAddDrivers(api, config),
                API.fetchInvoiceTypeForAddDrivers(api, config),
                API.getNoramlAndBackupShiftForScheduler(api, config),
            ]);

            opts = drivers;
            setInvoiceOptions(invoiceTypes);
            setShiftOptions(shiftOptions);

            if (!config.quickShiftType) {
                // if (config.startTime) {
                //     reset({
                //         startTime: config.startTime,
                //     });
                // }
            } else {
                let tempDate = moment(config.startTime, "HH:mm");
                tempDate.add(config.quickShiftType.shiftTimeDiffrence, "m");

                let values = {
                    startTime: config.startTime,
                    invoiceType: config.quickShiftType.invoiceType || 0,
                };

                if (config.quickShiftType.isShift || config.quickShiftType.isBackup) {
                    values.shiftType = config.quickShiftType.id;
                    values.endTime = tempDate.format("HH:mm");
                }

                reset(values);
            }
        } else if (
            config &&
            config.type &&
            (config.type === "SCHEDULER_ADD_ROUTE" || config.type === "SCHEDULER_ADD_ROUTE_WITH_DATE")
        ) {
            const [drivers, invoiceTypes, shiftOptions, stations] = await Promise.all([
                API.fetchAvailableForAddDrivers(api, config),
                API.fetchInvoiceTypeForAddDrivers(api, config),
                API.getNoramlAndBackupShiftForScheduler(api, config),
                API.fetchStationData(api),
            ]);

            opts = drivers;
            setInvoiceOptions(invoiceTypes);
            setShiftOptions(shiftOptions);
            if (getStationLocalStorage != undefined && getRole() == "ROLE_DISPATCHER" || getStationLocalStorage != undefined && ["ROLE_STATION_MANAGER","ROLE_ASSISTANT_STATION_MANAGER","ROLE_LEAD_DRIVER"].includes(getRole())) {
                const foundStation = stations.find((stations) => stations.id == getStationLocalStorage.id);
                setStationOptions([foundStation]);
            } else {
                setStationOptions(stations);
            }

            if (!config.quickShiftType) {
                // if (config.startTime) {
                //     reset({
                //         startTime: config.startTime,
                //     });
                // }
            } else {
                let tempDate = moment(config.startTime, "HH:mm");
                tempDate.add(config.quickShiftType.shiftTimeDiffrence, "m");

                let values = {
                    startTime: config.startTime,
                    invoiceType: config.quickShiftType.invoiceType || 0,
                };

                if (config.quickShiftType.isShift || config.quickShiftType.isBackup) {
                    values.shiftType = config.quickShiftType.id;
                    values.endTime = tempDate.format("HH:mm");
                }

                reset(values);
            }
        } else if (config && config.type && (config.type === "REPLACE_DRIVER" || config.type === "OPEN_ROUTE_ASIGN")) {
            opts = await API.fetchReplaceDrivers(api, config);
        }
        // let sorterOpts = sorter(opts, "name", "DESC");
        let sorterOpts = opts;
        sorterOpts =
            sorterOpts.length > 0 ? sorterOpts : [{ id: 0, img: "", name: "No Driver Found!", routeId: 0, title: "" }];

        const paramsStationObject={
            "actions": {
                "response": {
                    "Station": {
                        "findOneBy": {
                            "criteria": {
                                "id":config.station
                            },
                            "get": "Station",
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "stationAddress",
                                "3": "stationGeofence",
                                "4": "parkingLotAddress",
                                "5": "parkingLotGeofence",
                                "6": "code",
                                "7": "timezone"
                            },
                            "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                        }
                    }
                }
            }
        }
        let responseStationObj = await api.post('/api/lazy/manage/data', paramsStationObject);
        let timeZoneShortName = null;
        if(responseStationObj.data.data.Station){
            let currentSelectedStationObj = responseStationObj.data.data.Station;
            timeZoneShortName = currentSelectedStationObj.timezone;
        }
        opts.timeZoneShortName = timeZoneShortName;
        setOptions(sorterOpts);
    };

    const onShiftChange = (value) => {
        if (value == "") {
            return;
        }
        let isDST = moment(currentShiftDate).isDST();
        let shiftType = shiftOptions.find((s) => value.toString() === s.id.toString());

        const startTime = isDST
            ? shiftType.startTimeOnDst
            : shiftType.startTime;

        const endTime = isDST
            ? shiftType.endTimeOnDst
            : shiftType.endTime;

        if (shiftType.category == 8) {
            setRouteStatus(true);
        } else {
            setRouteStatus(false);
        }
        if (shiftType.category == 2 && shiftType.isOT == true) {
            setBackupStatus(true);
            setRouteStatus(false);
        } else {
            setBackupStatus(false);
        }
        // setSelectedInvoice(value.invoiceType ? value.invoiceType : 0);
        // let tempDate = moment(config.startTime, "HH:mm");
        // tempDate.add(shiftType.shiftTimeDiffrence, "m");
        reset({
            startTime: parseFloat(startTime).toFixed(2).replace(".", ":"),
            endTime: parseFloat(endTime).toFixed(2).replace(".", ":"),
            shiftType: value,
            invoiceType: shiftType.invoiceType || 0,
        });
    };

    useEffect(() => {
        loadOptions(api);
    }, []);

    return (
        <Modal width="340px" title="Select a Driver" visible={true} setVisible={cancel}>
            {!options.length ? (
                <div style={{ textAlign: "center" }}>
                    <Loading style={{ width: "40px" }} />
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    {children && <Spacer bottom={3}>{children}</Spacer>}
                    {config.type === "REPLACE_DRIVER" && (
                        <Spacer bottom={3}>
                            <TagInput
                                ref={(elm) => {
                                    inputRef.current = elm;
                                    register(elm);
                                }}
                                name="routeCodes"
                                label="Routes"
                                options={config.routeCode.map((c) => ({ name: c, value: c, selected: true }))}
                            />
                        </Spacer>
                    )}
                    {config.type === "SCHEDULER_ADD_DRIVER" && (
                        <Spacer bottom={3}>
                            <ComboBox
                                ref={register({
                                    required: true,
                                })}
                                name="shiftType"
                                label="Shift Type"
                                type="shiftIcon"
                                input={false}
                                options={shiftOptions.map((s) => ({
                                    name: s.name,
                                    value: s.id,
                                    color: s.color,
                                    invoiceType: s.invoiceType,
                                    category:s.category
                                }))}
                                Required={true}
                                valid={!errors.shiftType && getValues().shiftType}
                                error={errors.shiftType && "Please select a shift type"}
                                onChange={onShiftChange}
                                visibleOptionsQty={6}
                            />
                            {routeStatus && (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="routeStatus"
                                        options={[
                                            { label: "Paid", value: "1" },
                                            { label: "Unpaid", value: "2" },
                                            { label: "Holiday", value: "3" },
                                        ]}
                                        Required={true}
                                        valid={errors.routeStatus && getValues().routeStatus}
                                        error={errors.routeStatus && errors.routeStatus.message}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="isVoluntary"
                                        options={[{ value: true, label: "Voluntary" }]}
                                    />
                                </Spacer>
                            )}
                            {backupStatus ? (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="backupStatus"
                                        options={[
                                            { label: "Rostered", value: "1" },
                                            { label: "Rescuer", value: "2" }
                                        ]}
                                        Required={true}
                                        valid={errors.backupStatus && getValues().backupStatus}
                                        error={errors.backupStatus && errors.backupStatus.message}
                                        //defaultValue={[""]}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="onCall"
                                        options={[{ value: true, label: "onCall" }]}
                                    />
                                </Spacer>
                            ) : (
                                <Input type="hidden" defaultValue="false" name="onCall" ref={register} />
                            )}
                            <Spacer top={3} />
                            <Dropdown
                                ref={register}
                                name="invoiceType"
                                label="Invoice Type"
                                options={invoiceOptions.map((i) => ({
                                    name: i.name,
                                    value: i.id,
                                }))}
                                defaultValue={selectedInvoice}
                            />
                            <Spacer bottom={3} />
                            <TimePicker
                                ref={register({
                                    required: "please select time",
                                })}
                                Required={true}
                                name="startTime"
                                label="Start Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.startTime && "Please select Start Time"}
                            />
                            <Spacer right={5} inline />
                            <TimePicker
                                ref={register({
                                    required: true,
                                })}
                                Required={true}
                                name="endTime"
                                label="End Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.endTime && "Please select End Time"}
                            />
                        </Spacer>
                    )}
                    {config.type === "SCHEDULER_ADD_ROUTE_WITH_DATE" && (
                        <Spacer bottom={3}>
                            <DayPicker
                                label="Date"
                                ref={register({
                                    required: true,
                                })}
                                onChange={(date) => {
                                    if (date.utc) {
                                        const utc = moment.utc(date.utc);
                                        if (utc.isValid()) {
                                            onDateChange(utc.format("YYYY-MM-DD"), station);
                                            console.log("onChange DayPicker ", getValues().shiftType);
                                            onShiftChange(getValues().shiftType);
                                        }
                                    }
                                }}
                                name="date"
                                defaultValue={moment(config.date).format("YYYY-MM-DD")}
                                size={"medium"}
                            />
                            <Spacer bottom={3} />
                            <ComboBox
                                ref={register({
                                    required: true,
                                })}
                                name="shiftType"
                                label="Shift Type"
                                type="shiftIcon"
                                input={false}
                                options={shiftOptions.map((s) => ({
                                    name: s.name,
                                    value: s.id,
                                    color: s.color,
                                    invoiceType: s.invoiceType,
                                    category:s.category
                                }))}
                                Required={true}
                                valid={!errors.shiftType && getValues().shiftType}
                                error={errors.shiftType && "Please select a shift type"}
                                onChange={onShiftChange}
                                visibleOptionsQty={6}
                            />
                            {routeStatus && (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="routeStatus"
                                        options={[
                                            { label: "Paid", value: "1" },
                                            { label: "Unpaid", value: "2" },
                                            { label: "Holiday", value: "3" },
                                        ]}
                                        Required={true}
                                        valid={errors.routeStatus && getValues().routeStatus}
                                        error={errors.routeStatus && errors.routeStatus.message}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="isVoluntary"
                                        options={[{ value: true, label: "Voluntary" }]}
                                    />
                                </Spacer>
                            )}
                            {backupStatus ? (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="backupStatus"
                                        options={[
                                            { label: "Rostered", value: "1" },
                                            { label: "Rescuer", value: "2" }
                                        ]}
                                        Required={true}
                                        valid={errors.backupStatus && getValues().backupStatus}
                                        error={errors.backupStatus && errors.backupStatus.message}
                                        //defaultValue={[""]}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="onCall"
                                        options={[{ value: true, label: "onCall" }]}
                                    />
                                </Spacer>
                            ) : (
                                <Input type="hidden" defaultValue="false" name="onCall" ref={register} />
                            )}
                            <Spacer top={3} />
                            <Dropdown
                                ref={register}
                                name="invoiceType"
                                label="Invoice Type"
                                options={invoiceOptions.map((i) => ({
                                    name: i.name,
                                    value: i.id,
                                }))}
                                defaultValue={selectedInvoice}
                            />
                            <Spacer bottom={3} />
                            <TimePicker
                                ref={register({
                                    required: "please select time",
                                })}
                                Required={true}
                                name="startTime"
                                label="Start Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.startTime && "Please select Start Time"}
                            />
                            <Spacer right={5} inline />
                            <TimePicker
                                ref={register({
                                    required: true,
                                })}
                                Required={true}
                                name="endTime"
                                label="End Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.endTime && "Please select End Time"}
                            />
                            <Spacer right={5} inline />
                            <Dropdown
                                ref={register({
                                    required: true,
                                })}
                                name="station"
                                label="Station"
                                type="color"
                                options={stationOptions.map((s) => ({
                                    name: s.code,
                                    value: s.id,
                                }))}
                                defaultValue={station}
                                onChange={(o) => {
                                    onStationChange(o.value);
                                }}
                                Required={true}
                                valid={!errors.station && getValues().station}
                                error={errors.station && "Please select a station"}
                                visibleOptionsQty={6}
                            />
                            <Spacer bottom={3} inline />
                        </Spacer>
                    )}
                    {config.type === "SCHEDULER_ADD_ROUTE" && (
                        <Spacer bottom={3}>
                            <ComboBox
                                ref={register({
                                    required: true,
                                })}
                                name="shiftType"
                                label="Shift Type"
                                type="shiftIcon"
                                input={true}
                                options={shiftOptions.map((s) => ({
                                    name: s.name,
                                    value: s.id,
                                    color: s.color,
                                    invoiceType: s.invoiceType,
                                    category: s.category,
                                }))}
                                Required={true}
                                valid={!errors.shiftType && getValues().shiftType}
                                error={errors.shiftType && "Please select a shift type"}
                                onChange={onShiftChange}
                                visibleOptionsQty={6}
                            />
                            {routeStatus && (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="routeStatus"
                                        options={[
                                            { label: "Paid", value: "1" },
                                            { label: "Unpaid", value: "2" },
                                            { label: "Holiday", value: "3" },
                                        ]}
                                        Required={true}
                                        valid={errors.routeStatus && getValues().routeStatus}
                                        error={errors.routeStatus && errors.routeStatus.message}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="isVoluntary"
                                        options={[{ value: true, label: "Voluntary" }]}
                                    />
                                </Spacer>
                            )}
                            {backupStatus ? (
                                <Spacer top={3}>
                                    <Label>Shift Status</Label>
                                    <RadioBox
                                        ref={register({ required: "Required Shift Status" })}
                                        name="backupStatus"
                                        options={[
                                            { label: "Rostered", value: "1" },
                                            { label: "Rescuer", value: "2" }
                                        ]}
                                        Required={true}
                                        valid={errors.backupStatus && getValues().backupStatus}
                                        error={errors.backupStatus && errors.backupStatus.message}
                                        //defaultValue={[""]}
                                    />
                                    <Spacer top={3}></Spacer>
                                    <CheckBox
                                        ref={register()}
                                        name="onCall"
                                        options={[{ value: true, label: "onCall" }]}
                                    />
                                </Spacer>
                            ) : (
                                <Input type="hidden" defaultValue="false" name="onCall" ref={register} />
                            )}
                            <Spacer top={3} />
                            <Dropdown
                                ref={register}
                                name="invoiceType"
                                label="Invoice Type"
                                options={invoiceOptions.map((i) => ({
                                    name: i.name,
                                    value: i.id,
                                }))}
                                defaultValue={selectedInvoice}
                            />
                            <Spacer bottom={3} />
                            <TimePicker
                                ref={register({
                                    required: "please select time",
                                })}
                                Required={true}
                                name="startTime"
                                label="Start Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.startTime && "Please select Start Time"}
                            />
                            <Spacer right={5} inline />
                            <TimePicker
                                ref={register({
                                    required: true,
                                })}
                                Required={true}
                                name="endTime"
                                label="End Time"
                                size="small"
                                timeZoneShortName={options.timeZoneShortName}
                                dateForTimePicker={currentShiftDate}
                                error={errors.endTime && "Please select End Time"}
                            />
                            <Spacer right={5} inline />
                            <Dropdown
                                ref={register({
                                    required: true,
                                })}
                                name="station"
                                label="Station"
                                type="color"
                                options={stationOptions.map((s) => ({
                                    name: s.code,
                                    value: s.id,
                                }))}
                                onChange={(o) => {
                                    onStationChange(o.value);
                                }}
                                defaultValue={station}
                                Required={true}
                                valid={!errors.station && getValues().station}
                                error={errors.station && "Please select a station"}
                                visibleOptionsQty={6}
                            />
                        </Spacer>
                    )}
                    <TagInput
                        ref={register({
                            required: true,
                        })}
                        name="driver"
                        label="Driver"
                        options={options.map((d) => ({ name: d.name, value: d.id }))}
                        Required={true}
                        valid={!errors.driver && getValues().driver}
                        error={errors.driver && "Please select a driver"}
                        singleOption={false}
                        autoFocus={true}
                    />
                    <Spacer top={5} style={{ textAlign: "right" }}>
                        <Button type="primary">Select</Button>
                    </Spacer>
                </form>
            )}
        </Modal>
    );
};

const showDriverSelection = async (opts, api, config) => {
    let { children } = opts || {};

    return dialogPromise((success, cancel) => (
        <DriverSelection success={success} cancel={cancel} children={children} api={api} config={config} />
    ));
};

export { showDriverSelection };
