import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { HeaderContainer } from './NewDirectMessageAdd'
import { FlexScroll, Grid, TagInputContainer, Text } from '../styles'
import { CloseIcon, useChatContext } from 'stream-chat-react'
import { useDispatch, useTrackedState } from '../store';
import { initialState, reducer } from '../CGCOptions';
import { getEmployeesTwilio, getChannelFilters, channelFilterForDB } from '../utils';
import Member from './Member';
import Loading from '../../components/Loading';
import { TagInput, async } from '@dspworkplace/ui';
import { alert } from '../../components/Alert';
import { confirm } from '../../components/Confirm';
import { engine,getChatCompanyId,showErrorToast } from '../../Utilities';

function UpdateMembers({ channel, isMenConstract, isPage, isUpdateModalOpen, setUpdateModalOPen }) {
    const dispatch = useDispatch();
    const { chatClient, isNewChannel, currentChannel } = useTrackedState();
    const [newChannelModalState, newChannelModalDispatch] = useReducer(reducer, initialState);
    const { setActiveChannel } = useChatContext();
    const {
        loadingUsers,
        errorMessage,
        users,
        matchUsers,
        usersToAdd,
        loadingCreating,
        tagsDefaultAutoUsers, defaultUsers
    } = newChannelModalState;
    var [refresh, setRefresh] = useState(1);
    // const allMembers = Object.values(channel?.state?.members);
    // const [users, AddUsers] = useState([])
    var [refresh, setRefresh] = useState(1);

    const [members, setMembers] = useState([]);
    var allUsers = []

    const tagsDefaultAutoUsersStreamIndentity = tagsDefaultAutoUsers.map(tag => tag.value);
    const currentUserCount = `total ${matchUsers.filter(user => !tagsDefaultAutoUsersStreamIndentity.includes(user.streamIdentity)).length+defaultUsers.length} (${defaultUsers.length} default) members`;

    useEffect(() => {
        getMembers()
    }, [refresh]);

    async function getMembers() {
        // await setLoading(true)
        let offset = 0;
        let limit = 30;
        let hasData = true;
        let group_member = []
        const loop = async () => {
            if (hasData === true) {
                // while (hasData) {
                let data = await getMembersData(offset, limit)
                if (data.length > 0) {
                    await setMembers(memberList => [...memberList, ...data])
                    allUsers = [...allUsers, ...data]
                    offset = offset + limit
                } else {
                    hasData = false
                    // await setLoading(false)
                }
            }
            if (hasData === true) setTimeout(loop, 0)
        }
        // kickstart the loop
        await loop();
        await setChannelData()
    }

    async function getMembersData(offset, limit) {
        const data = await channel.queryMembers({}, { created_at: -1 }, { limit: limit, offset: offset });
        return data?.members
    }

    const setChannelData = async () => {
        const abortController = new AbortController();
        newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
        getEmployeesTwilio()
            .then(async result => {
                if (result) {
                    var matchUser = await result?.users?.filter(function (cv) {
                        return !result.defaultAutoUsers.find(function (e) {
                            return e.streamIdentity == cv.streamIdentity;
                        });
                    });
                    matchUser = await matchUser.filter(function (cv) {
                        return !allUsers?.find(function (e) {
                            return e.user_id == cv.streamIdentity;
                        });
                    });

                    let currentChannelUsers = allUsers.map((u)=> u.user_id);

                    let existingUsers = await result?.users?.filter(function (cv) {
                        return currentChannelUsers?.includes(cv.streamIdentity);
                    });

                    let { filterResult: filters } = await getChannelFilters(channel.id);

                    try {
                        await newChannelModalDispatch({
                            type: "SET_USERS",
                            users: result.users,
                            loadingUsers: false,
                            roles: result.roles,
                            skills: result.skills,
                            stations: result.stations,
                            schedules: result.schedules,
                            previousFilter: filters,
                            teams: result.teams,
                            chatVariable: result.chatVariable,
                            defaultAutoUsers: result.defaultAutoUsers,
                            // matchUser: result.users,
                        });

                        await newChannelModalDispatch({ type: "SET_EXISTING", users: existingUsers, filterSearch: filters})
                        // for (var i = 0; i < members.length; i++) {
                        //     if (!result.defaultAutoUsers.find(data => data?.userId == members[i]?.user?.userid)) {
                        //         newChannelModalDispatch({ type: "ADD_MEMBER", userId: members[i].user.userid });
                        //     }
                        // }
                    } catch (e) {
                        console.error({ e });
                    }
                }
            })
            .catch(error => {
                console.error({ error });
                newChannelModalDispatch({
                    type: "SET_USERS",
                    users: [],
                    loadingUsers: false,
                    roles: [],
                    skills: [],
                    stations: [],
                    schedules: [],
                    teams: [],
                    chatVariable: [],
                    defaultAutoUsers: [],
                });
            });

        return () => {
            abortController.abort();
        };
    }

    // useEffect(() => {
    //     if (newChannelModalState.users.length == 0) {
    //         const abortController = new AbortController();
    //         newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
    //         getEmployeesTwilio()
    //             .then(async result => {
    //                 if (result) {
    //                     try {
    //                         await newChannelModalDispatch({
    //                             type: "SET_USERS",
    //                             users: result.users,
    //                             loadingUsers: false,
    //                             roles: result.roles,
    //                             skills: result.skills,
    //                             stations: result.stations,
    //                             schedules: result.schedules,
    //                             teams: result.teams,
    //                             chatVariable: result.chatVariable,
    //                             defaultAutoUsers: result.defaultAutoUsers,
    //                         });

    //                         for (var i = 0; i < members.length; i++) {
    //                             if (!result.defaultAutoUsers.find(data => data?.userId == allMembers[i]?.user?.userid)) {
    //                                 newChannelModalDispatch({ type: "ADD_MEMBER", userId: allMembers[i].user.userid });
    //                             }
    //                         }
    //                     } catch (e) {
    //                         console.error({ e });
    //                     }
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error({ error });
    //                 newChannelModalDispatch({
    //                     type: "SET_USERS",
    //                     users: [],
    //                     loadingUsers: false,
    //                     roles: [],
    //                     skills: [],
    //                     stations: [],
    //                     schedules: [],
    //                     teams: [],
    //                     chatVariable: [],
    //                     defaultAutoUsers: [],
    //                 });
    //             });

    //         return () => {
    //             abortController.abort();
    //         };
    //     }
    // }, [newChannelModalDispatch, refresh, members]);

    // const addMember = useCallback(
    //     async userId => {
    //         await newChannelModalDispatch({ type: "ADD_MEMBER", userId: userId });
    //         await AddUsers((prevState) => [...prevState, userId])
    //     },
    //     [newChannelModalDispatch]
    // );

    // const removeMember = useCallback(
    //     async userId => {
    //         const user = await allUsers?.find(i => i.user_id == userId)
    //         if (user) {
    //             const confirmation = await confirm({
    //                 icon: true,
    //                 text: "Are you sure you want to remove user in group"
    //             });
    //             if (confirmation) {
    //                 await channel.removeMembers([user?.user_id], { text: `${user?.user.name} removed in the group.` })
    //                 await newChannelModalDispatch({ type: "REMOVE_MEMBER", userId: userId });
    //                 setUpdateModalOPen(false);
                    
    //             }
    //         } else {
    //             await AddUsers(users.filter(item => item !== userId))
    //             await newChannelModalDispatch({ type: "REMOVE_MEMBER", userId: userId });
    //         }
    //     },
    //     [newChannelModalDispatch]
    // );

    const handleTagChange = data => {
        setTimeout(() => {
            newChannelModalDispatch({ type: "SET_MATCHES", filterSearch: data });
        }, 50);
    };

    const updateChannelMembers = async () => {
        // new method
        let removeChannelMembers = [];
        let addChannelMembers = [];
        // let currentUserList = [];
        let removeUser = [];
        // let currentUserList = [...matchUsers ,...defaultUsers];
        // currentUserList = await [...new Set(currentUserList)];
        // currentUserList = currentUserList.map((u)=>u.user_id);
        members.map((member)=>{
            let findUser = users.find((u)=>{return member.user_id == u.streamIdentity});
            if(!findUser){
                removeUser.push(member);
            }
        });
        // remove terminated user
        if(removeUser.length >0){
            const arrayOfChunks = chunkArray(removeUser, 30);
            for (let i = 0; i < arrayOfChunks.length; i++) {
                const chunkIdentity = arrayOfChunks[i].map((u)=>u.user_id);
                var chunkNames = arrayOfChunks[i].map((u)=>u.user.name);
                chunkNames = chunkNames.join(", ");
                await channel.removeMembers(chunkIdentity, { text: `${chunkNames} removed from the group.` });
            }
        }
        let currentUserList = [...matchUsers ,...defaultUsers];
        currentUserList = await [...new Set(currentUserList)];

        if (currentUserList.length > 0) {
            if(usersToAdd && usersToAdd.length > 0) {
                let currentUserListIdentifier = currentUserList.map(user => user.streamIdentity);
                usersToAdd.map(user => {
                    if(!currentUserListIdentifier.includes(user.streamIdentity)){
                        removeChannelMembers.push(user);
                    }
                });
                
                let usersToAddIdentifier = usersToAdd.map(user => user.streamIdentity);
                currentUserList.map((user) => {
                    if(!usersToAddIdentifier.includes(user.streamIdentity)){
                        addChannelMembers.push(user);
                    }
                });
            }else{
                addChannelMembers = currentUserList;
            }

            if(addChannelMembers.length >0){
                const arrayOfChunks = chunkArray(addChannelMembers, 30);
                for (let i = 0; i < arrayOfChunks.length; i++) {
                    const chunkIdentity = arrayOfChunks[i].map((u)=>u.streamIdentity);
                    var chunkNames = arrayOfChunks[i].map((u)=>u.friendlyName);
                    chunkNames = chunkNames.join(", ");
                    await channel.addMembers(chunkIdentity, { text: `${chunkNames} added in the group.` });
                }
            }
            if(removeChannelMembers.length >0){
                const arrayOfChunks = chunkArray(removeChannelMembers, 30);
                for (let i = 0; i < arrayOfChunks.length; i++) {
                    const chunkIdentity = arrayOfChunks[i].map((u)=>u.streamIdentity);
                    var chunkNames = arrayOfChunks[i].map((u)=>u.friendlyName);
                    chunkNames = chunkNames.join(", ");
                    await channel.removeMembers(chunkIdentity, { text: `${chunkNames} removed from the group.` });
                }
            }
            let { roles, skills, stations, schedules, specialUsers, teams, chatVariable } = channelFilterForDB(newChannelModalState.filterCurrentSearch, newChannelModalState, currentUserList);
            let params = {
                case: 'members',
                roles:roles,
                skills:skills,
                stations:stations,
                schedules:schedules,
                filterResult:newChannelModalState.filterCurrentSearch,
                teams:teams,
                chatVariable:chatVariable,
                members: specialUsers,
                sid: channel.id,
                friendlyName: channel.data.name,
                company: getChatCompanyId(),
                currentMembers: currentUserList,
                currentMembersCount: currentUserList.length,
                env: process.env.NODE_ENV
            };
            
            await engine().post('/api/bot/channel/group/settings', params).catch(async e => {
                await showErrorToast(e, 'Auto Update', 'Auto Update Channel failed.');
            });
        }
        
        // old method
        // let userList = await users.map(item => { return item });
        // if (userList.length > 0) {
        //     const arrayOfChunks = chunkArray(userList, 99);
        //     for (let i = 0; i < arrayOfChunks.length; i++) {
        //         var userNames = "";
        //         for (var j = 0; j < arrayOfChunks[i].length; j++) {
        //             const data = usersToAdd.find(item => item.streamIdentity == arrayOfChunks[i][j])
        //             if (j == 0)
        //                 userNames = userNames + " " + data?.friendlyName
        //             else
        //                 userNames = userNames + ", " + data?.friendlyName
        //         }
        //         await channel.addMembers(arrayOfChunks[i], { text: `${userNames} added in the group.` });
        //     }
        // }
        // let params = {
        //     case: 'members',
        //     roles:newChannelModalState.roles,
        //     skills:newChannelModalState.skills,
        //     stations:newChannelModalState.stations,
        //     schedules:newChannelModalState.schedules,
        //     filterResult:newChannelModalState.filterCurrentSearch,
        //     teams:newChannelModalState.teams,
        //     chatVariable:newChannelModalState.chatVariable,
        //     members: userList,
        //     sid: channel.id,
        //     friendlyName: channel.data.name,
        //     company: getChatCompanyId(),
        //     currentMembers: userList,
        //     currentMembersCount: userList.length,
        //     env: process.env.NODE_ENV
        // };
        
        // await engine().post('/api/bot/channel/group/settings', params).catch(async e => {
        //     await showErrorToast(e, 'Auto Update', 'Auto Update Channel failed.');
        // });

        // var deletedMembers = allMembers.filter(member => { return !userList.includes('user-' + member.user.userid) });
        // var deletedMembersList = deletedMembers.map(item => { return item.user.id });
        // var deletedUserNames = deletedMembers.map(item => { return item.user.name; }).join(', ');
        // if (deletedMembersList.length > 0) {
        //     await channel.removeMembers(deletedMembersList, { text: `${deletedUserNames} removed in the group.` })
        // }
        await setUpdateModalOPen(false)
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize);
          chunks.push(chunk);
        }
        return chunks;
    }

    // async function selectAll() {
    //     try {
    //         // var addUser = await matchUsers.slice(0, 100)
    //         var addUser = await matchUsers;
    //         var matchUser = await matchUsers?.filter(function (cv) {
    //             return !addUser.find(function (e) {
    //                 return e.userId == cv.userId;
    //             });
    //         });

    //         const users = await addUser.map(item => { return item.streamIdentity })
    //         await AddUsers((prevState) => [...prevState, ...users])
    //         await newChannelModalDispatch({ type: "SELECT_ALL", usersToAdd: [...usersToAdd, ...addUser], matchUser: matchUser })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async function undoSelection() {
        setRefresh(++refresh)
    }


    return (
        <div style={{
            width: isMenConstract && isPage ? '95%' : isMenConstract && !isPage ? '91%' : isPage ? '82%' : "65%",
            float: "left", backgroundColor: '#F5F6FA', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', height: '100%'
        }} >
            <HeaderContainer>
                <Text color={'#000000'} fontWeight={"500"} margin="0px" fontSize="18px" >
                    Update Members</Text>
                <div style={{ cursor: 'pointer', }} onClick={() => setUpdateModalOPen(false)} >
                    <CloseIcon />
                </div>
            </HeaderContainer>
            {loadingUsers || loadingCreating ?
                <div style={{ display: "flex", height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }} >
                    <Loading height={'60px'} width="60px" />
                </div>
                :
                <div style={{ padding: '16px', backgroundColor: 'white' }} >
                    <div style={{ marginTop: '10px' }} >
                        <TagInputContainer width="auto" borderRadius="7px" templateColumns="100% auto">
                            <TagInput
                                options={newChannelModalState.filterArray}
                                size="big"
                                onChange={handleTagChange}
                                defaultValues={newChannelModalState.filterCurrentSearch.map((f)=>f.value)}
                                name={'tags'}
                                placeholder="Find by name, email, skill, role, station and schedule:"
                            />
                        </TagInputContainer>
                    </div>
                    <Grid rowGap="8px" templateRows="auto auto" height={"auto"} margin='15px 0 0 0'>
                        <FlexScroll height={"755px"} width="100%" key="second">

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text margin="10px" fontSize="14px" color="#0071BC">Previously total {members.length} members / After Update {currentUserCount}</Text>
                               
                                <Text margin="10px" fontSize="14px" color="gray" style={{ borderBottom: '1px solid', borderBottomColor: 'gray', cursor: 'pointer' }}
                                    onClick={() => undoSelection()}  >
                                    Undo</Text>
                            </div>

                            {Array.isArray(defaultUsers) && defaultUsers.map((item, index) => {
                                // if (item.streamIdentity == chatClient.userID) {
                                //     return <></>;
                                // }
                                let isNewUser = true;
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity == item.streamIdentity)) {
                                    isNewUser = false;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        // removeMember={() => removeMember(item.streamIdentity)}
                                        selectedUser={item}
                                        remove={tagsDefaultAutoUsers.filter(e => e.name === item.friendlyName).length === 0}
                                        index={index}
                                        isNewUser={isNewUser}
                                    />
                                );
                            })}
                            {Array.isArray(matchUsers) && matchUsers.map((item, index) => {
                                // if (item.streamIdentity == chatClient.userID) {
                                //     return <></>;
                                // }
                                if(tagsDefaultAutoUsers.filter(e => e.value == item.streamIdentity).length > 0){
                                    return <></>;
                                }
                                
                                let isNewUser = true;
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity == item.streamIdentity)) {
                                    isNewUser = false;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        // removeMember={() => removeMember(item.streamIdentity)}
                                        selectedUser={item}
                                        remove={tagsDefaultAutoUsers.filter(e => e.name === item.friendlyName).length === 0}
                                        index={index}
                                        isNewUser={isNewUser}
                                    />
                                );
                                
                            }).reverse()}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text margin="10px" fontSize="14px" color="#0071BC">Select New Members</Text>
                                <Text margin="10px" fontSize="14px" color="gray" style={{ borderBottom: '1px solid', borderBottomColor: 'gray', cursor: 'pointer' }}
                                    onClick={() => selectAll()}  >
                                    Select All Users</Text>
                            </div>
                            {Array.isArray(matchUsers) && matchUsers.map((item, index) => {
                                if (item.streamIdentity == chatClient.userID) {
                                    return <></>;
                                }
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity === item.streamIdentity)) {
                                    return <></>;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        callback={() => addMember(item.streamIdentity)}
                                        index={index}
                                    />
                                );
                            })} */}
                        </FlexScroll>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'absolute', right: '40px', bottom: '10px', }} >
                        <div style={{ backgroundColor: ((matchUsers.length + defaultUsers.length) > 0) ? '#0071BC' : '#CDCED0', height: '35px', width: '150px', display: 'flex', justifyContent: 'center', borderRadius: '5px', cursor: ((matchUsers.length + defaultUsers.length) > 0) > 0 ? 'pointer' : 'none' }}
                            onClick={() => ((matchUsers.length + defaultUsers.length) > 0) ? updateChannelMembers() : null}
                        >
                            <Text textAlign="center" color='white' fontWeight="500" >Update</Text>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateMembers