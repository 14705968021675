import React, { useEffect, useContext, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Dropdown, Icon, Input, Spacer, SubTitle, Theme, Button, CheckBox } from "@dspworkplace/ui";
import styled from "styled-components";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import Store, { VehicleProvider } from "./Context";
import Modal from "../../../components/Modal/index";
import { Btn } from "../../../components/Grid/Tools/Filter/Filter";

import DatePicker from "../../../components/Calendar/picker";

import { useAuth } from "../../../Auth";

import { fetchVehicles } from "./api";

import moment from "moment";
import { Header } from "../../../components/Grid/Tools/Filter/Filter";
import { Badge } from "../../../components/UI";
import Tooltip from "../../../components/Tooltip/tooltip";
import FilterBottom from "../../../components/FilterBottom";

const FilterContainer = styled.div`
    display: flex;
`;

function Filter(props) {
    const [state, dispatch] = useContext(Store);
    const [selectedStation] = useSelectedStation();
    const { api } = useAuth();
    const companyId = localStorage.getItem("company");
    const { open, setOpen } = props;
    const [badge, setBadge] = useState(0);
    const fetchVehiclesOpts = async () => {
        const result = await fetchVehicles(api, companyId, selectedStation);
        dispatch({ type: "VEHICLES_OPTS", payload: { data: result } });
    };
    const [val, setVal] = useState({ station: selectedStation, status: "all", type: "all", search: "", vehicleId: "all", modelType: "all" });

    useEffect(() => {
        fetchVehiclesOpts();
    }, [selectedStation]);

    const getFilterValuesFromStorage = () => {
        let filterFromStorage = sessionStorage.getItem("vehicle-mgmt-filter");
        const defaultFilter = { station: selectedStation, status: "all", type: "all", modelType: "all", search: "", vehicleId: "all" }
        try {
            filterFromStorage = JSON.parse(filterFromStorage) || defaultFilter;
        } catch (error) {
            filterFromStorage = defaultFilter
        }
        return defaultFilter
    }

    const { register, handleSubmit, reset, setValue, getValues } = useForm({
        mode: "onChange",
        defaultValues: getFilterValuesFromStorage(),
    });

    const onSubmit = (data) => {
        props.setIsButtonClicked(!props.isButtonClicked)
        const tempData = { ...data }
        // delete tempData.vehicleId;
        setVal({ ...val, ...tempData });
        sessionStorage.setItem("vehicle-mgmt-filter", JSON.stringify({ ...val, ...tempData }));
        dispatch({
            type: "SEARCH",
            payload: { ...val, ...tempData },
        });
        setOpen(false);
    };

    const onKeyDownHandler = (e) => {
            if (e.keyCode === 13) {
             e.preventDefault()
             setTimeout(() => handleSubmit(onSubmit)(), 1);
            }
    };

    const optTypes = [
        { name: "All", value: "all" },
        { name: "Rented", value: "Rented" },
        { name: "Owned", value: "Owned" },
        { name: "Leased", value: "Leased" },
    ];

    const StatusLookup = [
        { name: 'All (Non Archived)', value: 'all' },
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' },
        { name: 'Under Repair', value: 'Under Repair' },
        { name: "Grounded", value: "Grounded" },
        { name: 'Being Serviced', value: 'Being Serviced' },
        { name: 'Body Shop', value: 'Body Shop'},
        { name: 'Archived', value: 'Archived' },
    ]

    const vehTypes = [
        { name: 'All', value: 'all' },
        { name: "CDV Van", value: "cdv_van" },
        { name: "Large Cargo", value: "cargo_van" },
        { name: "XL Cargo", value: "xl_cargo_van" },
        { name: "Small Cargo", value: "small_cargo" },
        { name: "Box", value: "box" },
        { name: "Step Van", value: "step_van" },
        { name: "EDV Van", value: "edv_van" }
    ];

    const selectedDate = (e, x) => {

        const values = getFilterValuesFromStorage();
        delete values.vehicleId;
        sessionStorage.setItem("vehicle-mgmt-filter", JSON.stringify({
            ...values
        }));
        dispatch({
            type: "SELECTED_DATE",
            payload: e,
        });
    };

    useEffect(() => {
        const filterFromStorage = getFilterValuesFromStorage()
        if (selectedStation !== undefined) {
            dispatch({
                type: "SEARCH",
                payload: { ...filterFromStorage, station: selectedStation },
            });
        }
    }, [state.isLoading]);

    const submit = () => {
        setTimeout(() => handleSubmit(onSubmit)(), 1);
    }

    const handleFilterSelect = (filter) => {
        console.log("filter>>", filter);
    }

    useEffect(() => {
        reset(val);
        let i = 0;
        let x = getValues();
       
        Object.keys(x).forEach((item) => {
            if (x[item] !== "all" && x[item] != ""&&item!="search"&&item!="station") {
                i++;
            }
        });
        setBadge(i);
    }, [state.isLoading, open])



    const TooltipContent = ({ content }) => {
        return <div>{content}</div>
    }

    const ButtonRender = ({ children, content }) => {
        if (!content || content.length < 1) return children
        return <Tooltip direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
    }

    const TooltipRender = ({ label, value }) => {
        return <p>{label} = {value}</p>
    }

    const appliedFilters = useMemo(() => {
        const filters = []
        Object.keys(val).forEach(filterKey => {
            const value = val[filterKey].hasOwnProperty('value') ? val[filterKey].value : val[filterKey];

            // @ts-ignore            
            if (value !== null && value != '' && value != "all"&&filterKey!="station") filters.push(<TooltipRender label={filterKey} value={value} key={filterKey} />);
        })
        return filters
    }, [val]);

    const clearHandler = () => {
        setVal({ station: selectedStation, status: "all", type: "all", search: "", vehicleId: "all", modelType: "all" });
        reset({ station: selectedStation, status: "all", type: "all", search: "", vehicleId: "all", modelType: "all"})
        props.setIsButtonClicked(!props.isButtonClicked)
        const tempData = { station: selectedStation, status: "all", type: "all", search: "", vehicleId: "all", modelType: "all"}
        setVal(tempData);
        sessionStorage.setItem("vehicle-mgmt-filter", JSON.stringify(tempData))
        dispatch({
            type: "SEARCH",
            payload: tempData,
        });
    }

    return (
        <div>

            <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <StationSelector
                    ref={register}
                    name={"station"}
                    onChange={async (e) => {
                        await setValue("station", e.value);
                        setTimeout(() => handleSubmit(onSubmit)(), 1);
                    }}
                />
                <Spacer inline right={5} />
                <Input
                    label="Search"
                    name="search"
                    ref={register}
                    onKeyDown={onKeyDownHandler}
                    placeholder="Filter Unit #, model, location, etc"
                >
                    <Icon.Search
                        color={Theme.colors.info.border}
                        size={"24px"}
                        onClick={() => {
                            setTimeout(() => handleSubmit(onSubmit)(), 1);
                        }}
                    />
                </Input>
                <Spacer inline right={3} />

                <Spacer inline right={3} />
                <ButtonRender content={appliedFilters}>
                    <Btn onClick={() => setOpen(true)}>
                        <span>Filter</span>
                        <Icon.Filter />
                        {badge > 0 && <Badge>{badge}</Badge>}
                    </Btn>
                </ButtonRender>
            </div>
            <Modal
                width={'340px'}
                visible={open}
                title={'Filters'}
                setVisible={() => setOpen(false)}
                drawer={'right'}
                key={'modal-filter'}
                closeOnOverlay={true}
                zindex={999}
                removeHeader={true}
                paddingTopBottomZero={true}
            >
                <FilterContainer style={{ height: "100vh" }}>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                        <Header>
                            <SubTitle>Filters</SubTitle>
                            <Button type="cancel" onClick={() => {
                                clearHandler()
                            }}>
                                Clear
                            </Button>
                        </Header>
                        <div style={{ flex: 1, overflow: "auto" }}>
                            <Dropdown
                                name="status"
                                label="Status"
                                options={StatusLookup}
                                size="large"
                                ref={register}
                                onChange={async (e) => {
                                    await setValue("status", e.value);
                                }}
                                visibleOptionsQty={7}
                            />
                            <Spacer inline right={5} />
                            <Dropdown
                                name="type"
                                label="Type"
                                options={optTypes}
                                size="large"
                                ref={register}
                                onChange={async (e) => {
                                    await setValue("type", e.value);
                                }}
                                visibleOptionsQty={7}
                            />
                            <Spacer top={2} />
                            <Spacer top={5} right={5} />
                            <Dropdown
                                name="vehicleId"
                                label="Vehicles"
                                options={state.vehiclesOpts}
                                defaultValue={getValues().vehicleId}
                                size="large"
                                ref={register}
                                onChange={async (e) => {
                                    await setValue("vehicleId", e.value);
                                }}
                                visibleOptionsQty={7}
                            />
                            <Spacer top={2} />
                            <Spacer inline right={3} />
                            <Dropdown
                                name="modelType"
                                label="Model Type"
                                size={"large"}
                                ref={register}
                                options={vehTypes}
                                onChange={async (e) => {
                                    await setValue("modelType", e.value);
                                }}
                                visibleOptionsQty={7}
                            />
                            <Spacer top={5} />
                            <CheckBox
                                name='unenroll'
                                ref={register}
                                options={[
                                    { value: 'true', label: 'Vehicles Unenrolled from Auto-Assignment' }
                                ]}
                            />
                            <Spacer inline right={5} />
                        </div>
                        <FilterBottom
                            onFilterSelect={handleFilterSelect}
                        />
                    </form>
                </FilterContainer>
            </Modal>
        </div>
    );
}

export default Filter;
