import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import LoadOutStore, { LoadOutProvider } from "./context";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { useForm } from "react-hook-form";
import moment from "moment";
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { incidentPromise } from "../Incidents/form";
import { alert } from "../../components/Alert";
import { confirm } from "../../components/Confirm";
import {
    Button,
    CheckBox,
    CustomDropdown,
    Dropdown,
    Icon,
    Input,
    Password,
    Spacer,
    SubTitle,
    TagInput,
    Text,
    Theme,
    ThirdTitle,
    Toggle,
    RadioBox
} from "@dspworkplace/ui";
import IconAccidentInjury from "./accident_injury.svg";
import { validatePassword } from "../../patterns";
import { Table, TableData, TableHeader } from "../../components/Table";
import Driver from "../../components/People";
import Modal, { useModal } from "../../components/Modal";
import Loading, { LoadingWrapper } from "../../components/Loading";
import Empty from "../../components/Empty";
import Details from "../Employees/details";
import API from "./api";
import { showDriverSelection } from "./driverSelection";
import SSkeletonPulse from "../../components/Skeleton";
import { Badge, Label, Link as UILink } from "../../components/UI";
import { getCompanyId, useAuth, getRole, getAllowsDispatcherPermission } from "../../Auth";
import ES from "../../EventSource";
import ViewSelector from "../Scheduler/Common/ViewSelector";
import StationSelector, { useSelectedStation } from "../../components/StationSelector";
import toast from "../../components/Toasts/toast";
import { openReportIncidentForm } from "../Incidents/Common/ReportIncidentForm";
import ExportButton from "../Scheduler/Common/ExportButton";
import { Action } from "../Scheduler/Common/UI";
import { dialog, dialogPromise, loadingDialog } from "../../components/Dialog";
import useSchedulerApi from "../Scheduler/Common/api";
import { asDatabaseTime } from "../../components/TimeFormat";
import { showFlexibleShiftBlockForm } from "../Scheduler/Week/components/shiftBlockForms";
import PublishPopUp from "../Scheduler/Common/PublishPopUp";
import DayPicker from "../../components/Calendar/dayNew";
import { FilterWeekDate } from "../../hooks/useGlobalData";
import incidentsApi from "../Incidents/api";
import Tooltip from "../../components/Tooltip";
import LoadOutTools from "./tools";
import { fetchPopupTimelineData } from "../../api/utils";
import SimpleScroll from "../../components/SimpleScroll";
import { RowTimeline } from "../Employees/timeLine";
import Timeline from "../../components/Timeline";
import ComboBox from "../../components/ComboBox";
import { addEditDeletePopup } from "../Scheduler/Common/AddEditDeletePopup";


const Styles = createGlobalStyle`
    .nav-main {
        position: fixed;
        top: 0;
        z-index: 4;
    }
`;

const DateContent = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: bold;
    color: ${Theme.colors.neutrals.medium};
`;

const App = () => {
    useEffect(() => {
        return () => {
            let elem = document.querySelector(`[data-id='chat-icon']`);
            if (elem) elem.style.zIndex = "unset";
        };
    }, []);

    return (
        <LoadOutProvider>
            <LoadOutWrapper>
                <Styles />
                <Header />
                <ShiftTypeDrawer />
                <DriversList />
            </LoadOutWrapper>
        </LoadOutProvider>
    );
};
export default App;

// HOC
const LoadOutWrapper = ({ children }) => {
    return (
        <div
            id="load-out-wrapper"
            style={{ background: Theme.colors.neutrals.white, height: "100vh", overflow: "auto" }}
        >
            {children}
        </div>
    );
};

// Misc
const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Fixed = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: ${Theme.colors.neutrals.white};
    margin-top: 0;
    transition: top 0.3s, margin-top 0.3s;
`;

const CompactTableData = styled(TableData)`
    padding: 8px;
    position: relative;
    ${(props) => props.isLoading && props.isLoading == true && `opacity: 0.4; pointer-events: none;`}
`;

const CustomSpacer = styled(Spacer)`
    @media (max-width: 1201px) {
        padding: 4px;
    }
`;

const CustomSubTitle = styled(SubTitle)`
    @media (max-width: 1201px) {
        font-size: 14px;
    }
`;

const CustomButton = styled(Button)`
    @media (max-width: 1201px) {
        padding: 0 8px;
        height: 36px;
        font-size: 12px;
    }
`;

// Header
const Header = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const { api } = useAuth();
    const [state, dispatch] = useContext(LoadOutStore);
    // const [newDate, setNewDate] = useState(moment().format('YYYY-MM-DD'));
    // let selectedDate = moment(newDate).format("YYYY-MM-DD");
    let [newDate, setNewDate] = FilterWeekDate();
    const { register, handleSubmit, reset } = useForm({
        mode: "onBlur",
        defaultValues: state.filterData,
    });

    const updateFilterData = async (station) => {
        if (!station) station = state.waveData.station;

        dispatch({
            type: "SET_LOADING",
            payload: true,
        });

        let data = await API.fetchFilterData(
            {
                station: station,
                date: state.selectedDate,
            },
            api
        );

        if (!state.waveData.station && !station) {
            if (data.stations && data.stations.length > 0) {
                station = data.stations[0].value;
            }
        }

        let wave = await API.fetchWaveData(
            {
                station: station,
                date: state.selectedDate,
            },
            api
        );

        dispatch({
            type: "SET_WAVE_DATA",
            payload: wave,
            callback(state) {
                state.waveData.station = station;
                state.filterData = data;
            },
        });

        let week = moment(state.selectedDate).isoWeek();
        if (moment(state.selectedDate).day() === 0) {
            week = moment(state.selectedDate).add(1, "days").isoWeek();
        }

        let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
        if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
            year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
        }

        let UnpublishResponse = await API.fetchPendingChanges(
            {
                station: state.waveData.station,
                wknumber: week,
                unpublishDataDate: state.selectedDate,
                year: year,
                loadOut: true,
                type: "loadOut",
            },
            api
        );

        let chnagesId = [];
        UnpublishResponse.forEach(function (change) {
            chnagesId.push(change.driverRouteId);
        });

        dispatch({
            type: "SET_PUBLISH_COUNT",
            ids: chnagesId,
            station: state.waveData.station,
            api: true,
        });
        dispatch({
            type: "SET_LOADING",
            payload: false,
        });
    };

    const updateStartTime = async (startTime) => {
        let data = await API.fetchFilterData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        );
        // setFilterData(data);

        let wave = await API.fetchWaveData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        );
        dispatch({
            type: "SET_WAVE_DATA",
            payload: wave,
            callback(state) {
                state.filterData = data;
            },
        });
    };

    const updateStagingLocation = async (station) => {
        dispatch({
            type: "SET_LOADING",
            payload: true,
        });

        const stationMap = await api.post("/api/lazy/manage/data", {
            actions: {
                response: {
                    StationMap: {
                        findBy: {
                            get: "stationMap",
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                isArchive: false,
                            },
                            includes: ["locations"],
                        },
                    },
                },
            },
        });

        if (stationMap) {
            dispatch({
                type: "SET_KEY_VALUE",
                payload: {
                    stationMap: stationMap.data.data.stationMap
                        .map((m) => m.locations)
                        .flat()
                        .map((l) => ({
                            name: l,
                            value: l,
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
                },
            });
        }

        dispatch({
            type: "SET_LOADING",
            payload: false,
        });
    }

    useEffect(() => {
        //API.removeTempData(api);
        updateFilterData();

        const es = new ES("loadout", true);
        es.onmessage = (data) => {
            if (data.event === "PUNCH")
                return dispatch({
                    type: "SET_PUNCH_TIME",
                    payload: data.data,
                });

            if (data.event === "REFRESH") return updateFilterData(localStorage.getItem("selected"));

            if (data.event === "REFRESHSTAGINGLOCATION") return updateStagingLocation(localStorage.getItem("selected"));
        };

        return () => {
            es.close();
        };
    }, [state.selectedDate]);

    useEffect(() => {
        let drawer = document.getElementById("shift-types-drawer-wrapper");
        if (drawer) drawer.style.top = openFilter === true ? "186px" : "80px";

        let wrapper = document.getElementById("load-out-wrapper");
        if (wrapper) {
            wrapper.style.transition = "all .3s";
            wrapper.style.paddingTop = openFilter === true ? "292px" : "184px";
        }
    }, [openFilter]);

    useEffect(() => {
        if (!state.waveData.station) return () => { };

        const incidentsApi = import("../Incidents/api");

        incidentsApi.then((module) => {
            return Promise.all([
                module.default.fetchFormOptions({ station: state.waveData.station }),
                api.post("/api/lazy/manage/data", {
                    actions: {
                        response: {
                            StationMap: {
                                findBy: {
                                    get: "stationMap",
                                    criteria: {
                                        company: getCompanyId(),
                                        station: state.waveData.station,
                                        isArchive: false,
                                    },
                                    includes: ["locations"],
                                },
                            },
                        },
                    },
                }),
            ])
                .then(([incidents, stationMap]) => {
                    dispatch({
                        type: "SET_KEY_VALUE",
                        payload: {
                            incidentTypes: incidents.data.data.incidentTypes,
                            stationMap: stationMap.data.data.stationMap
                                .map((m) => m.locations)
                                .flat()
                                .map((l) => ({
                                    name: l,
                                    value: l,
                                }))
                                .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
                        },
                    });
                })
                .catch((e) => false);
        });

        return () => incidentsApi.then((module) => module.default.cancel());
    }, [dispatch, state.waveData.station]);

    const filterDrivers = (values) => {
        dispatch({
            type: "SET_APPLIED_FILTERS",
            payload: values,
        });
    };

    return (
        <Fixed style={{ zIndex: openMenu ? 3 : 5, marginTop: openMenu ? 53 : 0 }}>
            <FlexWrapper>
                <Spacer all={5}>
                    <Action
                        type="button"
                        onClick={() => {
                            let elem = document.querySelector(`[data-id='chat-icon']`);
                            if (elem) elem.style.zIndex = !openMenu ? 7 : "unset";
                            setOpenMenu(!openMenu);
                        }}
                    >
                        <Icon.Menu size="28px" />
                    </Action>
                    <CustomSpacer right={3} inline />
                    <Action
                        type="button"
                        onClick={() => {
                            setOpenFilter(!openFilter);
                        }}
                    >
                        <Icon.Filter size="28px" />
                        {state && state.appliedFilters > 0 && <Badge>{state.appliedFilters}</Badge>}
                    </Action>
                    <CustomSpacer right={3} inline />
                    <StationSelector
                        showAllOption={false}
                        label={false}
                        name="station"
                        size="extraSmall"
                        onChange={updateFilterData}
                    // defaultValue={state.waveData.station}
                    />
                    <CustomSpacer right={3} inline />
                    <LoadOutTools />
                    <CustomSpacer right={3} inline />
                    {moment(state.selectedDate).format("MM-DD-YYYY") == moment().format("MM-DD-YYYY") ? (
                        <LiveTitle />
                    ) : (
                        <CustomSubTitle style={{ display: "inline-block", height: "40px", lineHeight: "40px" }}>
                            {moment(state.selectedDate).format("ddd, MMM D, Y")}
                        </CustomSubTitle>
                    )}
                </Spacer>
                <Spacer all={5}>
                    {/*<Action>*/}
                    {/*    <Icon.Chat size="28px" />*/}
                    {/*</Action>*/}
                    {/*<Spacer right={3} inline />*/}
                    <Spacer inline={true} style={{ position: "relative", verticalAlign: "top", zIndex: 99 }} right={3}>
                        <Action
                            onClick={() => {
                                setNewDate(moment(state.selectedDate).subtract(1, "day").format("YYYY-MM-DD"));
                                dispatch({
                                    type: "UPDATE_LOADOUT_DATE",
                                    payload: moment(state.selectedDate).subtract(1, "day").format("YYYY-MM-DD"),
                                });
                            }}
                        >
                            <Icon.ArrowLeft
                                size="12px"
                                color={Theme.colors.info.border}
                                style={{ verticalAlign: "text-top" }}
                            />
                        </Action>
                        <DayPicker
                            onChange={(date) => {
                                if (date.utc) {
                                    const utc = moment.utc(date.utc);
                                    if (utc.isValid() && state.selectedDate !== utc.format("YYYY-MM-DD")) {
                                        setNewDate(moment(date.utc).format("YYYY-MM-DD"));
                                        dispatch({
                                            type: "UPDATE_LOADOUT_DATE",
                                            payload: moment(date.utc).format("YYYY-MM-DD"),
                                        });
                                    }
                                }
                            }}
                            defaultValue={state.selectedDate}
                            size={"small"}
                        />
                        <Action
                            onClick={() => {
                                setNewDate(moment(state.selectedDate).add(1, "day").format("YYYY-MM-DD"));
                                dispatch({
                                    type: "UPDATE_LOADOUT_DATE",
                                    payload: moment(state.selectedDate).add(1, "day").format("YYYY-MM-DD"),
                                });
                            }}
                        >
                            <Icon.ArrowRight
                                size="12px"
                                color={Theme.colors.info.border}
                                style={{ verticalAlign: "text-top" }}
                            />
                        </Action>
                    </Spacer>
                    <ExportButton
                        params={{
                            actions: {
                                response: {
                                    DriverRoute: {
                                        custom: {
                                            functionName: "getSchedularLoadOutListingDataForExport",
                                            get: "file",
                                            criteria: {
                                                stationId: state.waveData.station,
                                                drivers: state.drivers,
                                                driverStatus: state.driverStatus,
                                                date: state.selectedDate,
                                                timezoneOffset: new Date().getTimezoneOffset(),
                                            },
                                        },
                                    },
                                },
                            },
                        }}
                    />
                    <CustomSpacer right={3} inline />
                    <ViewSelector />
                </Spacer>
            </FlexWrapper>
            <Filters
                open={openFilter}
                filterData={state.filterData}
                register={register}
                reset={reset}
                as="form"
                onSubmit={handleSubmit(filterDrivers)}
                submitFunction={handleSubmit(filterDrivers)}
            />
        </Fixed>
    );
};

const getTitlePieces = () => {
    let now = moment();
    let color = now.format("s") % 2 === 0 ? Theme.colors.neutrals.white : "inherit";
    let p1 = now.format("ddd, MMM D, Y – h");
    let p2 = now.format("mm") + now.format("a");

    return {
        p1: p1,
        color: color,
        p2: p2,
    };
};

const LiveTitle = () => {
    const [title, setTitle] = useState(getTitlePieces());

    useEffect(() => {
        let titleInterval = setInterval(() => {
            setTitle(getTitlePieces());
        }, 500);

        return () => {
            clearInterval(titleInterval);
        };
    }, []);

    return (
        <CustomSubTitle style={{ display: "inline-block", height: "40px", lineHeight: "40px" }}>
            {title.p1}
            <span style={{ color: title.color }}>:</span>
            {title.p2}
        </CustomSubTitle>
    );
};

// Filters
const FiltersWrapper = styled(FlexWrapper)`
    background: #fafafa;
    border-bottom: 1px solid ${Theme.colors.info.border};
    // align-items: flex-end;

    margin-bottom: -104px;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;

    &:not([open]) {
        height: 106px;
    }

    ${(props) =>
        props.open
            ? `
    opacity: 1;
    margin-bottom: 0;
    pointer-events: initial;
    `
            : ""}
`;

const Filters = ({ open, filterData, register, reset, submitFunction }) => {
    const handleOnChange = (value) => {
        setTimeout(() => submitFunction(), 50);
    };

    const clearFilters = () => {
        reset({
            schedules: [],
            shiftTypes: [],
            skills: [],
            drivers: "",
            clockedOut: false,
            ncns: false,
        });
        submitFunction();
    };

    return (
        <FiltersWrapper open={open}>
            <Spacer all={5}>
                <TagInput
                    ref={register}
                    size="small"
                    name="schedules"
                    label="Schedules"
                    options={filterData.schedules}
                    onChange={handleOnChange}
                />
                <Spacer right={5} inline />
                <CustomDropdown
                    ref={register}
                    size="small"
                    name="shiftTypes"
                    label="Shift Types"
                    multiple={true}
                    type="color"
                    options={filterData.shiftTypes}
                    onChange={handleOnChange}
                />
                <Spacer right={5} inline />
                <TagInput
                    ref={register}
                    name="skills"
                    label="Skills"
                    options={filterData.skills}
                    onChange={handleOnChange}
                    size={"small"}
                />
                <Spacer right={5} inline />
                <Input
                    ref={register}
                    name="drivers"
                    label="Search"
                    placeholder="Name, email, phone, etc"
                    onChange={handleOnChange}
                />
                <Spacer right={5} inline />
                <Spacer top={9} inline>
                    <Toggle
                        ref={register}
                        name="clockedOut"
                        singleText="Clocked-out"
                        on={false}
                        onChange={handleOnChange}
                    />
                </Spacer>
                <Spacer right={35} inline />
            </Spacer>
            <Spacer top={11} right={5} bottom={5} left={5}>
                <Button type="cancel" onClick={clearFilters}>
                    Clear
                </Button>
            </Spacer>
        </FiltersWrapper>
    );
};

// Shift Type Drawer
const ShiftTypesDrawerWrapper = styled(FiltersWrapper)`
    opacity: 1;
    margin-bottom: 0;
    align-items: center;
    border: 0;
    pointer-events: initial;
`;

const Card = styled.div`
    width: 140px;
    height: 80px;
    display: inline-block;
    margin-right: 12px;
    padding: 8px 8px 12px 8px;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid transparent;
    box-shadow: 0 3px 6px #33333326;
    background: ${Theme.colors.neutrals.white};
    cursor: pointer;
    vertical-align: top;

    transition: all 0.3s ease-out;

    ${(props) =>
        props.successful === true &&
        `
    background: ${Theme.colors.success.bg};
    border-color: ${Theme.colors.success.shadow};

    &.changed {
        border-color: ${Theme.colors.success.text};
        box-shadow: 0 2px 6px ${Theme.colors.success.shadow};
    }
    `}

    ${(props) =>
        props.successful === undefined &&
        `
    background:  #fcebcf;
    border-color:  #F5C26B;
    color:black;

    &.changed {
        border-color:  #F5C26B;
        box-shadow: 0 2px 6px  #F5C26B;
    }


    path {
        fill:  #F5C26B !important;
    }
    `}

    ${(props) =>
        props.successful === false &&
        `
    background: ${Theme.colors.error.bg};
    border-color: ${Theme.colors.error.shadow};

    &.changed {
        border-color: ${Theme.colors.error.text};
        box-shadow: 0 2px 6px ${Theme.colors.error.shadow};
    }

    path {
        fill: ${Theme.colors.error.text} !important;
    }
    `}

    ${(props) =>
        props.selected
            ? `
    border-color: ${Theme.colors.secondary};
    box-shadow: 0 0 6px ${Theme.colors.action};
    `
            : ""}
`;

const ShiftTypeColor = styled.div`
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    margin-right: 4px;
    text-align: center;
    line-height: 24px;

    ${(props) =>
        props.smaller &&
        `
    position: absolute;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-width: 1px;
    `}
`;

const ShiftTypeName = styled.div`
    display: inline-block;
    vertical-align: middle;
    font-size: ${Theme.font.small.size};
    line-height: ${Theme.font.small.lineHeight};
    color: ${Theme.colors.neutrals.medium};
    max-width: 108px;
    span {
        display: block;
        font-size: ${Theme.font.extraSmall.size};
        line-height: ${Theme.font.extraSmall.lineHeight};
        font-family: ${Theme.font.main};
    }
`;

const ShiftTypeTime = styled.div`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    color: ${Theme.colors.neutrals.medium};
`;

const ShiftTypeCount = styled.div`
    display: inline-block;
    width: 54px;
    font-size: ${Theme.font.small.size};
    line-height: ${Theme.font.small.lineHeight};
`;
const ShiftTypeCount2 = styled.div`
    display: inline-block;
    width: 117px;
    font-size: ${Theme.font.small.size};
    line-height: ${Theme.font.small.lineHeight};
`;

const Span = styled.span`
    margin-left: -18px;
`;

const ShiftTypeDrawerDivisor = styled.div`
    border-left: 1px dashed ${Theme.colors.neutrals.silver}
    height: 92px;
    margin-top: -4px;
    margin-bottom: -8px;
`;

const StatusCardName = styled.div`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-weight: 500;
`;

const ShiftTypeCard = ({
    name,
    color,
    icon,
    time,
    drivers,
    routes,
    selected,
    isBackup,
    isShift,
    cortexRoute,
    totalRouteCodes,
    totalDrivers,
    onClick,
}) => {
    let successful;
    let isWarningIcon = false;

    successful = "";
    if (name === "Assigned" && totalDrivers === totalRouteCodes) {
        successful = true;
    } else if (name === "Assigned" && totalDrivers < totalRouteCodes) {
        successful = undefined;
    } else if (name === "Assigned") {
        successful = false;
    }

    return (
        <Card successful={successful} selected={selected} onClick={onClick}>
            <ShiftTypeName
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 124,
                    whiteSpace: "nowrap",
                }}
                title={name}
            >
                {name}
            </ShiftTypeName>
            <Spacer left={0}>
                <ShiftTypeTime>
                    <Spacer top={3} />
                </ShiftTypeTime>
                <Spacer top={1} />
                <ShiftTypeCount>{drivers}D</ShiftTypeCount>
                <Spacer right={0} inline />
                <>
                    {name === "Assigned" && <Span>/</Span>}
                    <Spacer right={2} inline />
                    {name === "Assigned" && (
                        <ShiftTypeCount>{totalRouteCodes > 0 ? totalRouteCodes : 0}R </ShiftTypeCount>
                    )}
                    <Spacer right={2} inline />
                </>
            </Spacer>
        </Card>
    );
};

const StatusCard = ({ icon, name, drivers, routes, successful, onClick, id, selected }) => {
    let isSuccessful = successful !== undefined ? successful : drivers > 0 ? false : null;

    let style = {
        color: isSuccessful === false ? Theme.colors.error.text : Theme.colors.info.text,
    };

    return (
        <Card successful={isSuccessful} onClick={onClick} id={id} selected={selected}>
            <Spacer top={1} />
            {icon}
            <StatusCardName style={style}>{name}</StatusCardName>
            <Spacer top={1} />
            {drivers && <ShiftTypeCount>{drivers}D</ShiftTypeCount>}
            {routes && <ShiftTypeCount>{routes}R</ShiftTypeCount>}
        </Card>
    );
};

const CommitmentCard = ({
    name,
    commitRouteCode,
    totalRouteCode,
    routeAmedmentSum,
    drivers,
    onClick,
    dropRoute,
    pickupRoute,
}) => {
    let style = {};
    let isSuccessful = null;
    style = {
        color: "rgb(81, 111, 144)",
    };

    /*if( commitRouteCode > 0 || totalRouteCode > 0  || drivers > 0 ) {
        if(commitRouteCode == totalRouteCode &&  commitRouteCode === drivers){
            isSuccessful=true;
            style = {
                color:  Theme.colors.info.text
            };
        } else if( totalRouteCode > drivers ){
            isSuccessful=false;
            style = {
                color: Theme.colors.info.text
            };
        } else {
            isSuccessful=undefined;
            style = {
                color: '#707070'
            };
        }
    }*/

    if (!(totalRouteCode == 0 && commitRouteCode + dropRoute + pickupRoute == 0)) {
        if (totalRouteCode == commitRouteCode + dropRoute + pickupRoute) {
            style = {
                color: Theme.colors.info.text,
            };
            isSuccessful = true;
        } else if (commitRouteCode + dropRoute + pickupRoute > totalRouteCode) {
            isSuccessful = false;
            style = {
                color: Theme.colors.info.text,
            };
        } else if (commitRouteCode + dropRoute + pickupRoute < totalRouteCode) {
            isSuccessful = undefined;
            style = {
                color: "#707070",
            };
        }
    }

    return (
        <Card successful={isSuccessful} onClick={onClick}>
            <Spacer top={1} />
            <StatusCardName style={style}>{name}</StatusCardName>
            <Spacer top={1} />
            <ShiftTypeCount2>
                {totalRouteCode}R <Spacer right={2} inline />/ {commitRouteCode}RC ({dropRoute}) ({pickupRoute})
            </ShiftTypeCount2>
        </Card>
    );
};

const OpenRoutes = ({ routes }) => {
    const [state, dispatch] = useContext(LoadOutStore);
    const [selectedDropRoutes, setSelectedDropRoutes] = useState([]);
    const { api } = useAuth();
    const [visible, setVisible] = useModal();
    const { dropLoadoutShift, ...schedulerApi } = useSchedulerApi();
    const hide = () => setVisible(false);
    const show = () => {
        setVisible(true);
    };
    const checkBoxAllRef = useRef()
    const routeCheckBoxRef = useRef([])

    const driversRouteCode = [].concat.apply([], state.drivers.map((d) => d.routes));

    const assignRouteToDriver = async (route, driver) => {
        await API.assignRoute(api, route, driver);

        let waveData = await API.fetchWaveData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        );
        dispatch({
            type: "SET_WAVE_DATA",
            payload: waveData,
        });
        dispatch({
            type: "ADD_CHANGE",
            payload: "assign_route_to_driver",
        });
    };

    const dropOpenRoutes = async (routeId, k) => {
        const result = await API.dropOpenRoutes(api, [routeId])
        if (result) {
            dispatch({
                type: "DROP_OPEN_ROUTE",
                payload: { key: k },
            });
            checkBoxAllRef.current.checked = false;
            state.waveData.openRoutes.forEach((item, i) => {
                routeCheckBoxRef.current[i].checked = false
            })
            handleDropCheckBox({
                currentTarget: { value: routeId }
            })
            setSelectedDropRoutes([])
        }
    };

    const dropMultipleRoutes = async () => {
        const result = await API.dropOpenRoutes(api, selectedDropRoutes)
        if (result) {
            dispatch({
                type: "DROP_MULTIPLE_OPEN_ROUTE",
                payload: selectedDropRoutes
            });
            setSelectedDropRoutes([])
            checkBoxAllRef.current.checked = false;
            state.waveData.openRoutes.forEach((item, i) => {
                routeCheckBoxRef.current[i].checked = false
            })
        }
    }

    const handleRemoveRequest = async (routeId, k) => {
        let result = await API.removeRequest(api, routeId);
        dispatch({
            type: "DROP_OPEN_ROUTE",
            payload: { key: k },
        });
        if (result.length > 0) {
            state.drivers.push(result[0]);
            dispatch({
                type: "ADD_CHANGE",
            });
        }
        checkBoxAllRef.current.checked = false;
        state.waveData.openRoutes.forEach((item, i) => {
            routeCheckBoxRef.current[i].checked = false
        })
    };

    const handleDropCheckBox = (e) => {
        const tempSelectedDropRoutes = [...selectedDropRoutes]
        if (e.currentTarget.checked) {
            tempSelectedDropRoutes.push(parseInt(e.currentTarget.value));
            if (state.waveData.openRoutes.length === tempSelectedDropRoutes.length) {
                if (checkBoxAllRef?.current?.type === 'checkbox') {
                    checkBoxAllRef.current.checked = true
                }
            }
        } else {
            const index = tempSelectedDropRoutes.indexOf(parseInt(e.currentTarget.value))
            tempSelectedDropRoutes.splice(index, 1);
            if ((state.waveData.openRoutes.length !== tempSelectedDropRoutes.length) ||
                (tempSelectedDropRoutes.length === 0)) {
                checkBoxAllRef.current.checked = false;
            }
        }
        setSelectedDropRoutes(tempSelectedDropRoutes)
    }

    const handleDropAllCheckBox = (e) => {
        let tempSelectedDropRoutes = [...selectedDropRoutes]
        state.waveData.openRoutes.forEach((item, i) => {
            if (e.currentTarget.checked) {
                if (!tempSelectedDropRoutes.includes(item.id)) {
                    tempSelectedDropRoutes.push(parseInt(item.id));
                }
                routeCheckBoxRef.current[i].checked = true
            } else {
                tempSelectedDropRoutes = [];
                routeCheckBoxRef.current[i].checked = false
            }
        })
        setSelectedDropRoutes(tempSelectedDropRoutes)
    }

    return (
        <>
            <StatusCard
                icon={<Icon.Warning size="20px" color={Theme.colors.info.border} />}
                name="Unassigned Routes"
                routes={routes.length}
                successful={routes.length === 0 ? null : false}
                onClick={show}
            />
            <Modal title="Unassigned Routes" visible={visible} setVisible={setVisible} width={"500px"}>
                <Table>
                    <TableHeader
                        headers={[
                            {
                                label: <div style={{ paddingTop: "6px" }}>
                                    <CheckBox
                                        name={"check_all"}
                                        title={""}
                                        options={[{ value: "", label: "" }]}
                                        onClick={handleDropAllCheckBox}
                                        ref={checkBoxAllRef}
                                    />
                                </div>, width: "20px"
                            },
                            { label: "Route", width: "80px" },
                            { label: "Shift Type", width: "140px" },
                            {
                                label: selectedDropRoutes.length ? <Button
                                    size="small"
                                    type="delete"
                                    onClick={async () => {
                                        let confirmation = await confirm({
                                            icon: true,
                                            title: "Unassigned Selected Routes",
                                            text: "Are you sure?",
                                        });
                                        if (confirmation) {
                                            dropMultipleRoutes()
                                        }
                                    }}
                                >
                                    {`Drop (${selectedDropRoutes.length})`}
                                </Button> : '', width: "auto", style: { marginLeft: "auto" }
                            },
                        ]}
                    />
                    {!state.waveData.openRoutes.length && <Empty />}
                    {state.waveData.openRoutes.map((r, k) => (
                        <li key={k} style={{ display: "flex" }}>
                            <TableData width="20px">
                                <CheckBox
                                    name={r.code}
                                    title={""}
                                    options={[{ value: r.id, label: "" }]}
                                    onClick={handleDropCheckBox}
                                    ref={el => routeCheckBoxRef.current[k] = el}
                                />
                            </TableData>
                            <TableData width="80px" style={{ "display": "block" }}>{r.code} <br />{driversRouteCode.includes(r.code) ? <div style={{ color: "red", fontSize: '10px' }}>{' Duplicate!'}</div> : ''}</TableData>
                            <TableData width="198px">
                                <DriverShiftType shiftType={r.shiftType} />
                            </TableData>
                            <TableData width="auto" style={{ textAlign: "right", marginLeft: "auto" }}>
                                {localStorage.getItem('role') != 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE' && <Button
                                    size="small"
                                    onClick={() => {
                                        hide();
                                        showDriverSelection(
                                            {
                                                children: (
                                                    <>
                                                        <Label>Shift Type</Label>
                                                        <DriverShiftType shiftType={r.shiftType} />
                                                        <Spacer bottom={3} />
                                                        <Label>Route</Label>
                                                        <Text>{r.code}</Text>
                                                    </>
                                                ),
                                            },
                                            api,
                                            {
                                                type: "OPEN_ROUTE_ASIGN",
                                                station: state.waveData.station,
                                                date: state.selectedDate,
                                                route: r.id,
                                            }
                                        ).then(
                                            async (driver) => {
                                                if (driver && r.code) {
                                                    let response = await API.checkRouteCodeForDriverAlias(api, {
                                                        driver: driver.id,
                                                        driverRoute: r.id
                                                    });

                                                    if (response.message && response.message != '') {
                                                        let confirmation = await confirm({
                                                            icon: true,
                                                            title: "Please confirm",
                                                            text: response.message,
                                                            cancelBtn: 'No',
                                                            confirmBtn: 'Yes'
                                                        });
                                                        if (confirmation) {
                                                            await API.updateTidAndDriverAlias(api, {
                                                                company: getCompanyId(),
                                                                driver: driver.id,
                                                                driverAlias: response.driverAlias,
                                                                transporterID: response.transporterID
                                                            });
                                                        }
                                                    }
                                                }
                                                if (driver) {
                                                    assignRouteToDriver(r.id, driver.id)
                                                }
                                            },
                                            (reject) => console.log(reject)
                                        );
                                    }}
                                >
                                    Assign
                                </Button>}
                                <Spacer right={3} inline />
                                <Button
                                    size="small"
                                    type="delete"
                                    onClick={async () => {
                                        let confirmation = await confirm({
                                            icon: true,
                                            title: "Unassigned Routes",
                                            text: "Are you sure?",
                                        });
                                        if (confirmation) {
                                            dropOpenRoutes(r.id, k);
                                        }
                                    }}
                                >
                                    Drop
                                </Button>
                                {r.requestApprovedBy && r.isRequested === true && r.isRequestCompleted === false && (
                                    <Spacer left={3} inline>
                                        <Button
                                            size="small"
                                            type="delete"
                                            onClick={() => {
                                                handleRemoveRequest(r.id, k);
                                            }}
                                        >
                                            Disapprove
                                        </Button>
                                    </Spacer>
                                )}
                            </TableData>
                        </li>
                    ))}
                </Table>
            </Modal>
        </>
    );
};

const ShiftTypeDrawer = () => {
    const [state, dispatch] = useContext(LoadOutStore);
    const { api } = useAuth();
    const { dropLoadoutShift, ...schedulerApi } = useSchedulerApi();

    const setQuickShiftType = (name) => {
        if (name === "Assigned") {
            dispatch({
                type: "ASSIGNED_FILTER",
                payload: state.quickShiftType == name ? null : name,
            });
        } else {
            dispatch({
                type: "UNASSIGNED_FILTER",
                payload: state.quickShiftType == name ? null : name,
            });
        }
    };
    const setNotPunchInType = () => {
        dispatch({
            type: "FILTER_NOT_PUNCH_IN",
            payload: (state.filterNotPunchIn = state.filterNotPunchIn == false ? true : false),
        });
    };
    const setNotDepartedType = () => {
        dispatch({
            type: "FILTER_NOT_DEPARTED",
            payload: (state.filterNotDeparted = state.filterNotDeparted == true ? false : true),
        });
    };

    let render = [];

    if (state)
        state.waveData.balanceGroups.map((item, k) => {
            let icon = null;

            render.push(
                <ShiftTypeCard
                    key={k}
                    name={item.name}
                    color={item.color}
                    icon={icon}
                    time={item.time}
                    drivers={item.drivers}
                    routes={item.routes}
                    isBackup={item.isBackup}
                    isShift={item.isShift}
                    cortexRoute={item.cortexRoute}
                    selected={item.id == state.quickShiftType}
                    totalRouteCodes={state.routeCommitments.codes}
                    totalDrivers={state.routeCommitments.driversWithRouteCode}
                    onClick={() => {
                        setQuickShiftType(item.name);
                    }}
                />
            );
        });
    let week = moment(state.selectedDate).isoWeek();
    if (moment(state.selectedDate).day() === 0) {
        week = moment(state.selectedDate).add(1, "days").isoWeek();
    }
    let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
    if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
        year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
    }

    const fakeRefresh = useState(0);
    const [publishUpdate] = fakeRefresh;

    useEffect(() => {
        if (publishUpdate < 1) return;

        API.fetchWaveData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        ).then((waveData) => {
            dispatch({
                type: "SET_WAVE_DATA",
                payload: waveData,
            });
            let week = moment(state.selectedDate).isoWeek();
            if (moment(state.selectedDate).day() === 0) {
                week = moment(state.selectedDate).add(1, "days").isoWeek();
            }

            let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
            if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
                year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
            }
            //handleUpdateChanges(state.waveData.station);
            API.fetchPendingChanges(
                {
                    station: state.waveData.station,
                    wknumber: week,
                    unpublishDataDate: state.selectedDate,
                    year: year,
                    loadOut: true,
                    type: "loadOut",
                },
                api
            ).then((UnpublishResponse) => {
                let chnagesId = [];
                UnpublishResponse.forEach(function (change) {
                    chnagesId.push(change.driverRouteId);
                });

                dispatch({
                    type: "SET_PUBLISH_COUNT",
                    ids: chnagesId,
                    station: state.waveData.station,
                    api: true,
                });
            });
        });
    }, [publishUpdate]);
    return (
        <Fixed style={{ top: "80px" }} id="shift-types-drawer-wrapper">
            <ShiftTypesDrawerWrapper>
                <Spacer top={3} bottom={3} left={5} style={{ overflow: "auto" }}>
                    {!render.length && <Empty />}
                    <div style={{ width: render.length * 152 }}>{render}</div>
                </Spacer>
                <Spacer top={3} right={5} bottom={3} left={3}>
                    <ShiftTypesDrawerWrapper>
                        <ShiftTypeDrawerDivisor />
                        <Spacer right={5} inline />
                        <OpenRoutes routes={state.waveData.openRoutes} />
                        <StatusCard
                            icon={<Icon.GeoferenceIn size="20px" color={Theme.colors.info.border} />}
                            name="Not Punched In"
                            drivers={state && state.waveData.notPunchedIn}
                            selected={!state.filterNotPunchIn}
                            onClick={() => {
                                setNotPunchInType();
                            }}
                            id={"counter-not-punched-in"}
                        />
                        <StatusCard
                            icon={<Icon.Clock size="20px" color={Theme.colors.info.border} />}
                            name="Not Departed"
                            drivers={state && state.waveData.notDeparted}
                            selected={state.filterNotDeparted}
                            onClick={() => {
                                setNotDepartedType();
                            }}
                            id={"counter-not-departed"}
                        />

                        <CommitmentCard
                            name="Routes vs. Route Commitment"
                            status={"undefined"}
                            commitRouteCode={state.routeCommitments.commitments}
                            totalRouteCode={state.routeCommitments.codes}
                            routeAmedmentSum={state.routeCommitments.amendment}
                            dropRoute={state.routeCommitments.dropRoute}
                            pickupRoute={state.routeCommitments.pickupRoute}
                            drivers={state.routeCommitments.drivers}
                            id={"routes-vs-route-commitment"}
                            onClick={async () => {
                                if (
                                    state.routeCommitments.dropRoute +
                                    state.routeCommitments.pickupRoute +
                                    state.routeCommitments.commitments !=
                                    state.routeCommitments.codes
                                ) {
                                    let incidentTypesRes = await incidentsApi.getDroprouteIncidentType();
                                    let dropRouteTypeId = null;
                                    let token = "";
                                    if (
                                        state.routeCommitments.codes >
                                        state.routeCommitments.dropRoute +
                                        state.routeCommitments.pickupRoute +
                                        state.routeCommitments.commitments
                                    ) {
                                        token = "Pic";
                                    } else if (
                                        state.routeCommitments.codes <
                                        state.routeCommitments.dropRoute +
                                        state.routeCommitments.pickupRoute +
                                        state.routeCommitments.commitments
                                    ) {
                                        token = "Dro";
                                    }
                                    incidentTypesRes.data.data.incidentTypes.map((type) => {
                                        if (type.token == token) {
                                            dropRouteTypeId = type.id;
                                        }
                                    });
                                    if (dropRouteTypeId) {
                                        openReportIncidentForm({
                                            station: state.waveData.station,
                                            driver: null,
                                            incidentType: dropRouteTypeId,
                                            driverRoute: "",
                                            date: moment(state.selectedDate).format("YYYY-MM-DD"),
                                            time: moment.utc().format("HH:mm"),
                                        }).then(
                                            ({ data: incident, action }) => {
                                                if (!incident) return false;
                                                const hide = loadingDialog();
                                                const res = incidentsApi
                                                    .startIncident(incident)
                                                    .then(async (response) => {
                                                        if (response.data.data.result.incidentType.token == "Dro") {
                                                            // if droproute then delete
                                                            if (
                                                                response.data.data.result.driverRoute &&
                                                                response.data.data.result.driverRoute.id
                                                            ) {
                                                                let a = await incidentsApi.incidentDroprouteAutofill(
                                                                    response.data.data.result.id
                                                                );
                                                                await dropLoadoutShift({
                                                                    driverId: response.data.data.result.driverRoute.id,
                                                                    incidentId: response.data.data.result.id,
                                                                });
                                                                let driver = state.drivers.find(
                                                                    (d) =>
                                                                        d.id == response.data.data.result.driverRoute.id
                                                                );

                                                                dispatch({
                                                                    type: "REMOVE_DRIVER_ROUTE",
                                                                    payload: driver,
                                                                });
                                                            }
                                                        } else if (
                                                            response.data.data.result.incidentType.token == "Pic"
                                                        ) {
                                                            if (
                                                                response.data.data.result.driverRoute &&
                                                                response.data.data.result.driverRoute.id
                                                            ) {
                                                                let a = await incidentsApi.incidentPickuprouteAutofill(
                                                                    response.data.data.result.id
                                                                );
                                                            }
                                                        }

                                                        if (response && action === "next") {
                                                            await incidentPromise(response.data.data.result.id);
                                                            let waveData = await API.fetchWaveData(
                                                                {
                                                                    station: state.waveData.station,
                                                                    date: state.selectedDate,
                                                                },
                                                                api
                                                            );
                                                            dispatch({
                                                                type: "SET_WAVE_DATA",
                                                                payload: waveData,
                                                            });
                                                        } else {
                                                            toast({
                                                                type: "Success",
                                                                title: "Incident successfully reported",
                                                                content: (
                                                                    <Link
                                                                        style={{ color: "inherit" }}
                                                                        to={`/incidents/${response.data.data.result.id}`}
                                                                    >
                                                                        View incident
                                                                    </Link>
                                                                ),
                                                                timeout: 8000,
                                                            });
                                                            dispatch({
                                                                type: "REFRESH",
                                                            });
                                                        }
                                                    })
                                                    .then(hide);
                                                return res;
                                            },
                                            (error) => {
                                                if (error.response)
                                                    toast({
                                                        type: "error",
                                                        title: "Error",
                                                        content: error.response.data.message,
                                                    });
                                            }
                                        );
                                    }
                                }
                            }}
                        />
                        <Spacer right={2} inline />
                        <ShiftTypeDrawerDivisor />
                        <Spacer right={5} inline />
                        <PublishPopUp
                            date={state.selectedDate}
                            week={week}
                            year={year}
                            view={"loadOut"}
                            fakeRefresh={fakeRefresh}
                        />
                    </ShiftTypesDrawerWrapper>
                </Spacer>
            </ShiftTypesDrawerWrapper>
        </Fixed>
    );
};

export const highlight = (id) => {
    setTimeout(() => {
        let row = document.getElementById(id);
        row.classList.add("changed");
        setTimeout(() => row.classList.remove("changed"), 3000);
    }, 50);
};

// Drivers List
export const highlightDriver = (id) => {
    highlight(`driver-row-${id}`);
};

const blink = keyframes`
    0% {
        background-color: ${Theme.colors.info.bg};
        box-shadow: 0 0 5px ${Theme.colors.info.shadow};
    }

    35% {
        background-color: ${Theme.colors.info.bg};
        box-shadow: 0 0 5px ${Theme.colors.info.shadow};
    }

    100% {
        background-color: ${Theme.colors.neutrals.white};
        box-shadow: 0 0 0 transparent;
    }
`;

const StatusData = styled.div`
    &:before {
        content: "";
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-right: 8px;
        border-radius: 50%;
        position: relative;
        top: 1px;
    }

    span {
        display: block;
        padding-left: 20px;
        color: ${Theme.colors.neutrals.medium};
        font-size: ${Theme.font.extraSmall.size};
        line-height: ${Theme.font.extraSmall.lineHeight};
        font-family: ${Theme.font.main};
    }

    ${(props) =>
        props.notPunchedIn
            ? `
        color: ${Theme.colors.error.text};

        &:before {
            background-color: ${Theme.colors.error.text};
        }
    `
            : false}

    ${(props) =>
        props.breakPunchOut
            ? `
        color: ${Theme.colors.success.text};

        &:before {
            background-color: ${Theme.colors.success.text};
        }
    `
            : false}

    ${(props) =>
        props.onBreak
            ? `
            color: ${Theme.colors.secondary}

            &:before {
                    background-color: ${Theme.colors.secondary};
            }
        `
            : false}

    ${(props) =>
        props.punchedIn
            ? `
        color: ${Theme.colors.success.text};

        &:before {
            background-color: ${Theme.colors.success.text};
        }
    `
            : false}

    ${(props) =>
        props.readyToStage
            ? `
        color: ${Theme.colors.warnings.text};

        &:before {
            background-color: ${Theme.colors.warnings.text};
        }
    `
            : false}

    ${(props) =>
        props.insideStation
            ? `
        color: ${Theme.colors.primary};

        &:before {
            background-color: ${Theme.colors.primary};
        }
    `
            : false}

    ${(props) =>
        props.onroute
            ? `
        color: ${Theme.colors.secondary};

        &:before {
            background-color: ${Theme.colors.secondary};
        }
    `
            : false}

    ${(props) =>
        props.returned
            ? `
        color: ${Theme.colors.neutrals.medium};

        &:before {
            background-color: ${Theme.colors.neutrals.medium};
        }
    `
            : false}

    ${(props) =>
        props.clockedOut
            ? `
        color: ${Theme.colors.neutrals.silver};

        &:before {
            background-color: ${Theme.colors.neutrals.silver};
        }
    `
            : false}

    ${(props) =>
        props.ncns || props.callin || props.late || props.accidentinjury
            ? `
        color: ${Theme.colors.info.text};

        &:before {
            background-color: ${Theme.colors.info.text};
        }
    `
            : false}
`;

const getCountdownText = (str) => {
    let until = moment(str, "h:mma");
    let diff = until.diff(moment(), "minutes");

    if (diff == 0) return "Leave now";

    return diff < 0 ? `Late ${-diff} min` : `In ${diff} min`;
};

const Countdown = ({ until }) => {
    const [text, setText] = useState(getCountdownText(until));

    useEffect(() => {
        let interval = setInterval(() => {
            setText(getCountdownText(until));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return text;
};

const Link = styled(UILink)`
    display: inline-table;

    > * {
        display: table-cell;
        vertical-align: middle;
    }

    svg {
        vertical-align: sub;
        padding-left: 0;
        position: absolute;
        right: 0;
        top: 8%;
    }

    .editIcon {
        top: unset;
        padding-top: 4px;
    }

    svg path {
        fill: ${Theme.colors.info.border};
    }
`;

const AddOrEdit = ({
    pageLoadOut = true,
    collection,
    children,
    enabled = true,
    onOpen = () => { },
    onHide = () => { },
    onView = () => { },

    emptyRender = ({ show, hide, enabled }) => (
        <Action onClick={show} disabled={!enabled} compact={true} tabIndex={1}>
            <Icon.Plus size="20px" style={{ height: "100%" }} />
        </Action>
    ),
    collectionRender = (collection, { show, hide, enabled }, pageLoadOut) =>
        !enabled ? (
            <span>{collection?.join(", ")}</span>
        ) : (
            <Link
                tabIndex={1}
                href="#"
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    onClick={(e) => {
                        e.preventDefault();
                        onView();
                    }}
                    style={{ maxWidth: '80%' }}
                >
                    {collection?.join(", ")}
                </span>
                <Icon.Edit
                    style={{
                        position: 'relative'
                    }}
                    size="20px"
                    onClick={(e) => {
                        e.preventDefault();
                        show();
                    }}
                />
            </Link>
        ),

    mode = "modal",
    modalProps = {},

    breakLine = false,

    /**
     * @deprecated use modalProps.title instead
     */
    title = "",

    /**
     * @deprecated use modalProps.width instead
     */
    width = "500px",
    paddingRight = 10
}) => {
    const [visible, setVisible] = useModal(false);

    let show = () => {
        if (onOpen && typeof onOpen === "function") onOpen();

        setVisible(true);
    };
    let hide = () => {
        if (onHide && typeof onHide === "function") onHide();

        setVisible(false);
    };

    useEffect(() => {
        return () => {
            show = false;
            hide = false;
        };
    }, []);

    modalProps = {
        title: title,
        width: width,
        ...modalProps,
    };

    return (
        <div
            style={{
                whiteSpace: breakLine ? "normal" : "normal",
                // position: 'relative',
                zIndex: +visible,
                paddingRight: +paddingRight,
            }}
        >
            {!visible ? (
                !collection?.length ? (
                    emptyRender({ show, hide, enabled })
                ) : (
                    collectionRender(collection, { show, hide, enabled }, pageLoadOut)
                )
            ) : mode === "modal" ? (
                <Modal {...modalProps} visible={visible} setVisible={hide}>
                    {children({ show, hide, visible })}
                </Modal>
            ) : mode === "tooltip" ? (
                "tooltip"
            ) : (
                children({ show, hide, visible })
            )}
        </div>
    );
};

export { AddOrEdit };

export const DriverRouteController = ({
    driver,
    addChange,
    onOpen = () => { },
    onHide = () => { },
    pageLoadOut = true,
}) => {
    const { register, getValues } = useForm({
        mode: "onBlur",
    });

    const { api } = useAuth();
    const { getTodayRouteCodes, getRouteCodes, removetRouteCode, saveShiftRouteCodes, reassignRouteCodes } =
        useSchedulerApi();
    const [todayRouteCodes, setTodayRouteCodes] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const getCodes = () => {
        let temp = driver?.routeCode?.map(route => { return route.replace(" Duplicate!", "") });
        return getTodayRouteCodes({
            station: driver.stationId,
            dateCreated: driver.shiftDate,
        }).then(
            (response) => {
                if (!response) return false;

                let codes = response.data.data.codes
                    .map((c) => ({
                        name: c.code,
                        value: c.code,
                        selected: temp.includes(c.code),
                    }))
                    .sort((a, b) => {
                        if (a) return a.name.localeCompare(b.name, undefined, { numeric: true });
                    });
                setTodayRouteCodes(codes);
                return codes;
            },
            (error) => {
                if (error.response)
                    toast({
                        type: "error",
                        title: "Error",
                        content: error.response.data.message,
                    });
            }
        );
    };

    const handleSave = async (hide) => {
        let oldCodes = [...driver.routeCode];
        let tempRouteCodes = getValues("routeCodes");

        if (tempRouteCodes.length == 1 && driver.isOpenShift == false) {
            setIsLoader(true);
            let oldDriverCodes = await getRouteCodes({
                station: driver.stationId,
                date: driver.shiftDate,
                week: moment(driver.shiftDate).utc().isoWeek(),
                code: tempRouteCodes[0],
            });

            setIsLoader(false);
            let isOpenRouteCode = false;
            let isDriverRouteCode = false;

            let openRouteCode = [];
            let driverRouteCode = [];

            await tempRouteCodes.forEach((item) => {
                oldDriverCodes.data.data.routeCodes.open.forEach((openC) => {
                    if (openC.code.indexOf(item) > -1) {
                        isOpenRouteCode = true;
                        openRouteCode = openC;
                    }
                });
                oldDriverCodes.data.data.routeCodes.driver.forEach((driverC) => {
                    if (driverC.code.indexOf(item) > -1) {
                        isDriverRouteCode = true;
                        driverRouteCode = driverC;
                    }
                });
            });

            if (isDriverRouteCode === true && isOpenRouteCode === true) {
                let confirmation = await confirm({
                    icon: true,
                    title: "Route code",
                    text:
                        "This route code is both located in Open Routes and is assigned to " +
                        driverRouteCode.name +
                        ".  Would you like to assign a third driver?",
                });
                if (!confirmation) {
                    let buttons = [
                        {
                            text: "Close",
                            type: "cancel",
                            callback: () => false,
                        },
                    ];
                    await dialog({
                        icon: <Icon.Info size="60px" color={Theme.colors.info.text} />,
                        text: "Please locate one of the existing Routes and reassign.",
                        buttons: buttons,
                    });
                    return;
                } else {
                    return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                }
            } else if (isOpenRouteCode === true) {
                if (driver.id != openRouteCode.id) {
                    let confirmation = await confirm({
                        icon: true,
                        title: "Route code",
                        text: `This Route Code already exists under the Open Routes Badge.  Would you like to assign this open Route Code to this Delivery Associate?`,
                    });
                    if (confirmation) {
                        await removetRouteCode({
                            route: openRouteCode.id,
                            code: openRouteCode.codeId,
                        }).then((response) => {
                            let oldDriver = {};
                            if (driver.viewType == "loadOut") {
                                oldDriver.routes = [];
                            } else {
                                addChange({
                                    ...oldDriver,
                                    routeId: openRouteCode.id,
                                    olsShiftIsOpen: true,
                                    driverId: null,
                                    date: openRouteCode.date,
                                    startTime: openRouteCode.startTime,
                                    codeCount: openRouteCode.codeCount,
                                    code: tempRouteCodes[0],
                                });
                            }
                        });
                        return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                    } else {
                        let confirmationOpenCode = await confirm({
                            icon: true,
                            title: "Route code",
                            text: `Are you sure you want to duplicate this Route Code assignment?`,
                        });
                        if (!confirmationOpenCode) {
                            return;
                        } else {
                            return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                        }
                    }
                } else {
                    return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                }
            } else if (isDriverRouteCode === true) {
                if (driver.id != driverRouteCode.id) {
                    let buttons = [
                        {
                            text: "Cancel",
                            type: "cancel",
                            callback: () => false,
                        },
                        {
                            text: "Duplicate",
                            inline: false,
                            style: {
                                width: "100%",
                                marginTop: 8,
                            },
                            callback: async () => {
                                return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                            },
                        },
                    ];
                    buttons.push({
                        text: " Reassign",
                        inline: false,
                        style: {
                            width: "100%",
                            marginTop: 8,
                        },
                        callback: async () => {
                            let oldDriver = {};
                            await removetRouteCode({
                                route: driverRouteCode.id,
                                code: driverRouteCode.codeId,
                            });

                            if (driver.hasBag == true || driver.hasBag == false) {
                                return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                            } else {
                                addChange({
                                    ...oldDriver,
                                    routeId: driverRouteCode.id,
                                    hasRouteCodeChnage: true,
                                    driverId: driverRouteCode.driverId,
                                    date: driverRouteCode.date,
                                    startTime: driverRouteCode.startTime,
                                    codeCount: openRouteCode.codeCount,
                                    code: tempRouteCodes[0],
                                });
                                return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                            }
                        },
                    });
                    await dialog({
                        icon: <Icon.Warning size="60px" color={Theme.colors.warnings.text} />,
                        title: "Driver Route Code",
                        text:
                            "This Route Code is already assigned to " +
                            driverRouteCode.name +
                            ". Would you like to reassign or duplicate the Route Code?",
                        buttons: buttons,
                    });
                } else {
                    return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
                }
            } else {
                return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
            }
        } else {
            return saveRouteCode(driver, oldCodes, hide, tempRouteCodes);
        }
    };

    const saveRouteCode = async (driver, oldCodes, hide, tempRouteCodes) => {
        setIsLoader(true);

        driver.routeCode = tempRouteCodes;
        return saveShiftRouteCodes({
            shift: {
                stationId: driver.stationId,
                routeId: driver.id,
                date: driver.shiftDate,
            },
            codes: tempRouteCodes,
        })
            .then(
                (response) => {
                    addChange({
                        ...driver,
                        routeId: driver.id,
                    });
                    setIsLoader(false);
                },
                (error) => {
                    driver.routeCode = oldCodes;
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            )
            .finally(hide);
    };
    const handleReassign = (hide) => {
        // hide();

        showDriverSelection(
            {
                children: (
                    <>
                        {/*<Label>Reassign Routes</Label>
                        <Text>{driver.routeCode.join(', ')}</Text>*/}
                    </>
                ),
            },
            api,
            {
                type: "REPLACE_DRIVER",
                station: driver.stationId,
                date: driver.date,
                routeId: driver.id,
                route: driver.id,
                shiftDate: moment(driver.date).format("YYYY-MM-DD"),
                driverId: driver.driverId,
                routeCode: driver.routeCode,
            }
        ).then((newDriver) => {
            if (!newDriver) return;

            reassignRouteCodes({
                routeId: driver.id,
                newDriver: newDriver.driver.id,
                routeCodes: newDriver.routeCodes,
                timezoneOffset: new Date().getTimezoneOffset()
            }).then((response) => {
                let oldRoutes = driver.routeCode.filter(function (val) {
                    return newDriver.routeCodes.indexOf(val) == -1;
                });
                addChange(
                    {
                        ...driver,
                        routeId: driver.id,
                        routeCode: oldRoutes,
                    },
                    response.data.data.reassignRouteCodes
                );
            });
        });
    };

    const inputRef = useRef();

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
    }

    const onClickHandler = (e, hide) => {
        if (typeof inputValue === 'string' && inputValue.trim().length > 0) {
            setInputValue("")
        } else if (typeof inputValue === 'string' && inputValue.trim().length === 0 && e.keyCode === 13) {
            setTodayRouteCodes([])
            handleSave(hide)
        }
    }

    return (
        <AddOrEdit
            width="340px"
            title={driver.name ? `${driver.name}'s Routes` : `Routes`}
            collection={driver.routeCode}
            enabled={true}
            onOpen={async () => {
                onOpen();
                getCodes();
                setTimeout(() => inputRef.current && inputRef.current.parentNode.querySelector("input").focus(), 10);
            }}
            onHide={() => {
                onHide();
            }}
            paddingRight="50"
            collectionRender={(collection, { show, hide, enabled }) => (
                <>
                    {!enabled ? (
                        <span>
                            {collection.map((c, index) => {
                                if (c.includes(' Duplicate!')) {
                                    return <><div >{c.replace(" Duplicate!", "")}</div><div style={{ color: "red", fontSize: '10px' }}>{' Duplicate!'}{index < (collection.length - 1) && ','}</div></>
                                } else {
                                    return <div >{c}{index < (collection.length - 1) && ','}</div>
                                }
                            })}
                        </span>
                    ) : (<>
                        <Link
                            tabIndex={1}
                            onClick={(e) => {
                                e.preventDefault();
                                show();
                            }}
                            href="#"
                        >
                            <span >
                                {collection.map((c, index) => {
                                    if (c.includes(' Duplicate!')) {
                                        return <><div >{c.replace(" Duplicate!", "")}</div><div style={{ color: "red", fontSize: '10px' }}>{' Duplicate!'}</div></>
                                    } else {
                                        return <div >{c}</div>
                                    }
                                })}
                            </span>
                            {/* <span style={{color:"red"}}>{collection.join(", ")}</span> */}
                        </Link>
                        <Link
                            tabIndex={1}
                            onClick={(e) => {
                                e.preventDefault();
                                show();
                            }}
                            href="#"
                        >
                            <Icon.Edit
                                style={pageLoadOut ? { top: 5, right: 40 } : { top: -4, left: 65 }}
                                className="editIcon"
                                size="20px"
                            />
                        </Link>
                    </>
                    )}
                    {collection?.length && enabled ? (
                        <Tooltip
                            content={"Reassign Route Codes"}
                            verticalPosition={"bottom"}
                            horizontalPosition={"center"}
                        >
                            <Link
                                tabIndex={1}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleReassign();
                                }}
                                href="#"
                            >
                                <Icon.Replace
                                    style={pageLoadOut ? { top: 10, right: 15 } : { top: 0, left: 45 }}
                                    size="20px"
                                />
                            </Link>
                        </Tooltip>
                    ) : null}
                </>
            )}
            mode="inline"
        >
            {({ hide }) => (
                <>
                    {!todayRouteCodes ||
                        (isLoader && (
                            <LoadingWrapper>
                                <Loading style={{ width: 40 }} />
                            </LoadingWrapper>
                        ))}
                    <div onChange={onChangeHandler} onKeyDown={(e) => { onClickHandler(e, hide) }}>
                        <TagInput
                            ref={(elm) => {
                                inputRef.current = elm;
                                register(elm);
                            }}
                            name="routeCodes"
                            // label="Routes"
                            options={todayRouteCodes}
                            canCreate={true}
                            size={"extraSmall"}
                        />
                    </div>
                    <Spacer left={1} inline>
                        <Action type="primary" onClick={() => handleSave(hide)}>
                            <Icon.Check color={"white"} size={"20px"} />
                        </Action>
                        <Action type="cancel" onClick={hide}>
                            <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                        </Action>
                    </Spacer>
                </>
            )}
        </AddOrEdit>
    );
};

export const DriverVehicleController = ({ driver, addChange, pageLoadOut }) => {
    const { api } = useAuth();
    const { register, getValues } = useForm({
        mode: "onBlur",
    });
    const [showTimeline, setShowTimeline] = useState(false);
    const [dataTimeline, setDataTimeline] = useState(null);

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const loadOptions = async (driver) => {
        let data = await API.fetchVehiclesData(
            {
                date: driver.shiftDate,
                driver: driver.driverId,
                id: driver.id,
                station: driver.stationId,
                time: undefined,
            },
            api
        );

        let at = false;
        let vt = false;
        const vehicles = data
            .sort((a, b) => a.unit.localeCompare(b.unit, undefined, { numeric: true }))
            .sort((a, b) => {
                let x = a.assignment.indexOf(driver.driverId);
                let y = b.assignment.indexOf(driver.driverId);

                x = x === -1 ? 4 : x;
                y = y === -1 ? 4 : y;

                return x - y;
            })
            .reduce((all, v) => {
                if (v.assignment.indexOf(driver.driverId) !== -1 && all.length === 0) {
                    at = true;
                    all.push({
                        name: "Affinity",
                    });
                }

                if (v.assignment.indexOf(driver.driverId) === -1 && all.length > 0 && !vt && at) {
                    vt = true;
                    all.push({
                        name: "Vehicles",
                    });
                }

                all.push({
                    name: v.unit,
                    value: v.id,
                    selected: driver.vehicle.id === v.id,
                });

                return all;
            }, []);

        setOptions(vehicles);
    };

    const callAddVehicleApi = (vehicleId, routeId, name) => {
        // if (!vehicleId.length) return;
        const paramsVehicleAddJson = {
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "addLoadOutTempDriverRouteData",
                            get: "result",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "shiftType",
                                "schedule",
                                "status",
                                "routeId",
                                "createdBy",
                                "kickoffLog",
                                "vehicleDriverRecord",
                                "oldDriver",
                                "oldDriverRoute",
                            ],
                            criteria: {
                                vehicleId: vehicleId[0],
                                routeId: routeId,
                                name: name,
                                type: "vehicle",
                            },
                        },
                    },
                },
            },
        };
        try {
            const responseVehicleData = api.post("/api/lazy/manage/data", paramsVehicleAddJson);
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    const handleTimeline = useCallback(async (vehicleId) => {
        try {
            setShowTimeline(true);
            let response = await api.post("/api/lazy/get_timeline_events", {
                vehicleId: vehicleId,
                timezone: moment.tz.guess(),
                timezoneOffset: new Date().getTimezoneOffset(),
            });
            setDataTimeline(response.data);
        } catch (e) {
            setShowTimeline(false);
        }
    }, []);

    const inputRef = useRef();

    const handleSave = (hide, isEnter) => {
        addChange({
            description: "vehicle",
            driverId: driver.id,
            callback: () => {
                let id = getValues().vehicle;
                setOptions([]);
                if (id) {
                    let vehicle = options.find((v) => v.value == id);
                    if (vehicle) {
                        driver.vehicle = {
                            id: vehicle.value,
                            unit: vehicle.name,
                        };
                        callAddVehicleApi(id, driver.id, driver.name);
                    } else {
                        driver.vehicle = {};
                        callAddVehicleApi(id, driver.id, driver.name);
                    }
                } else {
                    driver.vehicle = {};
                }
                if (isEnter) {
                    setTimeout(() => { hide() }, 50)
                } else {
                    hide();
                }
            },
        })
    }

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
    }

    const onClickHandler = (e, hide) => {
        if (typeof inputValue === 'string' && inputValue.trim().length > 0) {
            setInputValue("")
        } else if (typeof inputValue === 'string' && inputValue.trim().length === 0 && e.keyCode === 13) {
            handleSave(hide, true)
        }
    }

    return (
        <>
            <AddOrEdit
                pageLoadOut={pageLoadOut}
                width="340px"
                title={`${driver.name}'s Vehicle`}
                collection={driver.vehicle.id ? (driver.vehicle.status && driver.vehicle.status != 'Active') ? [driver.vehicle.unit + '[' + driver.vehicle.status + ']'] : [driver.vehicle.unit] : []}
                enabled={(driver.isRestore != true && driver.shiftType.category != 8) ? true : false}
                onOpen={() => {
                    loadOptions(driver);
                    setTimeout(() => inputRef.current.parentNode.querySelector("input").focus(), 10);
                }}
                collectionRender={(collection, { show, hide, enabled }) => (
                    <>
                        {!enabled ? (
                            <span>{collection.join(", ")}</span>
                        ) : (<div style={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                                tabIndex={1}
                                onClick={(e) => {
                                    e.preventDefault();
                                    show();
                                }}
                                style={{ width: '80%' }}
                                href="#"
                            >
                                <span >
                                    {collection?.map(c => {
                                        if (c.includes('[') && c.includes(']')) {
                                            return <div >{c.match(/(.*)\[/)[1]} <div style={{ color: "red", fontSize: '10px' }}>{c.match(/\[([^)]+)\]/)[1]}</div></div>
                                        } else {
                                            return <div >{c}</div>
                                        }
                                    })}
                                </span>
                                {/* <span style={{color:"red"}}>{collection.join(", ")}</span> */}
                            </Link>
                            <Link
                                tabIndex={1}
                                onClick={(e) => {
                                    e.preventDefault();
                                    show();
                                }}
                                href="#"
                            >
                                <Icon.Edit
                                    style={{ position: 'relative' }}
                                    className="editIcon"
                                    size="20px"
                                />
                            </Link>
                        </div>
                        )}
                    </>
                )}
                onView={() => {
                    handleTimeline(driver.vehicle.id);
                }}
                mode="inline"
            >
                {({ hide }) => (
                    <>
                        <div onChange={(e) => { onChangeHandler(e) }} onKeyDown={(e) => { onClickHandler(e, hide) }}>
                            <TagInput
                                ref={(elm) => {
                                    inputRef.current = elm;
                                    register(elm);
                                }}
                                name="vehicle"
                                // label="Vehicle"
                                options={options}
                                size={"extraSmall"}
                                singleOption={true}
                                visibleOptionsQty={10}
                            />
                        </div>
                        <Spacer left={1} inline>
                            <Action
                                type="primary"
                                onClick={() => handleSave(hide)}
                            >
                                <Icon.Check color={"white"} size={"20px"} />
                            </Action>
                            <Action type="cancel" onClick={hide}>
                                <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                            </Action>
                        </Spacer>
                    </>
                )}
            </AddOrEdit>
            {showTimeline && (
                <Timeline
                    visible={showTimeline}
                    setVisible={setShowTimeline}
                    title={<SubTitle>Vehicle ID: {driver.vehicle.id} - Timeline</SubTitle>}
                    loading={!dataTimeline}
                    data={dataTimeline}
                />
            )}
        </>
    );
};

export const MileageController = ({ rts, kkl, date }) => {
    const { api } = useAuth();
    const [hide, setHide] = useState(true);
    const [mileage, setMilage] = useState(0);
    const { register, getValues } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        if (new Date(date) >= new Date("2022-03-10")) {
            if (rts && rts.mileage) {
                setMilage(rts.mileage);
            }
        } else {
            if (kkl && kkl.mileage) {
                setMilage(kkl.mileage);
            }
        }
    }, [rts]);

    const callMilageApi = (value) => {
        const paramsMileage =
            new Date(date) >= new Date("2022-03-10")
                ? {
                    actions: {
                        response: {
                            ReturnToStation: {
                                custom: {
                                    functionName: "updateMileageToWeek",
                                    get: "result",
                                    criteria: {
                                        id: rts.id,
                                        mileage: value,
                                        type: "rts",
                                    },
                                },
                            },
                        },
                    },
                }
                : {
                    actions: {
                        response: {
                            ReturnToStation: {
                                custom: {
                                    functionName: "updateMileageToWeek",
                                    get: "result",
                                    criteria: {
                                        id: kkl.id,
                                        mileage: value,
                                        type: "kkl",
                                    },
                                },
                            },
                        },
                    },
                };
        try {
            const responseVehicleData = api.post("/api/lazy/manage/data", paramsMileage);
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    const inputRef = useRef();

    return (
        <>
            {hide ? (
                <Text>
                    {(rts && rts.mileage) || (kkl && kkl.mileage) ? mileage : "-"}
                    {((rts && rts.mileage) || (kkl && kkl.mileage)) && (
                        <Icon.Edit
                            onClick={() => {
                                setHide(false);
                            }}
                            color={Theme.colors.info.border}
                            size={"20px"}
                        />
                    )}
                </Text>
            ) : (
                <>
                    <Input
                        ref={(elm) => {
                            inputRef.current = elm;
                            register(elm);
                        }}
                        defaultValue={mileage}
                        name="mileage"
                        size={"extraSmall"}
                    />
                    <Spacer left={1} inline>
                        <Action
                            type="primary"
                            onClick={() => {
                                let tempMileage = getValues().mileage;
                                setMilage(tempMileage);
                                setHide(true);
                                callMilageApi(tempMileage);
                            }}
                        >
                            <Icon.Check color={"white"} size={"20px"} />
                        </Action>
                        <Action
                            type="cancel"
                            onClick={() => {
                                setHide(true);
                            }}
                        >
                            <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                        </Action>
                    </Spacer>
                </>
            )}
        </>
    );
};

export const DriverDeviceController = ({ driver, addChange, breakLine, pageLoadOut = true }) => {
    const { api } = useAuth();
    const { register, getValues } = useForm({
        mode: "onBlur",
    });
    const [showTimeline, setShowTimeline] = useState(false);
    const [dataTimeline, setDataTimeline] = useState(null);

    const [devices, setDevices] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const loadOptions = async (driver) => {
        try {
            const params = {
                actions: {
                    response: {
                        Device: {
                            custom: {
                                functionName: "getLoadOutDeviceByStation",
                                get: "result",
                                excludes: ["station", "company"],
                                criteria: {
                                    id: driver.id,
                                    timezoneOffset: new Date().getTimezoneOffset(),
                                    date: driver.shiftDate,
                                    stationId: driver.stationId,
                                    driverID: driver.driverId,
                                },
                            },
                        },
                    },
                },
            };
            let response = await api.post("/api/lazy/manage/data", params, {
                cache: {
                    ignoreCache: true,
                },
            });
            if (response) {
                setDevices([]);
                let deviceArr = [];
                let temp = [];

                response.data.data.result.map((device, key) => {
                    temp = {
                        name: device.name,
                        value: device.id,
                        selected: driver.devices.filter((d) => d.id == device.id).length > 0 ? true : false,
                    };
                    deviceArr.push(temp);
                });
                setDevices(deviceArr);
            }
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    let collection = driver.devices.length ? driver.devices.map((d) => d.name) : [];

    const callAddDeviceApi = (deviceId, routeId, name, userId) => {
        const paramsDeviceAddJson = {
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "addLoadOutTempDriverRouteData",
                            get: "result",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "shiftType",
                                "schedule",
                                "status",
                                "routeId",
                                "createdBy",
                                "kickoffLog",
                                "vehicleDriverRecord",
                                "oldDriver",
                                "oldDriverRoute",
                            ],
                            criteria: {
                                deviceId: deviceId,
                                routeId: routeId,
                                name: name,
                                userId: userId,
                            },
                        },
                    },
                },
            },
        };

        try {
            const responseVehicleData = api.post("/api/lazy/manage/data", paramsDeviceAddJson);
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    const handleTimeline = useCallback(async (deviceId) => {
        try {
            setShowTimeline(true);
            let response = await api.post("/api/lazy/get_timeline_events", {
                deviceId: deviceId,
                timezone: moment.tz.guess(),
                timezoneOffset: new Date().getTimezoneOffset(),
            });
            setDataTimeline(response.data);
        } catch (e) {
            setShowTimeline(false);
        }
    }, []);

    const inputRef = useRef();

    const handleSave = (hide, isEnter) => {
        addChange({
            description: "device",
            driverId: driver.id,
            callback: () => {
                setDevices([]);
                driver.devices = [];
                let device = getValues().devices;

                devices.map((d) => {
                    if (device.includes(d.value.toString())) {
                        driver.devices.push({ id: d.value, name: d.name });
                    }
                });
                callAddDeviceApi(getValues().devices, driver.id, driver.name, driver.userId);
                if (isEnter) {
                    setTimeout(() => { hide() }, 50)
                } else {
                    hide();
                }
            },
        })
    }

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
    }

    const onClickHandler = (e, hide) => {
        if (typeof inputValue === 'string' && inputValue.trim().length > 0) {
            setInputValue("")
        } else if (typeof inputValue === 'string' && inputValue.trim().length === 0 && e.keyCode === 13) {
            handleSave(hide, true)
        }
    }

    return (
        <>
            <AddOrEdit
                pageLoadOut={pageLoadOut}
                width="340px"
                title={`${driver.name}'s Devices`}
                collection={collection}
                breakLine={true}
                enabled={
                    (driver.isRestore != true &&
                        moment(driver.shiftDate).isSameOrBefore(moment().utc().format("YYYY-MM-DD")) &&
                        driver.shiftType.category != 8)
                        ? true
                        : false
                }
                onOpen={() => {
                    loadOptions(driver);
                    setTimeout(() => inputRef.current.parentNode.querySelector("input").focus(), 10);
                }}
                onView={() => {
                    handleTimeline(driver?.devices[0]?.id)
                }}
                mode="inline"
            >
                {({ hide }) => (
                    <>
                        <div onChange={onChangeHandler} onKeyDown={(e) => { onClickHandler(e, hide) }}>
                            <TagInput
                                ref={(elm) => {
                                    inputRef.current = elm;
                                    register(elm);
                                }}
                                name="devices"
                                // label="Device"
                                options={devices}
                                size={"extraSmall"}
                            />
                        </div>
                        <Spacer left={1} inline>
                            <Action
                                type="primary"
                                onClick={() => handleSave(hide)}
                            >
                                <Icon.Check color={"white"} size={"20px"} />
                            </Action>
                            <Action type="cancel" onClick={hide}>
                                <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                            </Action>
                        </Spacer>
                    </>
                )}
            </AddOrEdit>
            {showTimeline && (
                <Timeline
                    visible={showTimeline}
                    setVisible={setShowTimeline}
                    title={<SubTitle>{driver?.devices[0]?.name} - Timeline</SubTitle>}
                    loading={!dataTimeline}
                    data={dataTimeline || []}
                />
            )}
        </>
    );
};

const StagingLocationController = ({ driver, addChange }) => {
    const { api } = useAuth();
    const [{ stationMap }] = useContext(LoadOutStore);
    const { register, getValues, reset } = useForm({
        // defaultValues: {
        //     stagingLocation: driver.stagingLocation,
        // },
    });
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState("");

    const handleSave = (hide, isEnter) => {
        let l = getValues("stagingLocation")?.[0];

        if (!l || !l.trim() || undefined) {
            setTimeout(() => {
                hide();
            }, 50);
            return;
        };

        if (l === driver.stagingLocation) {
            setTimeout(() => {
                hide();
            }, 50);
            return;
        }
        let editedOption = stationMap.filter((o) => o.value === l);
        // Fix Enter key issue
        if (inputValue == "") {
            if (inputValue == "" && editedOption.length == 0) {
                setTimeout(() => {
                    hide();
                }, 50);
            } else if (editedOption.length != 0) {
                setTimeout(() => {
                    hide();
                }, 50);
            } else {
                setTimeout(() => {
                    hide();
                }, 50);
                return;
            }
        }

        if (editedOption.length == 0) {
            if (driver.routes[0]) {
                addChange({
                    description: "setStagingLocation",
                    callback: (state) => {
                        let d = state.drivers.find((d) => d.id === driver.id);
                        d.stagingReference = ``;
                        d.stagingLocation = `${l}`;
                        return state;
                    },
                });

                api.post("/api/lazy/manage/data", {
                    actions: {
                        response: {
                            DriverRouteCode: {
                                custom: {
                                    functionName: "addStagingLocation",
                                    get: "stagingLocation",
                                    criteria: {
                                        company: getCompanyId(),
                                        station: driver.stationId,
                                        code: driver.routes[0],
                                        dateCreated: driver.shiftDate,
                                        isArchive: false,
                                        stagingLocation: l,
                                    },
                                },
                            },
                        },
                    },
                }).then((response) => {
                    addChange({
                        description: "setStagingLocation",
                        callback: (state) => {
                            let d = state.drivers.find((d) => d.id === driver.id);
                            d.stagingReference = ``;
                            d.stagingLocation = `${l}`;
                            return state;
                        },
                    });

                    api.post("/api/lazy/manage/data", {
                        actions: {
                            response: {
                                DriverRouteCode: {
                                    custom: {
                                        functionName: "updateStagingLocation",
                                        get: "stagingLocation",
                                        criteria: {
                                            company: getCompanyId(),
                                            station: driver.stationId,
                                            code: driver.routes[0],
                                            dateCreated: driver.shiftDate,
                                            isArchive: false,
                                            stagingLocation: l,
                                        },
                                    },
                                },
                                StationMap: {
                                    findBy: {
                                        get: "stationMap",
                                        criteria: {
                                            company: getCompanyId(),
                                            station: driver.stationId,
                                            isArchive: false,
                                        },
                                        matching: {
                                            locations: {
                                                contains: l,
                                            },
                                        },
                                        includes: ["name"],
                                    },
                                },
                            },
                        },
                    }).then((response) => {
                        reset({
                            stagingLocation: response?.data?.data?.stationMap?.[0]?.name,
                        });
                        addChange({
                            description: "setStagingReference",
                            callback: (state) => {
                                let d = state.drivers.find((d) => d.id === driver.id);
                                d.stagingReference = response?.data?.data?.stationMap?.[0]?.name;
                                // d.stagingLocation = response?.data?.data?.stationMap?.[0]?.name;
                                let stagingReference = (d.stagingReference != '') ? d.stagingReference : l;
                                state.stationMap.push({ name: stagingReference, value: stagingReference });
                                return state;
                            },
                        });
                        if (isEnter) {
                            setTimeout(() => { hide() }, 50)
                        } else {
                            hide();
                        }
                    });
                });
            }
        } else {
            addChange({
                description: "setStagingLocation",
                callback: (state) => {
                    let d = state.drivers.find((d) => d.id === driver.id);
                    d.stagingReference = ``;
                    d.stagingLocation = `${l}`;
                    return state;
                },
            });

            api.post("/api/lazy/manage/data", {
                actions: {
                    response: {
                        DriverRouteCode: {
                            custom: {
                                functionName: "updateStagingLocation",
                                get: "stagingLocation",
                                criteria: {
                                    company: getCompanyId(),
                                    station: driver.stationId,
                                    code: driver.routes[0],
                                    dateCreated: driver.shiftDate,
                                    isArchive: false,
                                    stagingLocation: l,
                                },
                            },
                        },
                        StationMap: {
                            findBy: {
                                get: "stationMap",
                                criteria: {
                                    company: getCompanyId(),
                                    station: driver.stationId,
                                    isArchive: false,
                                },
                                matching: {
                                    locations: {
                                        contains: l,
                                    },
                                },
                                includes: ["name"],
                            },
                        },
                    },
                },
            }).then((response) => {
                reset({
                    stagingLocation: response?.data?.data?.stationMap?.[0]?.name,
                });
                addChange({
                    description: "setStagingReference",
                    callback: (state) => {
                        let d = state.drivers.find((d) => d.id === driver.id);
                        d.stagingReference = response?.data?.data?.stationMap?.[0]?.name;
                        return state;
                    },
                });
                if (isEnter) {
                    setTimeout(() => { hide() }, 50)
                } else {
                    hide();
                }
            });
        }
    };

    const collection = driver.stagingLocation
        ? [`${driver.stagingReference ? driver.stagingReference + "/" : ""}${driver.stagingLocation}`]
        : [];

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
    }

    const onClickHandler = (e, hide) => {
        if (typeof inputValue === 'string' && inputValue.trim().length > 0) {
            setInputValue("")
        } else if (typeof inputValue === 'string' && inputValue.trim().length === 0 && e.keyCode === 13) {
            handleSave(hide, true)
        }
    }

    return (
        <AddOrEdit
            collection={collection}
            mode="inline"
            onOpen={() =>
                setTimeout(() => inputRef.current && inputRef.current.parentNode.querySelector("input").focus(), 10)
            }
            enabled={(driver.rescue == false && driver.isRestore != true && driver.shiftType.category != 8 && driver.routes.length > 0) ? true : false}
        >
            {({ hide }) => (
                <>
                    <div onChange={onChangeHandler} onKeyDown={(e) => { onClickHandler(e, hide) }}>
                        <TagInput
                            size={"extraSmall"}
                            name={"stagingLocation"}
                            ref={(elm) => {
                                inputRef.current = elm;
                                register(elm);
                            }}
                            options={stationMap}
                            singleOption={true}
                            canCreate={true}
                            defaultValues={[driver.stagingLocation ? driver.stagingLocation : '']}
                        />
                    </div>
                    <Spacer left={1} inline />
                    <Action type={"primary"} onClick={() => handleSave(hide)}>
                        <Icon.Check color={"white"} size={"20px"} />
                    </Action>
                    <Action type={"cancel"} onClick={hide}>
                        <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                    </Action>
                </>
            )}
        </AddOrEdit>
    );
};

const DriverRowStyle = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    &.changed {
        animation: ${blink} 3s;
    }

    &.drop-before {
        box-shadow: 0 2px 0 inset ${Theme.colors.info.border};
    }

    &.drop-after {
        box-shadow: 0 -2px 0 inset ${Theme.colors.info.border};
    }

    > :last-child {
        margin-left: auto;
    }

    ${Icon.Move} {
        cursor: move;
        opacity: 0.25;
    }

    &:hover ${Icon.Move} {
        opacity: 1;
    }

    div[id*="dspactions"] {
        li::before {
            right: auto;
            left: -5px;
        }

        li ul {
            right: 100%;
            left: auto;
            margin-right: 4px;
        }
    }
`;

const DriverShiftType = ({
    shiftType,
    backup,
    rescue,
    training,
    secondShift,
    startTime,
    sentToHome,
    refuseRescue,
    incident,
    hasCallOutOrNcns,
    isTimeOff,
    hasAnySinglePunch,
    isPerformanceRelated,
}) => {
    return (
        <>
            {!backup && !rescue && !training && !secondShift ? (
                <>
                    <ShiftTypeColor style={{ background: shiftType.color }}>
                        {sentToHome && (
                            <Icon.BackHome size="13px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />
                        )}
                        {refuseRescue && (
                            <>
                                <Icon.Plus
                                    size="20px"
                                    color={"red"}
                                    style={{ marginBottom: 1, position: "relative" }}
                                />
                                <Icon.NotAllowed
                                    style={{ position: "absolute", marginLeft: "-20px" }}
                                    size="20px"
                                    color={"blue"}
                                />
                            </>
                        )}
                        {!sentToHome && incident && <SolveIncidentIcons incident={incident} />}
                        {!sentToHome && shiftType.category === 2 && (
                            <Icon.Replace size="16px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />
                        )}
                        {!sentToHome && shiftType.category === 3 && (
                            <Icon.GraduationHat
                                size="16px"
                                color={Theme.colors.neutrals.white}
                                style={{ marginTop: 2 }}
                            />
                        )}
                        {!sentToHome && shiftType.category === 6 && (
                            <Icon.Feather size="16px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />
                        )}
                    </ShiftTypeColor>
                    <ShiftTypeName>
                        {shiftType.name && shiftType.name.length > 25
                            ? shiftType.name.slice(0, 25) + "..."
                            : shiftType.name}{" "}
                        <span>
                            {hasAnySinglePunch || (!hasCallOutOrNcns && !sentToHome && !isTimeOff) ? startTime : ""}
                        </span>
                    </ShiftTypeName>
                </>
            ) : (
                <>
                    <div
                        style={{
                            position: "relative",
                            width: 28,
                            height: 28,
                            display: "inline-flex",
                            alignItems: "flex-start",
                        }}
                    >
                        {backup && (
                            <ShiftTypeColor
                                smaller={true}
                                style={{
                                    background: backup.color,
                                    left: 8,
                                }}
                            >
                                <Icon.Replace size="12px" color={Theme.colors.neutrals.white} />
                            </ShiftTypeColor>
                        )}
                        {training && (
                            <ShiftTypeColor
                                smaller={true}
                                style={{
                                    background: backup.color,
                                    left: 8,
                                }}
                            >
                                <Icon.Feather size="13px" color={Theme.colors.neutrals.white} />
                            </ShiftTypeColor>
                        )}
                        {secondShift && (
                            <ShiftTypeColor
                                smaller={true}
                                style={{
                                    background: secondShift.color,
                                    left: 8,
                                }}
                            ></ShiftTypeColor>
                        )}

                        {rescue && (
                            <ShiftTypeColor
                                smaller={true}
                                style={{
                                    background: rescue.color,
                                    left: 8,
                                    lineHeight: "12px",
                                }}
                            >
                                {isPerformanceRelated ? (
                                    <Icon.Times
                                        style={{
                                            transform: "rotate(45deg)",
                                            marginTop: -1,
                                            stroke: "red",
                                            strokeWidth: 1,
                                        }}
                                        size="16px"
                                        color={"red"}
                                    />
                                ) : (
                                    <Icon.Broom
                                        style={{
                                            transform: "rotate(45deg)",
                                            stroke: "white",
                                            strokeWidth: 1,
                                            marginTop: 2,
                                        }}
                                        size="10px"
                                    />
                                )}
                            </ShiftTypeColor>
                        )}
                        <ShiftTypeColor
                            smaller={true}
                            style={{
                                background: shiftType.color,
                                top: 8,
                            }}
                        />
                    </div>
                    <ShiftTypeName>
                        {shiftType.name && shiftType.name.length > 25
                            ? shiftType.name.slice(0, 25) + "..."
                            : shiftType.name}
                        <span>
                            {backup && `Backup for ${backup.driver}`}
                            {rescue && `Rescued ${rescue.driver}`}
                            <span>
                                {hasAnySinglePunch || (!hasCallOutOrNcns && !sentToHome && !isTimeOff) ? startTime : ""}
                            </span>
                        </span>
                    </ShiftTypeName>
                </>
            )}
        </>
    );
};

const SolveIncidentIcons = ({ incident }) => {
    switch (incident) {
        case "NCNS":
            return <Icon.Times size="15px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />;
            break;
        case "Call Out":
            return <Icon.Phone size="13px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />;
            break;
        case "Late":
            return <Icon.Clock size="13px" color={Theme.colors.neutrals.white} style={{ marginTop: 2 }} />;
            break;
        case "Accident/Injury":
            return <img src={IconAccidentInjury} color={Theme.colors.neutrals.white} style={{ height: "20px", width: "20px" }} />;
            break;
        default:
            return null;
    }
};

const columnWidths = {
    move: "40px",
    driver: "14.1%",
    shiftType: "14.79%",
    status: "13.29%",
    routes: "10.41%",
    vehicle: "9.88%",
    devices: "11.9%",
    stagingLocation: "12%",
    bag: "3.41%",
    actions: "auto",
};

const DriverRow = ({ driver, index, selectedRows, setSelectedRows }) => {
    const [state, dispatch] = useContext(LoadOutStore);
    const { api } = useAuth();

    const {
        editShift,
        getShiftOptions,
        getTodayRouteCodes,
        saveRescueShift,
        saveSpecialShift,
        dropLoadoutShift,
        dropRescueLoadoutShift,
        saveRefusedRescueShift,
        tempEditShift,
        ...schedulerApi
    } = useSchedulerApi();
    const addChange = ({ description, driverId, callback }) => {
        if (driverId) {
            highlightDriver(driverId);
        }

        dispatch({
            type: "ADD_CHANGE",
            payload: description,
            callback: callback,
        });
    };

    // const [{ opacity }, drag, preview] = useDrag({
    //     item: {
    //         type: "DriverRow",
    //         id: driver.id,
    //         from: index,
    //     },
    //     collect: (monitor) => ({
    //         opacity: monitor.isDragging() ? 0.5 : 1,
    //     }),
    //     end: (dropResult, monitor) => {
    //         if (!monitor.didDrop()) clearHighlight();
    //     },
    // });
    //
    // const clearHighlight = () => {
    //     return document
    //         .querySelectorAll(".drop-before, .drop-after")
    //         .forEach(e => e.classList.remove("drop-before", "drop-after"));
    // };

    // const setHighlight = (index, where) => {
    //     return document
    //         .getElementById(`driver-row-${state.drivers[index].id}`)
    //         .classList.add(`drop-${where}`);
    // };

    // const highlightDrop = (item, i) => {
    //     clearHighlight();
    //
    //     if (item.from < i) {
    //         return i + 1 > state.drivers.length - 1
    //             ? setHighlight(state.drivers.length - 1, "after")
    //             : setHighlight(i + 1, "before");
    //     } else if (item.from > i) {
    //         return i - 1 < 0
    //             ? setHighlight(0, "before")
    //             : setHighlight(i - 1, "after");
    //     }
    // };

    // const reorder = (item) => {
    //     clearHighlight();
    //
    //     if (item.from === index) return;
    //
    //     dispatch({
    //         type: "MANUALLY_SORT",
    //         payload: { from: item.from, to: index },
    //         callback: () => {
    //             highlightDriver(state.drivers[item.from].id);
    //         },
    //     });
    // };

    // const [, drop] = useDrop({
    //     accept: "DriverRow",
    //     hover: item => highlightDrop(item, index),
    //     drop: item => reorder(item)
    // });

    let d = driver;

    const getDspActions = (driver) => {
        let actions = [];
        if (!driver.incident && state.incidentTypes.length) {
            state.incidentTypes.forEach((type) => {
                if (type.token != "Acc" && type.token != "Dro" && type.token != "Pic" && type.token != "Thx" && type.token != "Eno" && type.token != "Cor" && type.token != "Coa") {
                    actions.push({
                        name: type.name,
                        value: `incident-${type.id}`,
                    });
                }
            });
            actions.push({ name: "Report Incident", value: `incident-report` });
        }

        const more = schedulerApi.getShiftActions({
            shift: driver,
            format: "loadout",
        });
        if (
            state.waveData.station.toString() === driver.stationId.toString() &&
            [
                "ROLE_STATION_MANAGER",
                "ROLE_OWNER",
                "ROLE_ASSISTANT_STATION_MANAGER",
                "ROLE_LEAD_DRIVER",
                "ROLE_OPERATIONS_ACCOUNT_MANAGER",
            ].includes(localStorage.getItem("currentUserRole"))
        ) {
            actions.push({ name: "Password Reset", value: "password-reset", divider: true });
        }

        more.forEach((action, i) => {
            actions.push({
                name:
                    (action.label != "Make Rescue" && action.action.substr(0, 3) === "add" ? "Add " : "") +
                    action.label,
                value: action.action,
                divider: i === 0,
            });
        });

        return actions;
    };

    const getTrashActions = (driver) => {
        let actions = [];
        actions.push({ name: "Delete/Drop Options" });
        if (driver.routes.length > 0) {
            actions.push({ name: "Delete Shift/Route", value: "delete-shift-and-route" });
        } else {
            actions.push({ name: "Delete Shift", value: "delete-shift", divider: true });
        }
        if (driver.shiftType.category != 8 && driver.rescue == false && localStorage.getItem('role') != 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE') {
            actions.push({ name: "Delete Shift/Move Route to Open", value: "drop-route-to-open" });
        }

        return actions;
    };

    const handleDspActions = async (driver, option) => {
        let actionType = option.value;
        let param;
        let waveData;

        switch (actionType) {
            case "send-home":
                schedulerApi.setSendHome({
                    driverId: driver.id,
                });
                const checkForSendHome = state.sendedHome.indexOf(driver.id);
                if (checkForSendHome <= -1) {
                    state.sendedHome.push(driver.id);
                }
                dispatch({
                    type: "UPDATE_SENT_HOME",
                    payload: { id: driver.id, displayTime: moment().format("h:mma"), time: moment() },
                });

                waveData = await API.fetchWaveData(
                    {
                        station: driver.stationId,
                        date: driver.shiftDate,
                    },
                    api
                );
                dispatch({
                    type: "SET_WAVE_DATA",
                    payload: waveData,
                });

                return alert("Sent Home Successfully!!!");

            case "un-send-home":
                schedulerApi.setSendHome({
                    driverId: driver.id,
                    time: null,
                });
                const checkUnForSendHome = state.sendedHome.indexOf(driver.id);
                if (checkUnForSendHome > -1) {
                    state.sendedHome.splice(checkUnForSendHome, 1);
                }
                dispatch({
                    type: "UPDATE_SENT_HOME",
                    payload: { id: driver.id, displayTime: null, time: null, shiftInvoiceType: null },
                });

                waveData = await API.fetchWaveData(
                    {
                        station: driver.stationId,
                        date: driver.shiftDate,
                    },
                    api
                );
                dispatch({
                    type: "SET_WAVE_DATA",
                    payload: waveData,
                });
                return alert("Un-sent Home Successfully!!!");

            case "password-reset":
                return passwordReset(driver);

            case "refused-rescue":
                return handleRefusedRescue(driver);

            default:
                if (actionType.match("incident-")) {
                    const incidentsApi = (await import("../Incidents/api")).default;

                    openReportIncidentForm({
                        station: state.waveData.station,
                        driver: driver.driverId,
                        incidentType: actionType.replace("incident-", ""),
                        driverRoute: driver.id.toString(),
                        date: moment(state.selectedDate).format("YYYY-MM-DD"),
                        time: moment.utc().format("HH:mm"),
                        dropAndPicRoute: false,
                    }).then(
                        ({ data: incident, action }) => {
                            if (!incident) return false;
                            const hide = loadingDialog();
                            const res = incidentsApi
                                .startIncident(incident)
                                .then(async (response) => {
                                    if (driver.routes.length > 0) {
                                        state.drivers.forEach((dri) => {
                                            if (dri.id == driver.id && response) {
                                                dri.incident = { type: response.data.data.result.incidentType.name };
                                                if (
                                                    response.data.data.result.incidentType.token == "Cal" ||
                                                    response.data.data.result.incidentType.token == "Ncn"
                                                ) {
                                                    dri.hasCallOutOrNcns = true;
                                                }
                                            }
                                        });

                                        if (response.data.data.result.incidentType.token == "Dro") {
                                            // if droproute then delete
                                            if (
                                                response.data.data.result.driverRoute &&
                                                response.data.data.result.driverRoute.id
                                            ) {
                                                let a = await incidentsApi.incidentDroprouteAutofill(
                                                    response.data.data.result.id
                                                );
                                                await dropLoadoutShift({
                                                    driverId: response.data.data.result.driverRoute.id,
                                                    incidentId: response.data.data.result.id,
                                                });
                                            }
                                        } else if (response.data.data.result.incidentType.token == "Pic") {
                                            // if pickuproute then autofill
                                            if (
                                                response.data.data.result.driverRoute &&
                                                response.data.data.result.driverRoute.id
                                            ) {
                                                let a = await incidentsApi.incidentPickuprouteAutofill(
                                                    response.data.data.result.id
                                                );
                                            }
                                        }

                                        dispatch({
                                            type: "REFRESH",
                                        });
                                        // dispatch({
                                        //     type: "REMOVE_DRIVER_ROUTE",
                                        //     payload:driver,
                                        // });
                                        // driver.code = driver.routes.join(', ');

                                        // dispatch({
                                        //     type: "ADD_OPEN_ROUTE",
                                        //     payload:driver,
                                        // });
                                        // dispatch({
                                        //     type: "UPDATE_ROUTE_CODE_BOX",
                                        //     payload:'asign',
                                        // });
                                        response && action === "next"
                                            ? incidentPromise(response.data.data.result.id)
                                            : toast({
                                                type: "Success",
                                                title: "Incident successfully reported",
                                                content: (
                                                    <Link
                                                        style={{ color: "inherit" }}
                                                        to={`/incidents/${response.data.data.result.id}`}
                                                    >
                                                        View incident
                                                    </Link>
                                                ),
                                                timeout: 8000,
                                            });
                                    } else {
                                        state.drivers.forEach((dri) => {
                                            if (dri.id == driver.id && response) {
                                                dri.incident = { type: response.data.data.result.incidentType.name };
                                                if (
                                                    response.data.data.result.incidentType.token == "Cal" ||
                                                    response.data.data.result.incidentType.token == "Ncn"
                                                ) {
                                                    dri.hasCallOutOrNcns = true;
                                                }
                                            }
                                        });
                                        dispatch({
                                            type: "REFRESH",
                                        });
                                        response && action === "next"
                                            ? incidentPromise(response.data.data.result.id)
                                            : toast({
                                                type: "Success",
                                                title: "Incident successfully reported",
                                                content: (
                                                    <Link
                                                        style={{ color: "inherit" }}
                                                        to={`/incidents/${response.data.data.result.id}`}
                                                    >
                                                        View incident
                                                    </Link>
                                                ),
                                                timeout: 8000,
                                            });
                                    }
                                })
                                .then(hide);
                            return res;
                        },
                        (error) => {
                            if (error.response)
                                toast({
                                    type: "error",
                                    title: "Error",
                                    content: error.response.data.message,
                                });
                        }
                    );
                }

                if (actionType.match("add-")) {
                    const hideLoading = loadingDialog();
                    const isRescue = actionType.match("rescue") !== null;
                    Promise.all([
                        getTodayRouteCodes({
                            station: driver.stationId,
                            dateCreated: driver.shiftDate,
                            isRescue: true,
                        }),
                        getShiftOptions({
                            shift: {
                                isRescuer: isRescue,
                                stationId: driver.stationId,
                            },
                        }),
                    ]).then(async ([todayRouteCodes, shiftOptions]) => {
                        const currentShiftCategory = driver.category ? driver.category : null;
                        const shiftTypes = shiftOptions.data.data.shift.filter((s) =>
                            isRescue ? s.category === 4 : s.category === 1
                        );
                        const invoiceTypes = shiftOptions.data.data.invoiceTypes;

                        if (isRescue) {
                            todayRouteCodes.data.data.codes = await todayRouteCodes.data.data.codes.filter(
                                (rc) => !driver.routes.includes(rc.code)
                            );
                        }

                        let fields = !isRescue
                            ? undefined
                            : [
                                {
                                    component: TagInput,
                                    props: {
                                        name: "routeCode",
                                        label: "Route Being Rescued",
                                        Required: true,
                                        options: todayRouteCodes.data.data.codes.map((c) =>
                                            c.selected == false
                                                ? {
                                                    name: c.code,
                                                }
                                                : {
                                                    name: c.code,
                                                    value: c.code,
                                                    // selected: shift.routeCode.includes(c.code)
                                                }
                                        ),
                                        singleOption: true,
                                    },
                                },
                                {
                                    component: Input,
                                    props: {
                                        name: "numberOfPackage",
                                        label: "Number of Packages",
                                        Required: true,
                                    },
                                },
                            ];

                        showFlexibleShiftBlockForm({
                            options: {
                                shiftTypes: shiftTypes,
                                shiftInvoiceTypes: invoiceTypes,
                                loaded: true,
                            },
                            title: `Add ${isRescue ? "Rescue" : "Second"} Shift`,
                            shift: {
                                typeId: isRescue ? shiftTypes[0].id : undefined,
                                routeId: driver.id,
                                isRescue: true,
                                isSecondShift: !isRescue,
                                utcEndTime: driver.utcEndTime,
                                utcPriorShiftStartTime: parseFloat(driver.utcStartTime),
                                category: currentShiftCategory,
                                isPerformanceRelated: driver.isPerformanceRelated,
                            },
                            fields: fields,
                        }).then(
                            async (newShift) => {
                                if (!newShift) return;

                                const old = {
                                    routeId: driver.id,
                                    shiftDate: moment(state.selectedDate).format("YYYY-MM-DD"),
                                };

                                dispatch({
                                    type: "UPDATE_IS_LOADING",
                                    payload: { id: driver.id },
                                });

                                if (isRescue)
                                    saveRescueShift({
                                        rescueShift: newShift,
                                        rescued: old,
                                    });
                                else
                                    schedulerApi.saveSecondShift({
                                        secondShift: newShift,
                                        oldShift: old,
                                    });

                                let waveData = await API.fetchWaveData(
                                    {
                                        station: state.waveData.station,
                                        date: state.selectedDate,
                                    },
                                    api
                                );
                                dispatch({
                                    type: "SET_WAVE_DATA",
                                    payload: waveData,
                                });
                            },
                            (error) => {
                                if (error.response)
                                    toast({
                                        type: "error",
                                        title: "Error",
                                        content: error.response.data.message,
                                    });
                            }
                        );
                    });
                }

                if (actionType.match("make-")) {
                    const hideLoading = loadingDialog();
                    let category = actionType.replace("make-", "") === "trainer" ? 5 : 6;
                    getShiftOptions({
                        shift: {
                            isTrain: category === 5,
                            isDuty: category === 6,
                            stationId: driver.stationId,
                            shiftDate: moment(state.selectedDate).format("YYYY-MM-DD"),
                            driverStation: driver.stationId,
                            driverId: driver.driverId,
                        },
                    }).then((shiftOptions) => {
                        const shiftType = shiftOptions.data.data.shift.find((s) => s.category === category);
                        const invoiceTypes = shiftOptions.data.data.invoiceTypes;
                        const drivers = shiftOptions.data.data.drivers;

                        if (!shiftType)
                            return toast({
                                type: "warning",
                                title: "No shift type found!",
                            });

                        showFlexibleShiftBlockForm({
                            options: {
                                shiftTypes: [shiftType],
                                shiftInvoiceTypes: invoiceTypes,
                                loaded: true,
                            },
                            title: "Change Shift",
                            shift: {
                                isAddTrain: category === 5,
                                isLightDuty: category === 6,
                                typeId: shiftType.id,
                                routeId: driver.id,
                                stationId: driver.stationId,
                                utcStartTime: driver.utcStartTime,
                                utcEndTime: driver.utcEndTime,
                            },
                            fields: [
                                {
                                    component: CustomDropdown,
                                    props: {
                                        name: category === 5 ? "trainees" : "pairDriver",
                                        label: category === 5 ? "Trainees" : "Pair Driver",
                                        Required: category === 5 ? true : false,
                                        type: "people",
                                        multiple: category === 5,
                                        options: drivers.map((opt) => ({
                                            value: opt.id,
                                            name: opt.fullName,
                                            profile: opt.user.image,
                                        })),
                                        visibleOptionsQty: 6,
                                    },
                                },
                            ],
                        }).then(
                            async (newShift) => {
                                if (!newShift) return;

                                dispatch({
                                    type: "UPDATE_IS_LOADING",
                                    payload: { id: driver.id },
                                });

                                await saveSpecialShift({
                                    newShift: {
                                        ...newShift,
                                        isTrain: category === 5,
                                        isDuty: category === 6,
                                    },
                                    related: {
                                        ...driver,
                                        routeId: driver.id,
                                        shiftDate: moment(state.selectedDate).format("YYYY-MM-DD"),
                                    },
                                });

                                let waveData = await API.fetchWaveData(
                                    {
                                        station: state.waveData.station,
                                        date: state.selectedDate,
                                    },
                                    api
                                );
                                dispatch({
                                    type: "SET_WAVE_DATA",
                                    payload: waveData,
                                });
                            },
                            (error) => {
                                if (error.response)
                                    toast({
                                        type: "error",
                                        title: "Error",
                                        content: error.response.data.message,
                                    });
                            }
                        );
                    });
                }
                break;
        }
    };

    const handleDropRouteActions = async (driver, option) => {
        let action = option.value;
        let param;

        switch (action) {
            case "delete-shift-and-route":
                const conf1 = await confirm({
                    icon: true,
                    text: "This will treat this Shift and Route Code as as scheduling errors and remove them from today's record.",
                });

                if (!conf1) return;

                if (driver.rescue && driver.rescue.driver) {
                    let response = await dropRescueLoadoutShift({
                        driverId: driver.id,
                        incidentId: null,
                    });

                    dispatch({
                        type: "REMOVE_DRIVER_ROUTE",
                        payload: driver,
                    });
                    dispatch({
                        type: "UPDATE_ROUTE_CODE_BOX",
                        payload: "asign",
                    });
                    dispatch({
                        type: "REMOVE_CHANGES",
                        id: driver.id,
                        station: driver.stationId,
                    });
                    dispatch({
                        type: "UPDATE_RESCUER",
                        payload: response.data.data.dropRescueShift.rescuerDriverRoute,
                    });
                } else {
                    dropLoadoutShift({
                        driverId: driver.id,
                    });

                    dispatch({
                        type: "REMOVE_DRIVER_ROUTE",
                        payload: driver,
                    });
                    dispatch({
                        type: "UPDATE_ROUTE_CODE_BOX",
                        payload: "asign",
                    });
                    dispatch({
                        type: "REMOVE_CHANGES",
                        id: driver.id,
                        station: driver.stationId,
                    });
                }
                return toast({
                    type: "success",
                    title: "Shift and Route successfully removed.",
                });

                return;
            case "delete-shift":
                const conf2 = await confirm({
                    icon: true,
                    text: "This will treat this Shift as as a scheduling error and remove only the driver's shift from today's record.",
                });
                if (!conf2) return;

                dropLoadoutShift({
                    driverId: driver.id,
                    isMultiDelete: false
                });

                dispatch({
                    type: "REMOVE_DRIVER_ROUTE",
                    payload: driver,
                });
                dispatch({
                    type: "REMOVE_CHANGES",
                    id: driver.id,
                    station: driver.stationId,
                });
                return toast({
                    type: "success",
                    title: "Shift successfully removed.",
                });

                return;

            case "drop-route-to-open":
                const openRouteConfi = await confirm({
                    icon: true,
                    text: "This will treat this Shift as a scheduling error and remove it from today's record but will place the Route Code into Open Routes.",
                });
                if (!openRouteConfi) return;

                if (driver.isNew) {
                    schedulerApi.createOpenRouteTemp({
                        driverId: driver.id,
                        incidentId: null,
                    });
                } else {
                    schedulerApi.createOpenRoute({
                        driverId: driver.id,
                        incidentId: null,
                    });
                }

                dispatch({
                    type: "REMOVE_DRIVER_ROUTE",
                    payload: driver,
                });
                driver.code = driver.routes.join(", ");
                let driverTemp = driver;
                delete driverTemp.driverId;
                dispatch({
                    type: "ADD_OPEN_ROUTE",
                    payload: driver,
                });

                if (driver.routes.length > 0) {
                    dispatch({
                        type: "UPDATE_ROUTE_CODE_BOX",
                        payload: "open-route",
                    });
                }
                return toast({
                    type: "success",
                    title: "Shift and Route successfully moved in open route.",
                });

                return;

            default:
                break;
        }
    };

    const replaceDriverAPI = async (oldRouteId, driverId, routeId) => {
        await API.replaceDriver(api, oldRouteId, driverId, routeId);

        let waveData = await API.fetchWaveData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        );
        dispatch({
            type: "SET_WAVE_DATA",
            payload: waveData,
        });
        dispatch({
            type: "ADD_CHANGE",
        });
    };

    const replaceDriver = async (driver) => {
        let newDriver = await showDriverSelection(
            {
                children: (
                    <>
                        <ThirdTitle>The selected driver will be assigned to:</ThirdTitle>
                        <Spacer bottom={3} />
                        <Label>Shift Type</Label>
                        <DriverShiftType shiftType={driver.shiftType} />
                        {driver.routes.length ? (
                            <>
                                <Spacer bottom={3} />
                                <Label>Routes</Label>
                                <Text>{driver.routes.join(", ")}</Text>
                            </>
                        ) : null}
                        {driver.vehicle.unit ? (
                            <>
                                <Spacer bottom={3} />
                                <Label>Vehicle</Label>
                                <Text>{driver.vehicle.unit}</Text>
                            </>
                        ) : null}
                        {driver.devices.length ? (
                            <>
                                <Spacer bottom={3} />
                                <Label>Device</Label>
                                <Text>{driver.devices.name}</Text>
                            </>
                        ) : null}
                    </>
                ),
            },
            api,
            {
                type: "REPLACE_DRIVER",
                route: driver.id,
                station: state.waveData.station,
                date: state.selectedDate,
            }
        ).then(
            (d) => {
                if (d) {
                    replaceDriverAPI(d.routeId, d.id, driver.id);
                }
            },
            (reject) => () => { }
        );
    };

    const PasswordResetDailog = ({ success, cancel, driver }) => {
        const { register, handleSubmit, errors, reset, getValues } = useForm();
        const { password, driverId } = getValues();
        const [loading, setLoading] = useState(false);
        const [allDrivers, setAllDrivers] = useState([]);
        const [driverEmail, setDriverEmail] = useState(driver.email);
        const [oldPasswords, setOldPasswords] = useState(driver.oldPasswords);
        const errorMessages = {
            password: {
                required: "You need to provide a password",
                pattern: "You password must respect all rules",
            },
        };
        const fetchList = async () => {
            setAllDrivers(await API.getAllDrivers(api, driver.stationId));
        };
        useEffect(() => {
            fetchList();
        }, []);

        const editPasswordReset = async (data) => {
            setLoading(true);
            let responsePassword = await API.passwordReset(api, data.driverId.driverId, data.password);
            if (responsePassword.data.success) {
                let responseMail = await API.passwordResetMail(
                    api,
                    localStorage.getItem("company"),
                    driver.email,
                    driver.name.trim(),
                    data.password
                );
                if (responseMail) {
                    success(true);
                    toast({
                        type: "success",
                        title: "Password successfully changed.",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    success(false);
                    toast({
                        type: "error",
                        title: "Error in sending mail !!!",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                }
            } else {
                success(false);
                let messageTxt = responsePassword.data?.message ? responsePassword.data?.message : "Error in password reset !!!";
                toast({
                    type: "error",
                    title: messageTxt,
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
            }
        };

        return (
            <Modal width="340px" title="Password Reset" visible={true} setVisible={cancel} disabled={loading}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading style={{ width: "40px" }} />
                    </div>
                ) : (
                    <form name="password_reset_form" onSubmit={handleSubmit(editPasswordReset)}>
                        <CustomDropdown
                            ref={register({
                                required: true,
                            })}
                            Required={true}
                            name="driverId"
                            label="Driver"
                                onChange={(d) => {
                                    let currentDriver = allDrivers.filter((driver) => {
                                        return driver.userId.toString() === d.toString()
                                    });    
                                setDriverEmail(currentDriver[0].email)
                            }}
                            defaultValue={driver.userId}
                            options={allDrivers.map((s) => ({ name: s.name.toString(), value: s.userId.toString(), email:s.email }))}
                            valid={errors.driverId && errors.driverId.length}
                            error={errors.driverId && "Please select a driver type"}
                        />
                        <Spacer top={3} />
                        <Password
                            name="password"
                            label="Password"
                            help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    if (value) {
                                        return validatePassword(value, driverEmail, oldPasswords);
                                    }
                                }
                            })}
                            error={errors.password && errors.password.message}
                            valid={!errors.password && password && password.length > 0}
                        />
                        <Spacer top={5} style={{ textAlign: "right" }}>
                            <Button type="primary">Save</Button>
                        </Spacer>
                    </form>
                )}
            </Modal>
        );
    };

    const passwordReset = async (driver) => {
        return dialogPromise((success, cancel) => (
            <PasswordResetDailog success={success} cancel={cancel} driver={driver} />
        ));
    };

    const handleRefusedRescue = async (driver) => {
        let confirmation = await confirm({
            icon: false,
            title: "Confirm Refused Rescue Shift",
            text: `Please confirm your wish to assign a Refused Rescue to "` + driver.name + `"?`,
        });

        if (confirmation) {
            saveRefusedRescueShift({
                routeId: driver.id,
            }).then(
                async (response) => {
                    if (!response) return;
                    driver.isRefusedRescue = true;
                    dispatch({
                        type: "REFRESH",
                    });
                    const paramIncident = {
                        actions: {
                            response: {
                                Incident: {
                                    custom: {
                                        functionName: "createReportIncidentForNewType",
                                        get: "result",
                                        criteria: {
                                            company: localStorage.getItem("company"),
                                            station: driver.stationId,
                                            driver: driver.driverId,
                                            dateTime: moment(driver.shiftDate).utc().format("YYYY-MM-DD HH:mm:ss"),
                                            driverRoute: driver.id,
                                            createdDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                            updatedDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                        },
                                    },
                                },
                            },
                        },
                    };
                    await api.post("/api/lazy/manage/data", paramIncident);
                    return toast({
                        type: "success",
                        title: "Refuse Rescue shift status updated successfully.",
                    });
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        }
    };

    const callDriver = (driver) => {
        if (driver.devices.length > 1) {
            // open call to modal
        }

        window.location.href = `tel: ${driver.devices[0].number}`;
    };

    const handleEditTrainDutyShift = (driver) => {
        let shiftTypes = state.waveData.shiftTypes;
        if (driver.category == 6) {
            shiftTypes = shiftTypes.filter((s) => s.category != 5);
        }
        if (driver.category == 5) {
            shiftTypes = shiftTypes.filter((s) => s.category != 6);
        }
        dialog({
            align: {
                content: "left",
                footer: "right",
            },
            modal: {
                removeHeader: false,
                title: driver.category == 6 ? "Edit Light Duty Shift" : "Edit Train Shift",
            },
            text: `Replace shift of ${driver.name}`,
            fields: [
                {
                    field: Spacer,
                    props: {
                        top: 3,
                    },
                },
                {
                    field: CustomDropdown,
                    props: {
                        name: "shiftType",
                        label: "Shift Type",
                        type: "color",
                        options: shiftTypes.map((s) => ({
                            name: s.name,
                            value: s.id,
                            color: s.color,
                        })),
                        defaultValue: driver.shiftType.id,
                        Required: true,
                        visibleOptionsQty: 6,
                    },
                },
            ],
            buttons: [
                {
                    text: "Cancel",
                    type: "cancel",
                    size: "default",
                    callback: () => false,
                },
                {
                    text: "Save Shift Type",
                    type: "primary",
                    size: "default",
                },
            ],
        }).then(
            async ({ data: form }) => {
                if (!form) return;

                let newType = state.waveData.shiftTypes.filter((s) => s.id.toString() === form.shiftType)[0];

                if (driver.shiftType.id == newType.id) return;

                let titleShiftType = driver.category === 5 ? "Add Train" : "Add Light Duty";
                const traineeDriver = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "getTraineeAndLightDutyDriver",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        routeId: driver.id,
                                    },
                                },
                            },
                        },
                    },
                };
                const responsetraineeDriver = await api.post("/api/lazy/manage/data", traineeDriver);
                let traineeDriverName = "";
                responsetraineeDriver.data.data.result.map((driver, index) => {
                    traineeDriverName += driver.driverName + ",";
                });
                const confirmationTrainee = await confirm(
                    'WARNING: By changing the Shift Type from the "' +
                    titleShiftType +
                    '" to "' +
                    newType.name +
                    '" type, you will delete any training assignments/data from "' +
                    traineeDriverName.slice(0, traineeDriverName.lastIndexOf(",")) +
                    '". Do you wish to continue?'
                );
                if (!confirmationTrainee) {
                    return true;
                }
                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: driver.driverId,
                                        shiftId: form.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type."
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                                responseDriverSkill.data.data.result.driverName +
                                '" does not have the required "' +
                                responseDriverSkill.data.data.result.shiftSkillName +
                                '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }

                let isDST = moment(driver.date).isDST();
                const startTime = isDST
                    ? asDatabaseTime(newType.startTimeOnDst.timestamp * 1000)
                    : asDatabaseTime(newType.startTime.timestamp * 1000);
                const endTime = isDST
                    ? asDatabaseTime(newType.endTimeOnDst.timestamp * 1000)
                    : asDatabaseTime(newType.endTime.timestamp * 1000);

                const driverRouteParam = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "updateDriverRouteByShift",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        routeId: driver.id,
                                        shiftId: newType.id,
                                        isEditTrain: driver.category === 5,
                                        isEditDuty: driver.category === 6,
                                        shiftInvoiceTypeId: newType.invoiceType ? newType.invoiceType.id : null,
                                        startTime: startTime,
                                        endTime: endTime,
                                        timezoneOffset: new Date().getTimezoneOffset(),
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverRoute = await api.post("/api/lazy/manage/data", driverRouteParam);
                toast({
                    type: "success",
                    title: "Shift type updated",
                });
                driver.isAddTrain = false;
                driver.isLightDuty = false;
                dispatch({
                    type: "SET_SHIFT_TYPE",
                    payload: {
                        driverRoute: driver.id,
                        shiftType: newType,
                    },
                });
            },
            (error) => console.log(error)
        );
    };

    const handleEditShitType = async (driver) => {

        let shiftTypes = state.waveData.shiftTypes;
        const text = `Replace shift of ${driver.name}`;

        dialog({
            defaultValues: {
                isVoluntary: driver.isVoluntary,
                routeStatus: driver.routeStatus,
                selectedShiftType: driver.shiftType,
                backupStatus: driver.backupStatus,
                onCall: driver.onCall
            },
            align: {
                content: "left",
                footer: "right",
            },
            modal: {
                removeHeader: false,
                title: "Edit Shift Type",
            },
            text: text,
            fields: [
                {
                    field: Spacer,
                    props: {
                        top: 3,
                    },
                },
                {
                    field: ComboBox,
                    props: {
                        input: false,
                        name: "shiftType",
                        label: "Shift Type",
                        type: "shiftIcon",
                        options: shiftTypes.map((s) => ({
                            name: s.name,
                            value: s.id,
                            color: s.color,
                            category: s.category
                        })),
                        defaultValue: driver.shiftType.id,
                        Required: true,
                        visibleOptionsQty: 6,
                        handleShiftChange: (value, setRouteStatus, setBackupStatus) => {
                            const shift = shiftTypes.find(s => s.id == value);
                            if (shift && shift.category == 8) {
                                setRouteStatus(true);
                            } else {
                                setRouteStatus(false);
                            }
                            if (shift.category == 2 && shift.isOT == true) {
                                setBackupStatus(true);
                                setRouteStatus(false);
                            } else {
                                setBackupStatus(false);
                            }
                        }
                    },
                },
            ],
            buttons: [
                {
                    text: "Cancel",
                    type: "cancel",
                    size: "default",
                    callback: () => false,
                },
                {
                    text: "Save Shift Type",
                    type: "primary",
                    size: "default",
                },
            ],
        }).then(
            async ({ data: form }) => {
                if (!form) return;

                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: driver.driverId,
                                        shiftId: form.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                                responseDriverSkill.data.data.result.driverName +
                                '" does not have the required "' +
                                responseDriverSkill.data.data.result.shiftSkillName +
                                '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
                let newType = state.waveData.shiftTypes.filter((s) => s.id.toString() === form.shiftType)[0];
                if (driver.shiftType.id === newType.id && newType.category != 8 && (newType.category != 2 && (newType.category == 2 && newType.isOT == false))) return;

                const routeStatus = newType.category == 8 ? { routeStatus: form.routeStatus, isVoluntary: form.isVoluntary } : { routeStatus: 0, isVoluntary: false };
                const backupStatus = newType.category == 2 && newType.isOT == true ? { backupStatus: form.backupStatus, onCall: form.onCall } : { backupStatus: 0, onCall: false };

                dispatch({
                    type: "SET_SHIFT_TYPE",
                    payload: {
                        driverRoute: driver.id,
                        shiftType: newType,
                    },
                });

                let isDST = moment(driver.date).isDST();
                const startTime = isDST
                    ? asDatabaseTime(newType.startTimeOnDst.timestamp * 1000)
                    : asDatabaseTime(newType.startTime.timestamp * 1000);
                const endTime = isDST
                    ? asDatabaseTime(newType.endTimeOnDst.timestamp * 1000)
                    : asDatabaseTime(newType.endTime.timestamp * 1000);


                if (driver.isNew) {

                    tempEditShift({
                        id: driver.id,
                        newRecord: {
                            id: driver.tempDriverRouteId,
                            isNew: true,
                            driverId: driver.driverId,
                            shiftDate: driver.shiftDate,
                            shiftId: form.shiftType,
                            ...routeStatus,
                            ...backupStatus
                        },

                    }).then(
                        (response) => {
                            toast({
                                type: "success",
                                title: "Shift type updated",
                            });

                            dispatch({
                                type: "UPDATE_ROUTE_STATUS",
                                payload: {
                                    driverRoute: driver.id,
                                    ...routeStatus,
                                    ...backupStatus
                                },
                            });
                        },
                        (error) => {
                            if (error.response)
                                toast({
                                    type: "Error",
                                    content: error.response.data.message,
                                });

                            dispatch({
                                type: "SET_SHIFT_TYPE",
                                payload: {
                                    driverRoute: driver.id,
                                    shiftType: driver.shiftType,
                                },
                            });
                        }
                    );
                } else {
                    editShift({
                        id: driver.id,
                        updateRecord: {
                            shiftType: newType.id,
                            shiftName: newType.name,
                            shiftColor: newType.color,
                            shiftNote: newType.note,
                            shiftInvoiceType: newType.invoiceType ? newType.invoiceType.id : null,
                            shiftCategory: newType.category,
                            shiftTextColor: newType.textColor,
                            startTime: startTime,
                            endTime: endTime,
                            ...routeStatus,
                            ...backupStatus
                        },
                    }).then(
                        (response) => {
                            let params = {
                                actions: {
                                    response: {
                                        Shift: {
                                            custom: {
                                                functionName: "addShiftChangeEvent",
                                                get: "shift",
                                                criteria: {
                                                    driverRouteId: driver.id,
                                                    shiftData: response.data.updates.u1.fields,
                                                },
                                            },
                                        },
                                    },
                                },
                            };
                            api.post("/api/lazy/manage/data", params);
                            toast({
                                type: "success",
                                title: "Shift type updated",
                            });

                            dispatch({
                                type: "UPDATE_ROUTE_STATUS",
                                payload: {
                                    driverRoute: driver.id,
                                    ...routeStatus,
                                    ...backupStatus
                                },
                            });
                        },
                        (error) => {
                            if (error.response)
                                toast({
                                    type: "Error",
                                    content: error.response.data.message,
                                });

                            dispatch({
                                type: "SET_SHIFT_TYPE",
                                payload: {
                                    driverRoute: driver.id,
                                    shiftType: driver.shiftType,
                                },
                            });
                        }
                    );
                }
            },
            (error) => console.log(error)
        );
    };

    const [expand, setExpand] = useState(false);

    let spanColor = false;
    if (d.workHours > 40 || d.estimatedWorkHours > 40) {
        spanColor = true;
    }

    const setBag = async (id, driver) => {
        let checkboxObj = document.querySelector("[id='dri_" + id + "']");
        let hasBag = false;
        if (checkboxObj && checkboxObj.checked) {
            hasBag = true;
        } else {
            hasBag = false;
        }
        driver.hasBag = hasBag;
        dispatch({
            type: "SET_BAG",
            payload: {
                driver: driver
            },
        });
        let params = {
            actions: {
                update: {
                    DriverRoute: {
                        DriverRoute_1: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                hasBag: hasBag,
                            },
                        },
                    },
                },
            },
        };
        await api.post("/api/lazy/manage/data", params);
    };

    const TimeLineDailog = ({ success, cancel, driverRouteId, driverId }) => {
        const [loading, setLoading] = useState(false);
        // const [allDrivers, setAllDrivers] = useState([]);
        const [driverRoute, setDriverRoute] = useState([]);
        // const [driver, setDriver] = useState({ profileImage: null, position: "Delivery Associate", fullName: "" });

        const fetchTimeLine = async () => {
            setLoading(true);
            let data = await fetchPopupTimelineData(api, driverId, driverRouteId, driver.shiftType.shiftDate);
            setDriverRoute(data.routes.driverRoutes.filter((i) => i.line.some((e) => e.createdAt !== null)));
            setLoading(false);
        };

        useEffect(() => {
            fetchTimeLine();
        }, []);

        return (
            <Modal width="480px" title="Timeline" visible={true} setVisible={cancel} disabled={loading}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading style={{ width: "40px" }} />
                    </div>
                ) : (
                    <>
                        <SimpleScroll>
                            {driverRoute.length > 0 ? (
                                <SmartTimeLine data={driverRoute} />
                            ) : (
                                <div style={{ textAlign: "center", fontSize: "16px", color: "#707070" }}>
                                    No timeline found for this driver!!!
                                </div>
                            )}
                        </SimpleScroll>
                    </>
                )}
            </Modal>
        );
    };

    const Timeline = async (driverRouteId, driverId) => {
        return dialogPromise((success, cancel) => (
            <TimeLineDailog success={success} cancel={cancel} driverRouteId={driverRouteId} driverId={driverId} />
        ));
    };

    const SmartTimeLine = ({ data }) => {
        return data.map((item, index) => (
            <div key={index}>
                <DateContent>{moment(item.date.substr(0, 10)).format("ddd, MMM Do")}</DateContent>
                <Spacer bottom={2} />
                <RowTimeline data={item} date={item.date} />
                <Spacer bottom={5} />
            </div>
        ));
    };

    const handleRowClick = (e, driver) => {
        if (e.shiftKey) {
            setSelectedRows((prevSelectedRows) => {
                const isSelected = prevSelectedRows.some((row) => row.id === driver.id);
                if (isSelected) {
                    return prevSelectedRows.filter((row) => row.id !== driver.id);
                } else {
                    return [...prevSelectedRows, driver];
                }
            });
        } else {
            setSelectedRows([]);
        }
    };

    const handleDriver = (e) => {
        if (e.shiftKey) {
            return;
        }
        setExpand(true);
    };

    const handleShiftType = (e) => {
        if (e.shiftKey) {
            return;
        }
        if (
            driver.isRestore != true &&
            driver.rescue === false &&
            (d.shiftType.category <= 3 || [7, 8].includes(d.shiftType.category))
        ) {
            handleEditShitType(d, false);
        }
        else if ([5, 6].includes(d.shiftType.category)) handleEditTrainDutyShift(d);
    }

    const handleStatus = (e) => {
        if (e.shiftKey) {
            return;
        } else {
            Timeline(d.id, d.driverId)
        }
    }

    return (
        <>
            <li
                onClick={(e) => handleRowClick(e, d)}
                style={{
                    background: selectedRows.some(row => row.id === d.id) ? 'rgba(157, 221, 244, 0.25)' : 'white',
                    userSelect: 'none'
                }}
            >
                <DriverRowStyle id={`driver-row-${d.id}`} style={{ position: "relative" }}>
                    {d.isLoading && d.isLoading == true && <Icon.DspwNetwork size="28px" color={Theme.colors.primary} style={{ position: 'absolute', transform: "translate(-50%, -50%)", left: '50%', top: '50%' }} />}
                    <CompactTableData
                        width={columnWidths.driver}
                        onClick={handleDriver}
                        isLoading={d.isLoading}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <Driver
                            name={d.name}
                            jobTitle={
                                <>
                                    <span style={{ display: "flex", alignItems: "center", marginTop: "" }}>
                                        <Icon.Clock size="12px" color={Theme.colors.neutrals.medium} />
                                        <span style={spanColor ? { color: Theme.colors.error.text } : {}}>
                                            {d.workHours}
                                        </span>
                                        /{" "}
                                        <span style={spanColor ? { color: Theme.colors.error.text } : {}}>
                                            {d.estimatedWorkHours}
                                        </span>
                                    </span>
                                    <span style={{ color: Theme.colors.error.text, fontSize: "10px" }}>
                                        {d.adpStatus && d.adpStatus == true ? "Terminated Driver in ADP" : ""}
                                    </span>
                                </>
                            }
                            size="20px"
                            img={d.img}
                            isNew={d.isNew}
                        />
                    </CompactTableData>
                    {/* here */}
                    <CompactTableData
                        width={columnWidths.shiftType}
                        onClick={handleShiftType}
                        isLoading={d.isLoading}
                        style={{
                            wordBreak: "normal",
                            cursor:
                                driver.rescue === false &&
                                    (d.shiftType.category <= 3 || [5, 6, 7, 8].includes(d.shiftType.category))
                                    ? "pointer"
                                    : "default",
                        }}
                    >
                        <DriverShiftType
                            shiftType={d.shiftType}
                            backup={d.backup}
                            rescue={d.rescue}
                            training={d.training}
                            secondShift={d.secondShift}
                            startTime={d.startTime}
                            sentToHome={d.isSentHome}
                            refuseRescue={d.isRefusedRescue}
                            incident={d.incident ? d.incident.type : false}
                            hasCallOutOrNcns={d.hasCallOutOrNcns}
                            isTimeOff={d.isTimeOff}
                            hasAnySinglePunch={d.hasAnySinglePunch}
                            isPerformanceRelated={d.isPerformanceRelated}
                        />
                        {driver.isRestore != true && driver.rescue === false &&
                            (d.shiftType.category <= 3 || [5, 6, 7, 8].includes(d.shiftType.category)) && (
                                <Spacer inline left={3}>
                                    <Icon.Edit
                                        size="20px"
                                        style={{ position: "absolute", top: "25%", right: 0 }}
                                        color={Theme.colors.info.border}
                                    />
                                </Spacer>
                            )}
                    </CompactTableData>
                    {/*
                    <CompactTableData width='8.44%'>
                        {d.startTime}
                    </CompactTableData>
                    */}
                    <CompactTableData width={columnWidths.status} style={{ wordBreak: "normal", cursor: "pointer" }} isLoading={d.isLoading} onClick={() => Timeline(d.id, d.driverId)}>
                        <div style={{ wordBreak: "normal", cursor: "pointer !important" }}>
                            <Status state={state} d={d} />
                        </div>
                    </CompactTableData>
                    <CompactTableData width={columnWidths.routes} isLoading={d.isLoading}>
                        {driver.isRestore != true && driver.rescue === false && driver.shiftType.category != 8 ? (
                            <DriverRouteController
                                driver={{
                                    ...d,
                                    routeCode: d.routes,
                                    notEditable: driver.rescue === false ? true : false,
                                    date: state.selectedDate,
                                    isOpenShift: false,
                                    viewType: "loadOut",
                                }}
                                addChange={async (shift, newShift) => {
                                    let newDriverCheck = [];
                                    if (newShift) {
                                        newDriverCheck = state.waveData.drivers.filter(
                                            (dr) => dr.id == newShift.routeId
                                        );
                                    }
                                    if (newDriverCheck.length == 0) {

                                        dispatch({
                                            type: "UPDATE_IS_LOADING",
                                            payload: { id: driver.id },
                                        });

                                        let waveData = await API.fetchWaveData(
                                            {
                                                station: state.waveData.station,
                                                date: state.selectedDate,
                                            },
                                            api
                                        );
                                        dispatch({
                                            type: "SET_WAVE_DATA",
                                            payload: waveData,
                                        });
                                        let week = moment(state.selectedDate).isoWeek();
                                        if (moment(state.selectedDate).day() === 0) {
                                            week = moment(state.selectedDate).add(1, "days").isoWeek();
                                        }
                                        let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
                                        if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
                                            year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
                                        }

                                        let UnpublishResponse = await API.fetchPendingChanges(
                                            {
                                                station: state.waveData.station,
                                                wknumber: week,
                                                unpublishDataDate: state.selectedDate,
                                                year: year,
                                                loadOut: true,
                                                type: "loadOut",
                                            },
                                            api
                                        );
                                        let chnagesId = [];
                                        UnpublishResponse.forEach(function (change) {
                                            chnagesId.push(change.driverRouteId);
                                        });

                                        dispatch({
                                            type: "SET_PUBLISH_COUNT",
                                            ids: chnagesId,
                                            station: state.waveData.station,
                                            api: true,
                                        });
                                    } else {
                                        dispatch({
                                            type: "UPDATE_ROUTE_CODES",
                                            payload: { shift, newShift },
                                        });

                                        if (newShift) highlightDriver(newShift.routeId);

                                        highlightDriver(d.id);
                                    }
                                }}
                            />
                        ) : (
                            <Text style={{ color: "#0071BC", textDecorationLine: "underline" }}>
                                {d.routes.join(", ") || (
                                    <Action disabled={"disabled"} compact={true}>
                                        <Icon.Plus size="20px" />
                                    </Action>
                                )}
                            </Text>
                        )}
                    </CompactTableData>
                    <CompactTableData width={columnWidths.vehicle} isLoading={d.isLoading}>
                        <DriverVehicleController driver={d} addChange={addChange} />
                    </CompactTableData>
                    <CompactTableData width={columnWidths.devices} isLoading={d.isLoading}>
                        <DriverDeviceController driver={d} addChange={addChange} breakLine={true} />
                    </CompactTableData>

                    <CompactTableData width={columnWidths.stagingLocation} isLoading={d.isLoading}>
                        {driver.rescue == false && <StagingLocationController driver={d} addChange={addChange} />}
                    </CompactTableData>
                    <CompactTableData width={columnWidths.bag} key={"dri_" + d.id} isLoading={d.isLoading}>
                        {(driver.isRestore != true && driver.shiftType.category != 8) ? (
                            <CheckBox
                                name={"dri_" + d.id}
                                id={"dri_" + d.id}
                                title={""}
                                defaultValues={[d.hasBag]}
                                onClick={() => {
                                    setBag(d.id.toString(), d);
                                }}
                                options={[{ value: true, label: "" }]}
                            />
                        ) : (
                            <CheckBox
                                name={"dri_" + d.id}
                                id={"dri_" + d.id}
                                title={""}
                                defaultValues={[d.hasBag]}
                                onClick={() => {
                                    setBag(d.id.toString(), d);
                                }}
                                options={[{ value: true, label: "" }]}
                                disabled={"disabled"}
                            />
                        )}
                    </CompactTableData>
                    <CompactTableData width={columnWidths.actions} isLoading={d.isLoading}>
                        {(driver.isRestore != true && driver.shiftType.category != 8) && (
                            <>
                                <Action
                                    type="cancel"
                                    compact={true}
                                    onClick={() => {
                                        document.getElementById(`driver-${d.id}-dspactions`).click();
                                    }}
                                >
                                    <Icon.DspwNetwork size="28px" color={Theme.colors.primary} />
                                </Action>
                                <Dropdown
                                    className="just-options"
                                    id={`driver-${d.id}-dspactions`}
                                    options={getDspActions(d)}
                                    onSelect={(option) => handleDspActions(d, option)}
                                    visibleOptionsQty={100}
                                />
                            </>
                        )}
                        {driver.isRestore != true && (<><Action
                            type="cancel"
                            compact={true}
                            onClick={() => {
                                document.getElementById(`driver-${d.id}-dspactions1`).click();
                            }}
                        >
                            <Icon.Trash size="28px" color={Theme.colors.error.text} />
                        </Action>
                            <Dropdown
                                className="just-options"
                                id={`driver-${d.id}-dspactions1`}
                                options={getTrashActions(d)}
                                onSelect={(option) => handleDropRouteActions(d, option)}
                                visibleOptionsQty={100}
                            /></>)}

                        <Action type="cancel" compact={true} disabled={(driver.isRestore != true ? true : !d.devices.length)} onClick={() => callDriver(d)}>
                            <Icon.Phone size="28px" color={Theme.colors.info.border} />
                        </Action>
                    </CompactTableData>
                </DriverRowStyle>
            </li>
            {expand && (
                <Details
                    profileId={d.driverId}
                    detailVisible={expand}
                    toggleDetails={() => {
                        setExpand(false);
                    }}
                    modalHandler={() => { }}
                    isEdit="false"
                />
            )}
        </>
    );
};

const Status = ({ state, d }) => {
    let dr = state.driverStatus[String(d.driverId)];
    return <>{(dr.isSentHome && (
        <StatusData ncns={true}>
            Send Home
            <span>Since {dr.dateTimeEndedTimeZoneConversion}</span>
        </StatusData>
    )) ||
        (dr.incident && dr.incident.type === "NCNS" && <StatusData ncns={true}>NCNS</StatusData>) ||
        (dr.incident && dr.incident.type === "Call Out" && (
            <StatusData callin={true}>Call Out</StatusData>
        )) ||
        (dr.incident && dr.incident.type === "Late" && <StatusData late={true}>Late</StatusData>) ||
        (dr.incident && dr.incident.type === "Accident/Injury" && (
            <StatusData accidentinjury={true}>Accident/Injury</StatusData>
        )) ||
        // (dr.punchedIn === false && dr.clockedOut === false && dr.onBreak === false && dr.breakPunchOut === false &&(
        //     <StatusData noData={true}>No data</StatusData>
        // )) ||
        (dr.punchedIn && dr.clockedOut === false && dr.onBreak && dr.breakPunchOut === false && (
            <StatusData onBreak={true}>
                Out to Lunch
                <span>
                    Since{" "}
                    {dr.isBackup === true && dr.dateTimeEnded
                        ? dr.dateTimeEndedTimeZoneConversion
                        : dr.onBreak}
                </span>
            </StatusData>
        )) ||
        (dr.punchedIn && dr.clockedOut === false && dr.onBreak && dr.breakPunchOut && (
            <StatusData breakPunchOut={true}>
                In from Lunch
                <span>{dr.breakPunchOut}</span>
            </StatusData>
        )) ||
        (dr.clockedOut && (
            <StatusData clockedOut={true}>
                Punched Out
                <span>{dr.clockedOut}</span>
            </StatusData>
        )) ||
        (dr.returned && <StatusData returned={true}>Returned</StatusData>) ||
        (dr.punchedIn === false && dr.es_name != "Exempt" && (
            <StatusData notPunchedIn={true}>Not Punched In </StatusData>
        )) ||
        (dr.punchedIn === false && dr.es_name == "Exempt" && <StatusData>-</StatusData>) ||
        (dr.insideStation && (
            <StatusData insideStation={true}>
                Inside Station
                <span>
                    {dr.insideStation} - <Countdown until={dr.mustLeaveUntil} />
                </span>
            </StatusData>
        )) ||
        (dr.onRoute && <StatusData onroute={true}>On Route</StatusData>) ||
        (dr.punchedIn !== false &&
            ((dr.isLoadoutCompleted && (dr.isLoadoutCompleted == true || dr.isLoadoutCompleted == "LOAD_OUT_COMPLETED")) ? (
                <StatusData readyToStage={true}>Ready to Stage</StatusData>
            ) : (
                <StatusData punchedIn={true}>
                    Punched In
                    <span>{dr.punchedIn}</span>
                </StatusData>
            )))
    }</>;
};



const AddDriverRow = () => {
    const [state, dispatch] = useContext(LoadOutStore);
    const { api } = useAuth();
    const [newDriver, setNewDriver] = useState(null);
    const quickShiftType = state.waveData.balanceGroups.filter((s) => s.id === state.quickShiftType).pop();

    const { register, getValues } = useForm({
        mode: "onBlur",
    });

    const addDriver = async (driver) => {
        setNewDriver(null);
        driver.date = state.selectedDate;
        await API.addDriver(api, driver);

        let waveData = await API.fetchWaveData(
            {
                station: state.waveData.station,
                date: state.selectedDate,
            },
            api
        );
        dispatch({
            type: "SET_WAVE_DATA",
            payload: waveData,
        });
        dispatch({
            type: "ADD_CHANGE",
        });
    };

    const handleUpdateChanges = async (station) => {
        let week = moment(state.selectedDate).isoWeek();
        if (moment(state.selectedDate).day() === 0) {
            week = moment(state.selectedDate).add(1, "days").isoWeek();
        }
        let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
        if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
            year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
        }

        await API.fetchPendingChanges(
            {
                station: station,
                wknumber: week,
                unpublishDataDate: state.selectedDate,
                year: year,
                loadOut: true,
                type: "loadOut",
            },
            api
        ).then((UnpublishResponse) => {
            let chnagesId = [];
            UnpublishResponse.forEach(function (change) {
                chnagesId.push(change.driverRouteId);
            });

            dispatch({
                type: "SET_PUBLISH_COUNT",
                ids: chnagesId,
                station: state.waveData.station,
                api: true,
            });
        });
    };

    const handleAddDriver = async () => {
        if (!state.waveData.station) return;

        let tempStartTime = "";
        let tempShiftDriverCount = 0;
        state.waveData.balanceGroups.map((shift) => {
            if (tempShiftDriverCount < parseInt(shift.drivers)) {
                tempShiftDriverCount = shift.drivers;
                tempStartTime = '';//shift.pickerTime;
            }
        });

        showDriverSelection({}, api, {
            type: "SCHEDULER_ADD_ROUTE",
            station: state.waveData.station,
            // quickShiftType: state.quickShiftType,
            date: state.selectedDate,
            startTime: API.getUtcTime(tempStartTime),
            quickShiftType: quickShiftType,
        }).then(async (data) => {
            if (!data) return;

            const driverSkillParam = {
                actions: {
                    response: {
                        Driver: {
                            custom: {
                                functionName: "checkDriverAndShiftSkillMatch",
                                get: "result",
                                excludes: [],
                                criteria: {
                                    driverId: data.driver,
                                    shiftId: data.shift,
                                },
                            },
                        },
                    },
                },
            };
            const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
            if (!responseDriverSkill.data.data.result.result) {
                if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                    const confirmation = await confirm(
                        'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                    );
                    if (!confirmation) {
                        return true;
                    }
                }
            }
            dispatch({
                type: "ADD_CHANGE",
            });
            data.driver = data.driver;
            data.date = state.selectedDate;
            data.station = state.waveData.station;

            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "checkSequenceDriverRoute",
                                get: "checkSequenceDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: "",
                                    driverId: data.driver,
                                    newDate: data.date,
                                },
                            },
                        },
                    },
                },
            };
            const response = await api.post("/api/lazy/manage/data", params1);
            if (response.data.data.checkSequenceDriverRoute.status) {
                const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
                if (!wc) return undefined;

                const params1 = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "createEventForConsicutiveShift",
                                    get: "createEventForConsicutiveShift",
                                    excludes: [],
                                    criteria: {
                                        driverId: data.driver,
                                        message: response.data.data.checkSequenceDriverRoute.message.bot,
                                    },
                                },
                            },
                        },
                    },
                };
                const response2 = await api.post("/api/lazy/manage/data", params1);
            }
            API.addDriver(api, data)
                .then((response) =>
                    API.fetchWaveData(
                        {
                            station: state.waveData.station,
                            date: state.selectedDate,
                        },
                        api
                    )
                )
                .then((waveData) => {
                    dispatch({
                        type: "SET_WAVE_DATA",
                        payload: waveData,
                    });
                    handleUpdateChanges(state.waveData.station);
                });
        });
    };

    return (
        <li style={{ position: "relative" }}>
            <DriverRowStyle style={{ opacity: 0.5 }}>
                {/*<CompactTableData width={columnWidths.move}>*/}
                {/*    <Icon.Move size="20px" />*/}
                {/*</CompactTableData>*/}
                <CompactTableData width={columnWidths.driver}>
                    <Driver
                        name={<SSkeletonPulse width={100} height={16} animation={1} />}
                        jobTitle={<SSkeletonPulse width={65} height={12} animation={1} />}
                        img={null}
                    />
                </CompactTableData>
                <CompactTableData width={columnWidths.shiftType}>
                    <DriverShiftType
                        shiftType={{
                            color: Theme.colors.info.shadow,
                            name: <SSkeletonPulse width={100} height={16} animation={1} />,
                        }}
                    />
                </CompactTableData>
                {/*
                <CompactTableData width='8.44%'>
                    <SSkeletonPulse width={50} height={16} animation={1}/>
                </CompactTableData>
                */}
                <CompactTableData width={columnWidths.status}>
                    <StatusData>
                        <SSkeletonPulse width={100} height={16} animation={1} />
                    </StatusData>
                </CompactTableData>
                <CompactTableData width={columnWidths.routes}>
                    <Action disabled={0} compact={true}>
                        <Icon.Plus size="20px" />
                    </Action>
                </CompactTableData>
                <CompactTableData width={columnWidths.vehicle}>
                    <Action disabled={0} compact={true}>
                        <Icon.Plus size="20px" />
                    </Action>
                </CompactTableData>
                <CompactTableData width={columnWidths.devices}>
                    <Action disabled={0} compact={true}>
                        <Icon.Plus size="20px" />
                    </Action>
                </CompactTableData>
                <CompactTableData width={columnWidths.stagingLocation}>
                    <Action disabled={0} compact={true}>
                        <Icon.Plus size="20px" />
                    </Action>
                </CompactTableData>
                <CompactTableData width={columnWidths.bag}>
                    <SSkeletonPulse width={60} height={16} animation={1} />
                </CompactTableData>
                <CompactTableData width={columnWidths.actions}>
                    <Action type="cancel" compact={true} disabled={true}>
                        <Icon.DspwNetwork size="28px" color={Theme.colors.primary} />
                    </Action>
                    <Action type="cancel" compact={true} disabled={true}>
                        <Icon.Trash size="28px" color={Theme.colors.info.border} />
                    </Action>
                    <Action type="cancel" compact={true} disabled={true}>
                        <Icon.Phone size="28px" color={Theme.colors.info.border} />
                    </Action>
                    {/*<Action type="cancel" compact={true} disabled={true}>*/}
                    {/*    <Icon.Expand size="28px" color={Theme.colors.info.border} />*/}
                    {/*</Action>*/}
                </CompactTableData>
            </DriverRowStyle>
            <CustomButton
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
                onClick={handleAddDriver}
            >
                Add Driver
            </CustomButton>
            {/*<Button*/}
            {/*    style={{*/}
            {/*        position: "absolute",*/}
            {/*        top: "50%",*/}
            {/*        left: "50%",*/}
            {/*        transform: "translate(-50%, -50%)",*/}
            {/*    }}*/}
            {/*    onClick={async () => {*/}
            {/*        let tempStartTime = "";*/}
            {/*        let tempShiftDriverCount = 0;*/}
            {/*        await state.waveData.balanceGroups.map(shift => {*/}
            {/*            if (tempShiftDriverCount < parseInt(shift.drivers)) {*/}
            {/*                tempShiftDriverCount = shift.drivers;*/}
            {/*                tempStartTime = shift.pickerTime;*/}
            {/*            }*/}
            {/*        });*/}

            {/*        showDriverSelection(*/}
            {/*            {*/}
            {/*                children: quickShiftType && <></>,*/}
            {/*            },*/}
            {/*            api,*/}
            {/*            {*/}
            {/*                type: "SCHEDULER_ADD_DRIVER",*/}
            {/*                station: state.waveData.station,*/}
            {/*                startTime: API.getUtcTime(tempStartTime),*/}
            {/*                quickShiftType: quickShiftType,*/}
            {/*            }*/}
            {/*        ).then(*/}
            {/*            driver => addDriver(driver),*/}
            {/*            reject => () => {}*/}
            {/*        );*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Add Driver*/}
            {/*</Button>*/}
            {/*{newDriver && (*/}
            {/*    <Modal*/}
            {/*        title={`Select ${newDriver.name} shift type`}*/}
            {/*        visible={newDriver}*/}
            {/*        setVisible={() => setNewDriver(null)}*/}
            {/*        width="340px"*/}
            {/*    >*/}
            {/*        <Label>Driver</Label>*/}
            {/*        <Driver*/}
            {/*            name={newDriver.name}*/}
            {/*            jobTitle={newDriver.title}*/}
            {/*            img={newDriver.img}*/}
            {/*        />*/}
            {/*        <Spacer bottom={3} />*/}
            {/*        <CustomDropdown*/}
            {/*            ref={register}*/}
            {/*            name="shiftType"*/}
            {/*            label="Shift Type"*/}
            {/*            type="color"*/}
            {/*            options={state.waveData.balanceGroups.map((s) => ({*/}
            {/*                name: s.name,*/}
            {/*                value: s.id,*/}
            {/*                color: s.color,*/}
            {/*            }))}*/}
            {/*        />*/}
            {/*        <Spacer top={5} style={{ textAlign: "right" }}>*/}
            {/*            <Button*/}
            {/*                type="primary"*/}
            {/*                onClick={() =>*/}
            {/*                    addDriver(*/}
            {/*                        newDriver,*/}
            {/*                        state.waveData.balanceGroups.find(*/}
            {/*                            (s) => s.id == getValues().shiftType*/}
            {/*                        )*/}
            {/*                    )*/}
            {/*                }*/}
            {/*            >*/}
            {/*                Add Driver*/}
            {/*            </Button>*/}
            {/*        </Spacer>*/}
            {/*    </Modal>*/}
            {/*)}*/}
        </li>
    );
};

const DriversList = () => {
    const [state, dispatch] = useContext(LoadOutStore);
    const { api } = useAuth();
    const [selectedRows, setSelectedRows] = useState([]);
    const {
        getShiftOptions,
        ...schedulerApi
    } = useSchedulerApi();

    const setSortBy = (field) => {
        dispatch({
            type: "SET_SORT_BY",
            payload: {
                field: field,
            },
        });
    };
    let driverList = state ? state.drivers : [];

    if (state.quickShiftType === "Assigned") {
        driverList = driverList.filter((d) => (d.routes.length > 0 ? true : false));
    }
    if (state.quickShiftType === "Unassigned") {
        driverList = driverList.filter((d) => (d.routes.length === 0 ? true : false));
    }
    //Filter for not punchedIn
    if (state.filterNotPunchIn == false) {
        driverList = driverList.filter(
            (driver) =>
                driver.punchedIn == false &&
                driver.rescue == false &&
                driver.es_name != "Exempt" &&
                (driver.incident == null || (driver.incident.type != "Call Out" && driver.incident.type != "NCNS"))
        );
    }
    //Filter for not departed
    if (state.filterNotDeparted == true) {
        driverList = driverList.filter((driver) => driver.insideStation != false && driver.kickoffLog != null);
    }

    let headerArray = [
        // { width: `calc(${columnWidths.move} + 36px);`, label: "" },
        { width: `calc(36px);`, label: "" },
        {
            width: `calc(${columnWidths.driver} - 36px);`,
            label: "Driver",
            sortBy: "name",
        },
        { width: columnWidths.shiftType, label: "Shift Type", sortBy: "shiftType" },
        // { width: '8.45%', label: 'Start Time', sortBy: 'startTime', },
        { width: columnWidths.status, label: "Status", sortBy: "status" },
        { width: columnWidths.routes, label: "Routes", sortBy: "routes" },
        {
            width: columnWidths.vehicle,
            label: "Vehicle",
            sortBy: "vehicle",
        },
        {
            width: columnWidths.devices,
            label: "Devices",
            sortBy: "devices",
        },
        {
            width: columnWidths.stagingLocation,
            label: "Staging Location",
            sortBy: "stagingLocation",
        },
    ];

    if (
        [
            "ROLE_STATION_MANAGER",
            "ROLE_OWNER",
            "ROLE_ASSISTANT_STATION_MANAGER",
            "ROLE_LEAD_DRIVER",
            "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        ].includes(localStorage.getItem("currentUserRole"))
    ) {
        headerArray.push({ width: columnWidths.bag, label: "Bag" });
    }
    headerArray.push({ width: columnWidths.actions, label: "" });

    // const handleKeyUp = () => {
    //     console.log("dsadsd", tableRef);
    //     // tableRef.current.dispatchEvent(new KeyboardEvent('keyup', {
    //     //     key: 'Shift',
    //     // }));
    // }

    // useEffect(() => {
    //     document.addEventListener('keyup', handleKeyUp);
    //     return () => {
    //         document.removeEventListener('keyup', handleKeyUp);
    //     };
    // }, []);

    const handleMultipleShiftEdit = async () => {
        const filteredShifts = selectedRows.filter(shift => shift.isTrain || shift.isDuty);
        const filteredIsTrain = filteredShifts.find(shift => shift?.isTrain);
        const filteredIsDuty = filteredShifts.find(shift => shift?.isDuty);
        const driver = selectedRows[0];
        const station = JSON.parse(localStorage.getItem("station"))
        const matchingShiftType = selectedRows.every((row, index, array) => row.shiftType.id === array[0].shiftType.id);
        const matchingInvoiceType = selectedRows.every((row, index, array) => row.invoiceTypeId === array[0].invoiceTypeId);
        const response = await getShiftOptions({
            shift: {
                ...selectedRows[0], isTrain: filteredIsTrain?.isTrain, isDuty: filteredIsDuty?.isDuty,
                isEditTrainDutyShift: filteredIsDuty?.isEditTrainDutyShift
            },
        });

        let invoiceTypes = response.data.data.invoiceTypes;
        let ShiftTypeOption = response.data.data.shift;
        ShiftTypeOption = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...ShiftTypeOption];
        invoiceTypes = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...invoiceTypes];

        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: ShiftTypeOption,
                shiftInvoiceTypes: invoiceTypes,
                loaded: true,
                diffTime: matchingShiftType ? false : true,
                diffshiftInvoiceType: matchingInvoiceType ? false : true
            },
            timeZoneShortName: 'CT',
            shift: {
                ...driver,
                routeId: driver.id,
                isDuty: driver.category === 6,
                typeId: matchingShiftType ? parseFloat(selectedRows[0].shiftType.id) : 'mismatch',
                startTime: matchingShiftType ? parseFloat(selectedRows[0].startTime) : 'mismatch',
                endTime: matchingShiftType ? parseFloat(selectedRows[0].endTime) : 'mismatch',
                utcStartTime: matchingShiftType ? parseFloat(selectedRows[0].utcStartTime) : 'mismatch',
                utcEndTime: matchingShiftType ? parseFloat(selectedRows[0].utcEndTime) : 'mismatch',
                invoiceTypeId: matchingInvoiceType ? selectedRows[0].invoiceTypeId : 'mismatch',
            },
        }).then(async (data) => {
            if (!data) return;
            const responseDriverSkill = await schedulerApi.checkMultipleDriverSkills(selectedRows, data);
            if (responseDriverSkill.data.data.result.some(res => !res.result)) {
                const confirmation = await confirm(
                    `WARNING: Few drivers does not have the required skill to be assigned this shift type. Are you sure you want to continue?`
                );
                if (!confirmation) {
                    return true;
                }
            }
            const response = await schedulerApi.multiEditShift(selectedRows, data, [], '');
            let newType = state.waveData.shiftTypes.filter((s) => s.id.toString() === data.shiftType)[0];
            let week = moment(state.selectedDate).isoWeek();
            if (moment(state.selectedDate).day() === 0) {
                week = moment(state.selectedDate).add(1, "days").isoWeek();
            }
            let year = parseInt(moment(state.selectedDate).startOf("week").format("YYYY"));
            if (moment(state.selectedDate).startOf("week").format('YYYY') != moment(state.selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(state.selectedDate).startOf("week").format('YYYY')) {
                year = parseInt(moment(state.selectedDate).endOf("week").format('YYYY'));
            }

            await API.fetchPendingChanges(
                {
                    station: station.id,
                    wknumber: week,
                    unpublishDataDate: state.selectedDate,
                    year: year,
                    loadOut: true,
                    type: "loadOut",
                },
                api
            ).then((UnpublishResponse) => {
                let chnagesId = [];
                UnpublishResponse.forEach(function (change) {
                    chnagesId.push(change.driverRouteId);
                });

                dispatch({
                    type: "SET_PUBLISH_COUNT",
                    ids: chnagesId,
                    station: station.id,
                    api: true,
                });
            });

            for (let i = 0; i < selectedRows.length; i++) {
                dispatch({
                    type: "UPDATE_ROUTE_STATUS",
                    payload: {
                        driverRoute: selectedRows[i].id,
                    },
                });
                dispatch({
                    type: "SET_SHIFT_TYPE",
                    payload: {
                        driverRoute: selectedRows[i]?.id,
                        shiftType: newType,
                    },
                });
            }
        }).catch((error) => {
            console.error("Error in showFlexibleShiftBlockForm:", error);
        }).finally(() => {
            setSelectedRows([]);
        });

        return;
    }

    const handleMultipleDelete = async (e) => {
        const conf2 = await confirm({
            icon: true,
            text: "WARNING: You are about delete shifts for multiple drivers! <br /> This will remove the selected driver's shifts from today's record.",
        });
        if (!conf2) return;
        await schedulerApi.multiDeleteShift(selectedRows)
        for (let i = 0; i < selectedRows.length; i++) {
            dispatch({
                type: "REMOVE_DRIVER_ROUTE",
                payload: selectedRows[i],
            });
            dispatch({
                type: "REMOVE_CHANGES",
                id: selectedRows[i].id,
                station: selectedRows[i].stationId,
            });
        }
    }

    const handleShiftKeyEventLoadOut = (e) => {
        if (e.key === 'Shift' && selectedRows?.length > 0) {
            addEditDeletePopup().then(data => {
                if (data === 'add-edit') {
                    handleMultipleShiftEdit()
                } else if (data === 'delete') {
                    handleMultipleDelete()
                } else {
                    setSelectedRows([])
                }
            })
        }
    }
    return (
        <Spacer top={3} right={5} bottom={3} left={5}>
            {state.pageLoader == true ? (
                <Icon.DspwNetwork size="28px" color={Theme.colors.primary} style={{ position: 'absolute', transform: "translate(-50%, -50%)", left: '50%', top: '50%' }} />
            ) : (
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                    <div className="table-container" onKeyUp={handleShiftKeyEventLoadOut} style={{ userSelect: 'none', outline: 'none' }} tabIndex={1}>
                        <Table
                            style={{
                                paddingTop: 34,
                            }}
                        >
                            <TableHeader
                                style={{
                                    position: "fixed",
                                    width: "calc(100% - 40px)",
                                    left: 20,
                                    right: 20,
                                    zIndex: 2,
                                    backgroundColor: Theme.colors.neutrals.white,
                                    transform: "translateY(-100%)",
                                    paddingTop: 8,
                                    paddingBottom: 4,
                                }}
                                headers={headerArray}
                                compact={true}
                                sortByFunc={setSortBy}
                                sortedBy={state.sortBy}
                            />
                            {driverList.map((d, k) => (
                                <DriverRow
                                    key={k}
                                    index={k}
                                    driver={d}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                />
                            ))}
                            <AddDriverRow />
                        </Table>
                        <Spacer bottom={5} />
                    </div>
                </DndProvider>)
            }
        </Spacer>
    );
};
