import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import {Spacer, Input, Toggle, CheckBox,Dropdown, Button} from '@dspworkplace/ui';
import {SchedulesAddStateContext} from "../index";
import {SchedulesAddDispatchContext} from "../index";
import {useForm} from "react-hook-form";
import {CustomButton} from "../index";
import {patternDate} from '../../../../../../patterns';
import DayPicker from '../../../../../../components/Calendar/day';
import {useAuth} from "../../../../../../Auth";
import Loading from "../../../../../../components/Loading";
import moment from "moment";
import { alert } from '../../../../../../components/Alert';
import toast from "../../../../../../components/Toasts/toast";


const Content = styled.form`
    box-sizing: border-box;
    display: flex;
    font-family: circe-rounded;
    flex-direction: column;
`;

const TableData = styled.div`
    display: inline-block;
    margin:5px;
    display:inline-block;
`;

function calculateWeeksBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
        return 0;
    }

    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000; // 7 days in a week
    const differenceInMilliseconds = end - start;

    const weeks = Math.floor(differenceInMilliseconds / millisecondsInWeek);
    return weeks;
};

function diff_weeks(start, end) {

    start = new Date(start);
    end = new Date(end);

    let startYear ;
    let endYear;
    if (moment(start).day() === 0) {
        startYear = moment(start).add(1, 'days').utc().isoWeekYear();
    } else {
        startYear = moment(start).utc().isoWeekYear();
    }

    if (moment(end).day() === 0) {
        endYear = moment(end).add(1, 'days').utc().isoWeekYear();
    } else {
        endYear = moment(end).utc().isoWeekYear();
    }

    let startWeekNumber = 0;
    let endWeekNumber = 0;
    if (moment(start).day() === 0) {
        startWeekNumber = moment(start, 'DD-MM-YYYY').add(1, 'days').utc().isoWeek();
    } else {
        startWeekNumber = moment(start, 'DD-MM-YYYY').utc().isoWeek();
    }

    if (moment(end).day() === 0) {
        endWeekNumber = moment(end, 'DD-MM-YYYY').add(1, 'days').utc().isoWeek();
    } else {
        endWeekNumber = moment(end, 'DD-MM-YYYY').utc().isoWeek();
    }

    let lastDateWeekNumber = moment(startYear+'-12-31').utc().isoWeek();

    if( startYear === endYear ){
        return  (endWeekNumber - startWeekNumber)+1;
    } else {
        let weekno = lastDateWeekNumber + endWeekNumber;
        return  (weekno - startWeekNumber)+1;
    }
 }

const InformationPanel = (editData) => {

    const {state} = React.useContext(SchedulesAddStateContext);
    const {dispatch} = React.useContext(SchedulesAddDispatchContext);
    const [defVal, setDefVal] = useState(state.information);
    if( state.information.startDate ){
        defVal.startDate = moment(state.information.startDate).format('YYYY-MM-DD');
    }
    if( state.information.endDate ){
        defVal.endDate =moment(state.information.endDate).format('YYYY-MM-DD') ;
    }

    const { register, handleSubmit, errors, getValues,setError  } = useForm({mode:'onBlur', defaultValues: defVal});
    const {name, startDate, endDate} = getValues();
    const [isRecurring, setIsRecurring] = useState(0);
    const { api } = useAuth();
    const onSubmit = async data =>
    {
       if(data.recurring === false){
          if(new Date(data.endDate) < new Date(data.startDate)){
            setError("endDate", "notMatch", "End date must be greater than start date");
          } else {
            let weekCount = calculateWeeksBetween(data.startDate,data.endDate);
            dispatch({'type':'setTotalWeeks', originalWeekCount: weekCount});
            dispatch({'type':'setInformation', information: data});
            document.querySelectorAll("[data-tab='tab']")[1].click();
          }
        } else {
            const params={
                "actions":{
                   "response":{
                      "Company":{
                         "custom":{
                            "functionName":"getRecurringWeeks",
                            "get":"recurringWeek",
                            "criteria":{
                               "id":localStorage.getItem("company")
                            }
                         }
                      }
                   }
                }
             }
            let companyRecurringWeeks = await api.post('/api/lazy/manage/data', params);
            let companyRecurringWeek = companyRecurringWeeks.data.data.recurringWeek;

            let currentDate = moment().format('YYYY-MM-DD');
            if(state.isEdit == 1){
                data.recurringWeeks = parseInt(companyRecurringWeek);
            } else{
                let startDateObj = new Date(data.startDate);
                let startWeekNumber = 0;
                let startWeekYear = 0;
                if (moment(startDateObj).day() === 0) {
                    startWeekNumber = moment(startDateObj, 'DD-MM-YYYY').add(1, 'days').utc().isoWeek();
                    startWeekYear = moment(startDateObj, 'DD-MM-YYYY').add(1, 'days').utc().isoWeekYear();
                } else {
                    startWeekNumber = moment(startDateObj, 'DD-MM-YYYY').utc().isoWeek();
                    startWeekYear = moment(startDateObj, 'DD-MM-YYYY').utc().isoWeekYear();
                }
               let lastDayObj =  moment(startDateObj).add(parseInt(companyRecurringWeek), 'weeks');

                let lastDate = '';
                if(startWeekYear > moment().format('YYYY')){
                    lastDate = lastDayObj.isoWeekday(6).format('YYYY-MM-DD');
                } else {
                    lastDate = lastDayObj.isoWeekday(6).format('YYYY-MM-DD');
                }

                if(currentDate < data.startDate) {
                    data.recurringWeeks = parseInt(companyRecurringWeek);
                } else if (currentDate > data.startDate) {
                    let weeks = calculateWeeksBetween(data.startDate,moment().format('YYYY-MM-DD'));
                    data.recurringWeeks = parseInt(weeks) + parseInt(companyRecurringWeek) - 1;
                } else {
                    data.recurringWeeks = parseInt(companyRecurringWeek);
                }
            }
            dispatch({'type':'setInformation', information: data});
            document.querySelectorAll("[data-tab='tab']")[1].click();
       }
    };
    const handleIsRecurring = async()=>{
        if( isRecurring === 0 ){
            setIsRecurring(1);
        } else {
            setIsRecurring(0);
        }
    }
    const errorMessages = {
        startDate: {
            required: 'You need to provide an start date',
            pattern: 'Enter a valid date. Ex: YYYY-MM-DD'
        },
        endDate: {
            pattern: 'Enter a valid date. Ex: YYYY-MM-DD',
            required: 'You need to provide an end date',
            notMatch: 'End date must be greater then start date',
        }
    }

    const cancelBtn = async ()=>{
        document.getElementById('cancelBtn').click()
    }

    const getScheduleDetail = async (id) => {
        dispatch({type:'loading', isLoading: true});
        const params={
            "actions": {
                "response": {
                    "Schedule": {
                        "custom": {
                            "functionName": "getScheduleDetail",
                            "get": "schedule",
                            "criteria": {
                                "id":id
                            }
                        }
                    }
                }
            }
        };

        try {
            let response = await api.post('/api/lazy/manage/data', params);
            if(response){
                let schedule = response.data.data.schedule;

                let driversCount = 0;
                let weeksCount = [];
                let shiftTypesCount = 0;
                let shiftDesignId = [];
                let shiftCounts=0;
                let weeks=[];
                let weekshift=[];

                Object.keys(schedule.scheduleDesigns).forEach(function(sd) {
                    if(weeks.indexOf(schedule.scheduleDesigns[sd].week) === -1) {
                        weeks.push(schedule.scheduleDesigns[sd].week);
                    }
                });

                let weekIndex =[];
                Object.keys(weeks).forEach(function(week,key) {
                    let shifts=[];
                    Object.keys(schedule.scheduleDesigns).forEach(function(sd) {
                        if(schedule.scheduleDesigns[sd].week === weeks[week]  ){
                            let days=[];
                            if(schedule.scheduleDesigns[sd].sunday === true ){
                                days[0]={
                                    Sun:1
                                }
                            } else {
                                days[0]={
                                    Sun:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].monday === true ){
                                days[1]={
                                    Mon:1
                                }
                            } else {
                                days[1]={
                                    Mon:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].tuesday === true ){
                                days[2]={
                                    Tue:1
                                }
                            } else {
                                days[2]={
                                    Tue:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].wednesday === true ){
                                days[3]={
                                    Wed:1
                                }
                            } else {
                                days[3]={
                                    Wed:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].thursday === true ){
                                days[4]={
                                    Thu:1
                                }
                            } else {
                                days[4]={
                                    Thu:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].friday === true ){
                                days[5]={
                                    Fri:1
                                }
                            } else {
                                days[5]={
                                    Fri:0
                                }
                            }
                            if(schedule.scheduleDesigns[sd].saturday === true ){
                                days[6]={
                                    Sat:1
                                }
                            } else {
                                days[6]={
                                    Sat:0
                                }
                            }
                            Object.keys(schedule.scheduleDesigns[sd].shifts).forEach(function(shift) {
                                if( weeks[week] === schedule.scheduleDesigns[sd].week  ){
                                    shifts.push({
                                        break: schedule.scheduleDesigns[sd].shifts[shift].unpaidBreak,
                                        color: schedule.scheduleDesigns[sd].shifts[shift].color,
                                        count: schedule.scheduleDesigns[sd].shifts[shift].id,
                                        endTime: schedule.scheduleDesigns[sd].shifts[shift].endTime,
                                        endTimeOnDst: schedule.scheduleDesigns[sd].shifts[shift].endTimeOnDst,
                                        id: schedule.scheduleDesigns[sd].shifts[shift].id,
                                        days:days,
                                        invoice: "",
                                        name: schedule.scheduleDesigns[sd].shifts[shift].name,
                                        range: schedule.scheduleDesigns[sd].shifts[shift].startTime+" - "+schedule.scheduleDesigns[sd].shifts[shift].endTime,
                                        skill: schedule.scheduleDesigns[sd].shifts[shift].skill.name,
                                        skillId:schedule.scheduleDesigns[sd].shifts[shift].skill.id,
                                        startTime: schedule.scheduleDesigns[sd].shifts[shift].startTime,
                                        startTimeOnDst: schedule.scheduleDesigns[sd].shifts[shift].startTimeOnDst,
                                        rangeOnDst: schedule.scheduleDesigns[sd].shifts[shift].startTimeOnDst+" - "+schedule.scheduleDesigns[sd].shifts[shift].endTimeOnDst,
                                        week:schedule.scheduleDesigns[sd].week,
                                        category:schedule.scheduleDesigns[sd].shifts[shift].category,
                                        did:schedule.scheduleDesigns[sd].id
                                    });
                                    if(key < schedule.originalWeekCount){
                                      shiftTypesCount++;
                                    }
                                }
                            });
                        }
                    });
                    weekshift[week] = shifts;
                    weekIndex.push(parseInt(week)+1);
                });

               let driverArr=[];
               let tempDriver=[];
               let dCount = 0;
                Object.keys(schedule.drivers).forEach(function(d) {
                   if(!schedule.drivers[d].isArchive && !schedule.drivers[d].user.isArchive) {
                       driversCount = driversCount + 1;
                       let tempSchedule=[];
                       let skills=[];
                       Object.keys(schedule.drivers[d].driverSkills).forEach(function(skillIndex) {
                           skills[skillIndex]={
                               hourlyRate:schedule.drivers[d].driverSkills[skillIndex].hourlyRate,
                               id:schedule.drivers[d].driverSkills[skillIndex].id,
                               skill:{
                                   id:schedule.drivers[d].driverSkills[skillIndex].skill.id,
                                   name:schedule.drivers[d].driverSkills[skillIndex].skill.name,
                               }
                           }
                       });
                       let cnt = 0;
                       if(schedule.driversWithSchedules.length > 0){
                        //var driver = schedule.driversWithSchedules.find( d => d.id.toString == schedule.drivers[d].id );
                            schedule.driversWithSchedules.map(dri=> {
                                let cnt = 0;
                                if(dri.id == schedule.drivers[d].id){
                                    dri.schedules.map(sh=> {
                                        tempSchedule[cnt] = {
                                            id:sh.id,
                                            name:sh.name,
                                            startDate:sh.startDate,
                                            endDate:sh.endDate,
                                            isRecurring:sh.isRecurring,
                                            isArchive:sh.isArchive
                                        }
                                        cnt++;
                                    });
                                }

                            });
                        }
                        const camelize = function camelize(str) {
                            str = str.replace('ROLE_','');
                            str = str.replace('_',' ');
                            str = str.toLowerCase();
                            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
                            {
                                return  word.toUpperCase();
                            }).replace(/\s+/g, ' ');
                        }

                       driverArr[dCount]={
                           id:schedule.drivers[d].id,
                           cel: null,
                           color: "#00A38D",
                           role: (schedule.drivers[d].user.title)?camelize(schedule.drivers[d].user.title):camelize(schedule.drivers[d].user.userRoles[0].name),
                           grade: "A",
                           name: schedule.drivers[d].user.friendlyName,
                           skills:skills,
                           schedule:tempSchedule,
                           image:schedule.drivers[d].user.profileImage,
                           onAddDriver:false
                       }
                       dCount++;
                   }
                });
                Object.keys(schedule.scheduleDesigns).forEach(function(shift) {
                    shiftDesignId.push(schedule.scheduleDesigns[shift].id);
                });

                dispatch({type:'init',
                    information:{name:schedule.name,startDate:startDate,recurring:schedule.recurring,endDate:endDate,checkbox:schedule.previousAssignSchedule, },
                    drivers:driverArr,
                    design:{shiftTypes: weekshift, weekArr: weekIndex},
                    id:schedule.id,
                    shiftDesignId:shiftDesignId,
                    weekArr:weeks,
                   // driverRoutes:schedule.driverRoutes,
                    originalWeekCount:schedule.originalWeekCount,
                    originalScheduleDesignWeekAndYear:{
                        startWeek : schedule.startWeek,
                        endWeek : schedule.endWeek,
                        startYear : schedule.year,
                        endYear : schedule.weekEndYear
                    },
                    notification:{isNotification:schedule.isSentNotification, notificationTime:schedule.notificationTime, notificationType:schedule.notificationType,notificationMessage:schedule.notificationMessage},
                    driversEffectiveDate:schedule.driversEffectiveDate
                });

            }
            dispatch({type:'loading', isLoading: false});
        } catch(error) {
            if (error.response.status === 401) {
                window.location = "/logout";
            }
        }
    }

    useEffect(()=>{
        if(state.isEdit == 1 || state.isCopy == 1){
            getScheduleDetail(state.id);
        }
        //setDefVal(moment(defVal.information.startDate).format('YYYY'));
        if(state.information){
           if(state.information.recurring === true){
               setIsRecurring(1);
           } else {
               setIsRecurring(0);
           }
        }
        //startDate = moment(startDate).format('MM/DD/YYYY');
    },[]);
    let employeeStatusArr = [];
    if(state.originalWeekCount){
        for(let week = state.originalWeekCount; week<=15; week++){
            employeeStatusArr.push({'name':week.toString(),'value':week.toString()});
        }
    } else {
        for(let week = 1; week<=15; week++){
            employeeStatusArr.push({'name':week.toString(),'value':week.toString()});
        }
    }
    return (<>
            {state.isLoading && <Loading style={{height:'80px',marginTop:'20px'}} />}
            {!state.isLoading && <Content name='information' onSubmit={handleSubmit(onSubmit)}>
                <Spacer top={5}></Spacer>
                <Input
                    name='name'
                    label='Name'
                    ref={register({required:'You need to provide a name'})}
                    error={errors.name && errors.name.message}
                    valid={!errors.name && name && name.length > 0}
                />

                <Spacer top={4} style={{zIndex:2}}>
                    <DayPicker
                        name='startDate'
                        label='Start Date'
                        disabled={state.isEdit === 1 && moment().utc().format('YYYY-MM-DD') >= state.information.startDate ? true : false}
                        ref={register(
                            {required:true})}
                        error={errors.startDate && errorMessages.startDate[errors.startDate.type] }
                        valid={!errors.startDate && startDate && startDate.length > 0}
                    />
                </Spacer>

                <Spacer top={4}></Spacer>
                <div style={{'pointer-events':state.isEdit === 1 && state.information.recurring === false ? 'none' : ''}}>
                <Toggle
                    name='recurring'
                    singleText='Recurring'
                    ref={register({required: false})}
                    on={isRecurring }
                    onChange={()=>{handleIsRecurring()}}
                    ></Toggle>
                    </div>

                { isRecurring === 0 && <Spacer top={4}><DayPicker
                    label='End Date'
                    name='endDate'
                    disabled={state.isEdit === 1 && moment().format('YYYY-MM-DD') >= state.information.startDate && state.information.recurring === false ? "disabled" : ''}
                    ref={register(
                        {required:true})}
                    error={errors.endDate && errorMessages.endDate[errors.endDate.type] }
                    valid={!errors.endDate && endDate && endDate.length > 0}
                    /></Spacer> }

                { isRecurring === 0 && <Spacer top={4}><CheckBox
                    name='checkbox'
                    title={''}
                    ref={register({required: false})}
                    options={[
                        {value:true,label:'Return drivers to previous assigned schedule after end date'},
                    ]}/></Spacer> }

                <Spacer top={5}>
                    <Button>Go To Design Step</Button>
                    <Spacer inline right={3}/>
                    <Button type={'cancel'} onClick={()=>{cancelBtn()}}>Cancel</Button>
                </Spacer>

               </Content>}
            </>
    );
}

export default React.memo(InformationPanel);
